import { createContext, useContext } from 'react';

import useCompanyLogo from '~/hooks/useCompanyLogo';
import { LearnerInfo } from '~/types';

export const UserInfoCtx = createContext<{
  userLoading: boolean;
  suiteIDs: string[];
  hasAccessToContent: boolean;
  userInfo?: LearnerInfo;
  companyLogo?: ReturnType<typeof useCompanyLogo>;
}>({
  userInfo: undefined,
  suiteIDs: [],
  userLoading: false,
  hasAccessToContent: false,
  companyLogo: undefined,
});

export function useUserInfoContext() {
  return useContext(UserInfoCtx);
}
