import React from 'react';

import { useTheme } from '@mui/material';

import { Svg } from '~/assets/icons/styles';

const StarEdgyIcon = ({ color = '', ...props }) => {
  const theme = useTheme();
  const svgColor = color || theme.palette.common.blue;

  return (
    <Svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      svgColor={svgColor}
    >
      <path
        d="M22.5 9.56812L15.31 8.94812L12.5 2.32812L9.69 8.95813L2.5 9.56812L7.96 14.2981L6.32 21.3281L12.5 17.5981L18.68 21.3281L17.05 14.2981L22.5 9.56812ZM12.5 15.7281L8.74 17.9981L9.74 13.7181L6.42 10.8381L10.8 10.4581L12.5 6.42812L14.21 10.4681L18.59 10.8481L15.27 13.7281L16.27 18.0081L12.5 15.7281Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default StarEdgyIcon;
