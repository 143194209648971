import { capitalize } from '@mui/material';
import { captureException } from '@sentry/react';

import { Tenant } from '~/types';

const VARIABLES_KEY = 'variables';
const VAR_REG_EXP = /%%%([^%]+)%%%/g;

const replaceTenantVariables = (tenantName: Tenant['name'], t) => (str) => {
  try {
    return str.replaceAll(VAR_REG_EXP, (match) => {
      // %%%someValue%%% -> someValue
      const sanitizedMatch = VAR_REG_EXP.exec(match)?.[1];
      const replacement = t(`${VARIABLES_KEY}.${tenantName}.${sanitizedMatch}`);

      if (!replacement) {
        return sanitizedMatch;
      }

      return capitalize(replacement);
    });
  } catch (error) {
    captureException(error);
    return str;
  }
};

export default replaceTenantVariables;
