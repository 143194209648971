import React, { ComponentProps } from 'react';

import { Trans as I18nTrans } from 'react-i18next';

import useIsSsku from '~/hooks/tenant/useIsSsku';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { OTHER_TENANTS, SSKU } from '~/utils/translations/translateByTenant';

type TransProps = ComponentProps<typeof I18nTrans>;

const Trans: React.FC<TransProps> = ({ i18nKey, ...rest }) => {
  const { t } = useTenantTranslation();
  const tenantSpecificKey = `${i18nKey}.${OTHER_TENANTS}`;
  const sskuSpecificKey = `${i18nKey}.${SSKU}`;
  const isSsku = useIsSsku();

  if (isSsku && t(sskuSpecificKey)) {
    return <I18nTrans i18nKey={sskuSpecificKey} {...rest} />;
  }

  if (t(tenantSpecificKey)) {
    return <I18nTrans i18nKey={tenantSpecificKey} {...rest} />;
  }

  return <I18nTrans i18nKey={i18nKey} {...rest} />;
};

export default Trans;
