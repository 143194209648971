import React, { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types/swiper-options';

import ExpertCard, { ExpertCardType } from '~/components/ExpertCard/ExpertCard';
import { BREAKPOINTS_VALUES, SMALL_MARGIN, SMALL_MARGIN_PX, useIsWidthUp } from '~/theme';
import { User } from '~/types';
import { splitIntoChunks } from '~/utils/arrayUtils/splitIntoChunks';

const defaultSwiperSettings = {
  slidesPerView: 1,
  spaceBetween: 16,
  pagination: {
    clickable: true,
  },
  mousewheel: {
    releaseOnEdges: true,
    forceToAxis: true,
    invert: true,
  },
  navigation: true,
  observer: true,
  breakpoints: {
    [BREAKPOINTS_VALUES.ml]: {
      slidesPerView: 7,
    },
    [BREAKPOINTS_VALUES.xl]: {
      slidesPerView: 6,
    },
    1000: {
      slidesPerView: 5,
    },
    [BREAKPOINTS_VALUES.md]: {
      slidesPerView: 4,
    },
  },
};

const CHUNK_MOBILE_LENGTH = 4;
const CHUNK_TABLET_LENGTH = 6;

interface ExpertsSliderProps {
  experts?: User[];
  withLinks?: boolean;
  tileType?: ExpertCardType;
  loading?: boolean;
  swiperSettings?: SwiperOptions;
}

const loadingItems = new Array(6).fill(undefined);

const ExpertsSlider: React.FC<ExpertsSliderProps> = ({
  experts,
  withLinks = false,
  tileType = 'light',
  loading = false,
  swiperSettings = defaultSwiperSettings,
}) => {
  const [swiperKey, setSwiperKey] = useState(0);
  const isMobile = !useIsWidthUp('sm');
  const isTablet = !useIsWidthUp('md');

  useEffect(() => {
    setSwiperKey((old) => old + 1);
  }, [swiperSettings]);

  const chunkLength = useMemo(() => {
    return isMobile ? CHUNK_MOBILE_LENGTH : CHUNK_TABLET_LENGTH;
  }, [isMobile]);

  const chunks = useMemo(() => {
    return isTablet ? splitIntoChunks(experts, chunkLength) : experts;
  }, [isTablet, chunkLength, experts]);

  if (loading) {
    return (
      <SwiperWrapper>
        <Swiper {...swiperSettings} key={swiperKey}>
          {isTablet ? (
            <SwiperSlide>
              <Slide>
                {loadingItems.map((item, i) => (
                  <Chunk key={i}>
                    <ExpertCard loading />
                  </Chunk>
                ))}
              </Slide>
            </SwiperSlide>
          ) : (
            loadingItems.map((item, i) => (
              <SwiperSlide key={i}>
                <Box>
                  <ExpertCard loading />
                </Box>
              </SwiperSlide>
            ))
          )}
        </Swiper>
      </SwiperWrapper>
    );
  }

  return (
    <SwiperWrapper>
      <Swiper {...swiperSettings} key={swiperKey}>
        {chunks &&
          chunks.map((chunk) =>
            isTablet ? (
              <SwiperSlide key={chunk?.[0]?.id}>
                <Slide>
                  {chunk &&
                    chunk.map((expert) => (
                      <Chunk key={`c-${expert?.id}`}>
                        <ExpertCard
                          id={expert?.id}
                          title={expert?.name}
                          imageUrl={expert?.avatar}
                          jobTitle={expert?.job_title}
                          slug={expert?.slug}
                          withLink={withLinks}
                          type={tileType}
                          loading={loading}
                        />
                      </Chunk>
                    ))}
                </Slide>
              </SwiperSlide>
            ) : (
              <SwiperSlide key={chunk?.id}>
                <Box>
                  <ExpertCard
                    id={chunk?.id}
                    title={chunk?.name}
                    imageUrl={chunk?.avatar}
                    jobTitle={chunk?.job_title}
                    slug={chunk?.slug}
                    withLink={withLinks}
                    type={tileType}
                    loading={loading}
                  />
                </Box>
              </SwiperSlide>
            ),
          )}
      </Swiper>
    </SwiperWrapper>
  );
};

export default ExpertsSlider;

const Chunk = styled('div')`
  width: calc(50% - ${SMALL_MARGIN / 2}px);
  height: 255px;
  margin-bottom: ${SMALL_MARGIN_PX};

  & > div {
    height: 255px;
  }

  &:nth-child(2n + 1) {
    margin-right: ${SMALL_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: calc((100% - ${2 * SMALL_MARGIN}px) / 3);

    &:nth-child(2n + 1) {
      margin-right: 0;
    }

    &:nth-child(3n + 2) {
      margin-right: ${SMALL_MARGIN_PX};
    }

    &:nth-child(3n + 1) {
      margin-right: ${SMALL_MARGIN_PX};
    }
  }
`;
const Slide = styled('div')`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;
const SwiperWrapper = styled('div')`
  & .swiper-button-next,
  & .swiper-button-prev {
    top: 41%;
  }
`;
