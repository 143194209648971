import React, { useState, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { styled } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import CategoryCard from '~/components/AreasBlock/CategoryCard';
import { GET_ALL_AREAS, GET_CATEGORIES_BY_PARENT } from '~/graphql/videos';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import {
  LARGE_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  SMALL_MARGIN_PX,
  SUBMIDDLE_MARGIN_PX,
  useIsWidthBetween,
} from '~/theme';
import { Query } from '~/types';
import { replaceByIndex } from '~/utils/arrayUtils/replaceByIndex';
import { checkIsSSR } from '~/utils/checkIsSSR';
import { sortByString } from '~/utils/sortUtils/sortByString';

const AreasBlock = ({ data: areasData }) => {
  const background = areasData?.background;
  const { t } = useTenantTranslation();
  const isTablet = useIsWidthBetween('sm', 'md');
  const [openedArr, setOpenedArr] = useState<boolean[]>([]);
  const isSSR = checkIsSSR();

  const {
    data,
    loading: loadingData,
    error,
  } = useQuery<Pick<Query, 'listBaseVideoCategories'>>(GET_ALL_AREAS, {
    onCompleted: (data) => {
      const length = data?.listBaseVideoCategories?.length || 1;
      setOpenedArr(new Array(length).fill(false));
    },
    skip: isSSR,
  });

  const loading = loadingData || isSSR;

  const { data: categoriesData } =
    useQuery<Pick<Query, 'listVideoCategories'>>(GET_CATEGORIES_BY_PARENT);

  const setOpenedItem = (value, i) => {
    setOpenedArr((prevState) => {
      let res = replaceByIndex(prevState, i, value);

      if (isTablet) {
        if (i % 2 === 0 && i !== prevState.length - 1) {
          res = replaceByIndex(res, i + 1, value);
        } else if (i % 2 === 1 && i !== 0) {
          res = replaceByIndex(res, i - 1, value);
        }
      }

      return res;
    });
  };

  const areas = useMemo(() => {
    return data?.listBaseVideoCategories
      ? sortByString([...data.listBaseVideoCategories], 'title', true)
      : [];
  }, [data]);

  if (error) {
    return (
      <div>
        {t('error.error')}: {error?.message}
      </div>
    );
  }

  const areaBlockHeading = areasData?.heading;
  const categories = categoriesData?.listVideoCategories;

  const categoriesByAreas = categories?.reduce((acc, category) => {
    if (category?.parent_id) {
      const byArea = acc[category?.parent_id];
      if (byArea) {
        acc[category.parent_id] = [...byArea, category];
        return acc;
      }

      return { ...acc, [category?.parent_id]: [category] };
    }

    return acc;
  }, {});

  return (
    <StyledContainer
      className={cx(
        'catalogue-of-categories-block',
        background === 'Dark' ? 'dark-block' : 'light-block',
      )}
    >
      <Header variant="h2" component="h2" align="center">
        {areaBlockHeading}
      </Header>
      <ItemsWrapper>
        {loading
          ? new Array(8).fill(null).map((item, index) => <CategoryCard key={index} loading />)
          : areas &&
            areas.map((item, i) => {
              return (
                <CategoryCard
                  key={item?.id}
                  opened={openedArr[i]}
                  setOpened={(value) => setOpenedItem(value, i)}
                  rootCategory={item}
                  subcategories={item?.id ? categoriesByAreas?.[item?.id] : []}
                />
              );
            })}
      </ItemsWrapper>
    </StyledContainer>
  );
};

export default AreasBlock;

const ItemsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  width: 100%;
  display: grid;
  gap: ${SMALL_MARGIN_PX};
  grid-template-columns: 1fr;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    gap: ${MIDDLE_MARGIN_PX};
    grid-template-columns: repeat(3, 1fr);
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${MIDDLE_MARGIN_PX};
  padding-bottom: ${SMALL_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: ${LARGE_MARGIN_PX};
    padding-bottom: ${MIDDLE_MARGIN_PX};
  }

  &:first-child {
    margin-top: 76px;
  }
`;
const Header = styled(Typography)`
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }
` as typeof Typography;
