import React, { FC, Fragment } from 'react';

import { keyframes, styled } from '@mui/material/styles';

const sparks = new Array(3).fill(null);

interface ISparks {
  playing: boolean;
  className?: string;
}

const Sparks: FC<ISparks> = ({ playing, className }) => {
  return (
    <div className={className}>
      {sparks.map((item, index) => {
        const deg = (180 / sparks.length) * (index + 1);
        return (
          <Fragment key={index}>
            <SparkLine deg={deg}>
              <SparkWrapper reversed>
                <Spark shouldPlay={playing} />
              </SparkWrapper>
              <SparkWrapper>
                <Spark shouldPlay={playing} />
              </SparkWrapper>
            </SparkLine>
            <SparkLine deg={deg + 15}>
              <SparkWrapper reversed>
                <Spark shouldPlay={playing} delay={0.05} />
              </SparkWrapper>
              <SparkWrapper>
                <Spark shouldPlay={playing} delay={0.05} />
              </SparkWrapper>
            </SparkLine>
          </Fragment>
        );
      })}
    </div>
  );
};

export default Sparks;

const sparkAnimation = ({ theme }) => keyframes`
  0% {
    opacity: 0;
    width: 0;
    left: 0;
  }
  35% {
    background: ${theme.palette.common.blue};
  }
  50% {
    opacity: 1;
    width: 7px;
    left: 15px;
    background: ${theme.palette.common.violetSecondary};
  }
  70% {
    background: ${theme.palette.common.blue};
  }
  100% {
    opacity: 0;
    width: 0;
    left: 25px;
  }
`;
const Spark = styled('div')<{
  shouldPlay: boolean;
  delay?: number;
}>`
  position: relative;
  height: 2px;
  margin: 4px;
  border-radius: 2px;
  animation-name: ${(props) => props.shouldPlay && sparkAnimation(props)};
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-play-state: ${({ shouldPlay }) => (shouldPlay ? 'running' : 'paused')};
  animation-delay: ${({ delay = 0 }) => delay}s;
  background: ${({ theme }) => theme.palette.common.blue};
`;
const SparkWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'reversed',
})<{
  reversed?: boolean;
}>`
  transform: rotate(${({ reversed }) => (reversed ? 180 : 0)}deg);
`;
const SparkLine = styled('div')<{
  deg?: number;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: space-between;
  transform: translate(-50%, -50%) rotate(${({ deg = 0 }) => deg}deg);
`;
