import React, { FC } from 'react';

import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

import AnimatedSkeleton from '~/components/AnimatedSkeleton';
import AnimatedSkeletonMultiline from '~/components/AnimatedSkeletonMultiline';
import IKImage from '~/components/IKImage/IKImage';
import {
  BREAKPOINTS_VALUES,
  LARGE_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  SKELETON_TEXT_HEIGHT_SMALL_PX,
  SMALL_MARGIN,
  SMALL_MARGIN_PX,
  TINY_MARGIN_PX,
} from '~/theme';

interface BottomItemProps {
  title?: string | null;
  imageUrl?: string | null;
  image?: React.ReactNode | null;
  loading?: boolean;
  'data-testid'?: string;
}

const BottomItem: FC<BottomItemProps> = ({ title, imageUrl, image, loading, ...rest }) => {
  if (loading) {
    return (
      <Wrapper data-testid={rest['data-testid']}>
        <LoadingImageWrapper xs="65px" />
        <LoadingTitle xs={SKELETON_TEXT_HEIGHT_SMALL_PX} spacer={TINY_MARGIN_PX} lines={3} />
      </Wrapper>
    );
  }

  return (
    <Wrapper data-testid={rest['data-testid']}>
      <ImageWrapper>
        {image || (
          <StyledImage
            path={imageUrl}
            draggable={false}
            alt={title}
            srcset={[{ width: 65 }, { width: 40 }]}
            sizes={`(max-width:${BREAKPOINTS_VALUES['md']}px) 40px, 65px`}
          />
        )}
      </ImageWrapper>
      <Title>{title}</Title>
    </Wrapper>
  );
};

export default BottomItem;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-bottom: ${MIDDLE_MARGIN_PX};

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    margin-right: ${LARGE_MARGIN_PX};
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }
`;
const createImageWrapper = (tag) => styled(tag)`
  width: 65px;
  min-width: 65px;
  margin-bottom: ${SMALL_MARGIN / 2}px;

  & svg {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 0;
    margin-right: ${SMALL_MARGIN_PX};
  }
`;
const ImageWrapper = createImageWrapper('div');
const LoadingImageWrapper = createImageWrapper(AnimatedSkeleton);
const StyledImage = styled(IKImage)`
  width: 100%;
`;
const Title = styled(Typography)`
  font-size: 18px;
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.common.white};
  max-width: 280px;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 20px;
    text-align: left;
  }
`;
const LoadingTitle = styled(AnimatedSkeletonMultiline)`
  width: 100%;
`;
