import React, { forwardRef, ReactNode } from 'react';

import { styled } from '@mui/material';

import { CssUtilityType } from '~/styled';

interface IRatio {
  ratio?: number;
  children: ReactNode;
  contentWrapperClass?: string;
  contentWrapperCss?: CssUtilityType | CssUtilityType[];

  [key: string]: any;
}

const Ratio = forwardRef<HTMLDivElement, IRatio>(
  ({ ratio = 56, children, contentWrapperClass = '', contentWrapperCss, ...rest }, ref) => {
    return (
      <Wrapper ref={ref} {...rest}>
        <RatioSpace ratio={ratio} />
        <Content className={contentWrapperClass} optionalStyles={contentWrapperCss}>
          {children}
        </Content>
      </Wrapper>
    );
  },
);

export default Ratio;

const Content = styled('div', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})<{ optionalStyles?: CssUtilityType | CssUtilityType[] }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${({ optionalStyles }) => optionalStyles}
`;
const RatioSpace = styled('div')(({ ratio }: { ratio: number }) => ({
  width: '100%',
  paddingTop: ratio + '%',
}));

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hideVideoIframe',
})({
  position: 'relative',
  width: '100%',
});
