import React from 'react';

import { useStaticRouterContext } from '~/context/StaticRouterProvider';
import { checkIsSSR } from '~/utils/checkIsSSR';

interface RouteWithCodeProps {
  code: number;
  children: React.ReactElement<any, any> | null;
}

const RouteWithCode: React.FC<RouteWithCodeProps> = ({ children, code }) => {
  const staticRouterContext = useStaticRouterContext();

  if (checkIsSSR() && staticRouterContext) {
    staticRouterContext.statusCode = code;
  }

  return children;
};

export default RouteWithCode;
