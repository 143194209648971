import { captureException } from '@sentry/react';

import useIsSsku from '~/hooks/tenant/useIsSsku';
import useEventListener from '~/hooks/useEventListener';
import { removeProhibitedCookiesAndLocalStorageItems } from '~/utils/checkConsent';
import Cookie from '~/utils/cookies';

enum ConsentGroupsMap {
  necessary = 'C0001',
  statistics = 'C0002',
  preferences = 'C0003',
  marketing = 'C0004',
}

const TARGET_COOKIE = 'OptanonConsent';

const useSyncSskuConsentCookies = () => {
  const isSsku = useIsSsku();
  useEventListener('CookiebotOnConsentReady', removeProhibitedCookiesAndLocalStorageItems);

  useEventListener('CookiebotOnDialogDisplay', () => {
    if (!isSsku) return;

    window?.Cookiebot?.hide();
  });

  useEventListener('OneTrustGroupsUpdated', async () => {
    if (!isSsku) return;

    const hasOptanonConsentSet = !!Cookie.get(TARGET_COOKIE);

    // sync cookiebot consent only if optanon cookie was set
    if (!hasOptanonConsentSet) {
      return;
    }

    try {
      const allowedConsentGroups = window.OptanonActiveGroups.split(',').filter(Boolean);

      window?.Cookiebot?.submitCustomConsent(
        allowedConsentGroups.includes(ConsentGroupsMap.preferences),
        allowedConsentGroups.includes(ConsentGroupsMap.statistics),
        allowedConsentGroups.includes(ConsentGroupsMap.marketing),
      );
    } catch (error) {
      captureException(error);
    }
  });
};

export default useSyncSskuConsentCookies;
