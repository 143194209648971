import React, { createContext, useContext } from 'react';

import { useQuery } from '@apollo/client';

import { GET_TENANT_INFO } from '~/graphql/tenant';
import { Maybe, Query, Tenant } from '~/types';
import { hostToTenantName } from '~/utils/hostToTenantName/hostToTenantName';
import { TENANTS_CONFIG, TenantsRefs } from '~/utils/tenantsConfig';

export const TenantCtx = createContext<{
  tenant?: Maybe<Tenant>;
  tenantLoading: boolean;
  fallbackTenantName: string;
  config: (typeof TENANTS_CONFIG)[TenantsRefs];
}>({
  tenant: undefined,
  tenantLoading: false,
  config: TENANTS_CONFIG[hostToTenantName(window?.location?.hostname)],
  fallbackTenantName: hostToTenantName(window?.location?.hostname),
});

export function useTenantContext() {
  const context = useContext(TenantCtx);

  if (!context) {
    throw new Error('useTenantContext must be used within a TenantProvider');
  }

  return context;
}

const TenantProvider = ({ children, host }) => {
  const { data: tenantData, loading: tenantLoading } =
    useQuery<Pick<Query, 'getTenantInfo'>>(GET_TENANT_INFO);
  const tenant = tenantData?.getTenantInfo;
  const fallbackTenantName = hostToTenantName(host || window?.location?.hostname);

  return (
    <TenantCtx.Provider
      value={{
        tenant,
        tenantLoading,
        fallbackTenantName,
        config: TENANTS_CONFIG[tenant?.name || fallbackTenantName],
      }}
    >
      {children}
    </TenantCtx.Provider>
  );
};

export default TenantProvider;
