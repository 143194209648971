import { styled } from '@mui/material';

import CustomButton from '~/components/UI/CustomButton/CustomButton';

const SecondaryButton = styled(CustomButton)`
  &.btnOutlinedPrimary {
    border-color: ${({ theme }) => theme.palette.common.blue};

    &:hover {
      background: ${({ theme }) => theme.palette.common.blue};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;

export default SecondaryButton;
