//i18n helpers
import i18n from '~/i18n';
import { hostToTenantName } from '~/utils/hostToTenantName/hostToTenantName';
import replaceTenantVariables from '~/utils/translations/replaceTenantVariables';
import { translateByTenant } from '~/utils/translations/translateByTenant';

const tenantName = hostToTenantName(window?.location?.hostname || '');
const translationFn = (key: string, vars?): string => i18n.t(key, { ...vars });

const createTranslation = translateByTenant(tenantName, translationFn);
const replaceVariables = replaceTenantVariables(tenantName, translationFn);

export const translate = (key: string, options?) =>
  replaceVariables(createTranslation(key, options));
