import { captureException } from '@sentry/react';

import { isDev } from '~/libs/util/env';

export function throwOnlyInDev(error: Error) {
  if (isDev()) {
    console.error(error);
    throw error;
  }

  captureException(error);
}
