import React, { Suspense, useEffect, useState } from 'react';

import { CacheLocation } from '@auth0/auth0-spa-js';
import { RM } from '@request-metrics/browser-agent';
import { LazyMotion } from 'framer-motion';
import { BrowserRouter } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import Swiper from 'swiper';
import { Mousewheel, Navigation, Pagination } from 'swiper/modules';

import ApolloLoader from '~/ApolloLoader';
import Loader from '~/components/Loader/Loader';
import { AUTH_APP_ID_KEY } from '~/const';
import CommonLayout from '~/containers/CommonLayout/CommonLayout';
import AlertProvider from '~/context/AlertProvider';
import { AuthProvider } from '~/context/AuthProvider/AuthProvider';
import { DiscoverController } from '~/context/DiscoverContext';
import LanguageProvider from '~/context/LanguageProvider';
import OnboardingProvider from '~/context/OnboardingContext';
import { OnlineContentConnectorController } from '~/context/OnlineContentConnectorContext';
import { RXDController } from '~/context/RXDContext';
import { ScriptsProvider } from '~/context/ScriptLoader/useScriptLoader';
import TenantProvider from '~/context/TenantProvider';
import ThemeProvider from '~/context/ThemeProvider/ThemeProvider';
import 'swiper/css/bundle';
import UtilitiesProvider from '~/context/UtilitiesProvider/UtilitiesProvider';
import { hostToTenantName } from '~/utils/hostToTenantName/hostToTenantName';
import { TenantsRefs } from '~/utils/tenantsConfig';

declare global {
  interface Window {
    dataLayer?: {
      push: (options: object) => void;
    };
    OptanonActiveGroups: string;
    HISTORY_PUSH: (to: string) => void;
    UE?: {
      pageHit?: (data: object) => void;
      resetAuth: (data: object) => void;
      destroy?: () => void;
    };
    userengage?: (eventName: string, data?: any) => Promise<any>;
    invitePageCustomTexting?: {
      [locale: string]: {
        title?: string;
        description?: string;
        signUpBtn?: string;
      };
    };
    triggerFreeTrialSignUp?: boolean;
    isSSR?: boolean;
    HYDRATED?: boolean;
    Cookiebot?: {
      consent: {
        preferences: boolean;
        statistics: boolean;
        marketing: boolean;
      };
      submitCustomConsent: (preferences: boolean, statistics: boolean, marketing: boolean) => void;
      hide: () => void;
    };
    __LANG_CODE__?: string;
    __LANG_SWITCHING_ALLOWED__: boolean;
    __RM_METADATA__?: {
      [key: string]: string;
    };
    __NONCE__: string;
  }
}

const ROUTERS = {
  server: StaticRouter,
  client: BrowserRouter,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
Swiper.use([Navigation, Pagination, Mousewheel]);

const loadFeatures = () => import('./modules/domMax').then((res) => res.default);

function App({ type, client = undefined, location, hasAuthenticatedCookie, host = '', i18n }) {
  const Router = ROUTERS[type];
  const [authAppId, setAuthAppId] = useState('');

  useEffect(() => {
    const appId = localStorage.getItem(AUTH_APP_ID_KEY);
    if (appId) {
      setAuthAppId(appId);
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === 'prod') {
      RM.install({
        token: 'v6wj4gg:i7pq3yf',
      });
      if (window?.__RM_METADATA__) {
        RM.addMetadata(window.__RM_METADATA__);
      }
    }
  }, []);

  const isSsku = hostToTenantName(host || window.location?.hostname) === TenantsRefs.Ssku;

  return (
    <Suspense fallback={<Loader isSsku={isSsku} />}>
      <LazyMotion features={loadFeatures}>
        <ScriptsProvider>
          <RXDController>
            <OnlineContentConnectorController>
              <Router location={location}>
                <DiscoverController>
                  <AuthProvider
                    domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
                    cacheLocation={'localstorage' as CacheLocation}
                    audience={process.env.REACT_APP_AUTH0_AUDIENCE || ''}
                    scope="has-subscription"
                    useRefreshTokens
                    clientId={authAppId}
                    isSsku={isSsku}
                  >
                    <AlertProvider>
                      <LanguageProvider i18n={i18n}>
                        <ApolloLoader type={type} client={client} host={host}>
                          <TenantProvider host={host}>
                            <ThemeProvider>
                              <UtilitiesProvider>
                                <OnboardingProvider>
                                  <CommonLayout
                                    setAuthAppId={setAuthAppId}
                                    hasAuthenticatedCookie={hasAuthenticatedCookie}
                                  />
                                </OnboardingProvider>
                              </UtilitiesProvider>
                            </ThemeProvider>
                          </TenantProvider>
                        </ApolloLoader>
                      </LanguageProvider>
                    </AlertProvider>
                  </AuthProvider>
                </DiscoverController>
              </Router>
            </OnlineContentConnectorController>
          </RXDController>
        </ScriptsProvider>
      </LazyMotion>
    </Suspense>
  );
}

export default App;
