import { useCallback, useEffect, useMemo, useState } from 'react';

const useOverflowHidden = () => {
  const [openedItems, setOpenedItems] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const hasOpened = Object.values(openedItems).some(Boolean);

    if (hasOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.removeAttribute('style');
    }
  }, [openedItems]);

  const register = useCallback(
    (tag: string, hidden: boolean) =>
      setOpenedItems((old) => ({
        ...old,
        [tag]: hidden,
      })),
    [],
  );

  const unregister = useCallback(
    (tag: string) =>
      setOpenedItems((old) => {
        const clone = { ...old };
        delete clone[tag];

        return clone;
      }),
    [],
  );

  return useMemo(
    () => ({
      register,
      unregister,
    }),
    [register, unregister],
  );
};

export default useOverflowHidden;
