import React, { ComponentProps } from 'react';

import { styled } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { alpha } from '@mui/material/styles';

export const LightTooltip = styled(({ className, ...props }: ComponentProps<typeof Tooltip>) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className } }} />
))`
  position: relative;
  background-color: ${({ theme }) =>
    alpha(theme.palette.common.tooltip.bg, theme.isSsku ? 1 : 0.33)};
  border: 1px solid
    ${({ theme }) => alpha(theme.palette.common.tooltip.bg, theme.isSsku ? 1 : 0.03)};
  color: ${({ theme }) => theme.palette.common.tooltip.color};
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: ${({ theme }) => theme.typography.defaultFontFamily};
  backdrop-filter: blur(10px);

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    ${({ placement, theme }) =>
      placement === 'top' &&
      `border-top: 5px solid ${alpha(theme.palette.common.tooltip.bg, theme.isSsku ? 1 : 0.33)};`}
    ${({ placement }) => placement === 'top' && 'bottom: -5px;'}
        ${({ placement, theme }) =>
      placement === 'bottom' &&
      `border-bottom: 5px solid ${alpha(
        theme.palette.common.tooltip.bg,
        theme.isSsku ? 1 : 0.33,
      )};`}
        ${({ placement }) => placement === 'bottom' && 'top: -5px;'}
        left: 50%;
    transform: translateX(-50%);
  }

  & a {
    color: ${({ theme }) => theme.palette.common.tooltip.linkColor};
  }
`;
