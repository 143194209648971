import React from 'react';

import { useTheme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

import AccountIcon from '~/assets/icons/AccountIcon';
import CalendarAddIcon from '~/assets/icons/CalendarAddIcon';
import { getButtonProps, getHeaderActions } from '~/blocks/Header/const';
import SafeLink from '~/components/SafeLink';
import CustomButton from '~/components/UI/CustomButton/CustomButton';
import SecondaryButton from '~/components/UI/SecondaryButton/SecondaryButton';
import useAuth from '~/hooks/useAuth';
import { SMALL_MARGIN, SMALL_MARGIN_PX, useIsWidthUp } from '~/theme';

const UnauthorizedHeaderActions = ({ isSearchOpen, handleLogin, navigationItems }) => {
  const theme = useTheme();
  const { loginWithPopup } = useAuth();
  const location = useLocation();
  const isXxl = !useIsWidthUp('xxl');
  const isTablet = !useIsWidthUp('xl');
  const isMobile = !useIsWidthUp('md');

  const { buttonData, filledButtonData, userData, loginButton } = getHeaderActions(navigationItems);

  return (
    <>
      {userData && (
        <AccLink
          href={userData?.url || '#'}
          className={isSearchOpen ? 'openSearch' : ''}
          data-testid="headerAccLink"
        >
          <AccountIcon color={theme.palette.common.white} aria-label="account of current user" />
        </AccLink>
      )}
      <div>
        {buttonData && !isMobile && (
          <JoinBtnLink
            data-testid="headerButton"
            {...getButtonProps(buttonData?.url, location, loginWithPopup)}
          >
            <JoinBtn>{buttonData?.title}</JoinBtn>
          </JoinBtnLink>
        )}
        {loginButton && !isMobile && (
          <JoinBtn data-testid="headerLoginButton" onClick={handleLogin}>
            {loginButton?.title}
          </JoinBtn>
        )}
        {filledButtonData && !isMobile && (
          <JoinBtnLink
            aria-label={filledButtonData?.title}
            data-testid="headerFilledButton"
            to={filledButtonData?.url || '#'}
          >
            {isXxl && !isTablet ? (
              <SecondaryJoinRoundBtn aria-label={filledButtonData?.title}>
                <CalendarAddIcon />
              </SecondaryJoinRoundBtn>
            ) : (
              <SecondaryJoinBtn>{filledButtonData?.title}</SecondaryJoinBtn>
            )}
          </JoinBtnLink>
        )}
      </div>
    </>
  );
};

export default UnauthorizedHeaderActions;

const JoinBtnLink = styled(SafeLink)`
  color: ${({ theme }) => theme.palette.common.white};
  margin-right: ${SMALL_MARGIN / 2}px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    &:last-child {
      margin-right: ${SMALL_MARGIN_PX};
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    &:last-child {
      margin-right: 0;
    }
  }
`;
const createJoinBtnStyles = (component) => styled(component)`
  margin-bottom: ${SMALL_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: 0;
  }
`;
const JoinBtn = createJoinBtnStyles(CustomButton);
const SecondaryJoinBtn = createJoinBtnStyles(SecondaryButton);
const SecondaryJoinRoundBtn = styled(SecondaryButton)`
  width: 46px;
  min-width: 46px;
  height: 46px;

  &.MuiButton-outlinedPrimary {
    padding-left: 0;
    padding-right: 0;
  }
`;
const AccLink = styled('a')`
  width: 48px;
  height: 48px;
  cursor: pointer;
  margin-left: ${SMALL_MARGIN_PX};
  margin-right: ${SMALL_MARGIN_PX};
  background: ${({ theme }) => alpha(theme.palette.common.white, 0.15)};
  padding: 12.5px;
  transition: 0.5s;
  border-radius: 999em;

  &:hover {
    box-shadow: 0px 3px 6px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)};
    background: ${({ theme }) => theme.palette.primary.main};

    & svg {
      filter: brightness(0) saturate(100%) invert(7%) sepia(28%) saturate(734%) hue-rotate(210deg)
        brightness(99%) contrast(93%);
    }
  }

  &.openSearch {
    display: none;

    ${({ theme }) => theme.breakpoints.up('md')} {
      display: block;
    }
  }
`;
