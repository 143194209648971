import React, { FC, useEffect, useMemo } from 'react';

import { useTheme } from '@mui/material';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { captureException } from '@sentry/react';
import { TFunction } from 'i18next';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

import ErrorIcon from '~/assets/icons/ErrorIcon';
import IKImage from '~/components/IKImage/IKImage';
import FullScreenBackgroundWrap from '~/components/UI/FullScreenBackgroudWrap/FullScreenBackgroundWrap';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { BIG_MARGIN_PX, LARGE_MARGIN_PX, MIDDLE_MARGIN_PX, SMALL_MARGIN_PX } from '~/theme';
import Auth0Error from '~/utils/errors/Auth0Error';
import { TENANTS_CONFIG, TenantsRefs } from '~/utils/tenantsConfig';

const backgroundUrl = '/page-images/auth0ErrorPageBg.png';

const logoTransformation = [
  {
    height: 40,
  },
];

const updatedErrors = [
  {
    tKey: 'resetPasswordLinkUsed',
    originalValue: 'This URL can be used only once',
    shouldSentSentryError: false,
  },
  {
    tKey: 'linkExpired',
    originalValue: 'Access expired.',
    shouldSentSentryError: false,
  },
];

const checkOnTextUpdates = (text: string | null | undefined, t: TFunction) => {
  if (text) {
    const match = updatedErrors.find((item) => item.originalValue === text);

    if (match) {
      return [t(`error.${match.tKey}`), match.shouldSentSentryError];
    }
  }

  return [t('error.defaultAuthErrorDescription'), true];
};

const AuthErrorPage: FC = () => {
  const location = useLocation();
  const { t } = useTenantTranslation();
  const theme = useTheme();

  const [errorMessage, shouldSendError] = useMemo(() => {
    const parsed = qs.parse(location?.search?.slice(1))?.error_description as string | undefined;
    return checkOnTextUpdates(parsed, t);
  }, [location, t]);

  useEffect(() => {
    if (shouldSendError && process.env.NODE_ENV === 'production') {
      const parsed = qs.parse(location?.search?.slice(1))?.error_description || '';
      captureException(new Auth0Error(parsed));
    }
  }, [shouldSendError, location]);

  return (
    <FullScreenBackgroundWrap backgroundUrl={backgroundUrl}>
      <Wrapper>
        <TopWrapper>
          <Heading>
            <StyledIcon color={theme.palette.common.status.brightError} />
            <Header variant="h1">{t('error.error')}</Header>
          </Heading>
          <ErrorDescription>{errorMessage}</ErrorDescription>
        </TopWrapper>
        <InstructionText>{t('page.auth0Error.instruction')}</InstructionText>
        <LogosWrapper>
          <LogoImgWrapper>
            <Link href={process.env.REACT_APP_FU_SITE_LINK}>
              <LogoImg
                path={TENANTS_CONFIG[TenantsRefs.Fu].getLogo('dark')}
                transformation={logoTransformation}
              />
            </Link>
          </LogoImgWrapper>
          <LogoImgWrapper>
            <Link href={process.env.REACT_APP_ELOD_SITE_LINK}>
              <LogoImg
                path={TENANTS_CONFIG[TenantsRefs.Em].getLogo('dark')}
                transformation={logoTransformation}
              />
            </Link>
          </LogoImgWrapper>
          <LogoImgWrapper>
            <Link href={process.env.REACT_APP_SU_SITE_LINK}>
              <LogoImg
                path={TENANTS_CONFIG[TenantsRefs.Su].getLogo('dark')}
                transformation={logoTransformation}
              />
            </Link>
          </LogoImgWrapper>
        </LogosWrapper>
        <LogosWrapper>
          <LogoImgWrapper>
            <Link href={process.env.REACT_APP_BPP_SITE_LINK}>
              <LogoImg
                path={TENANTS_CONFIG[TenantsRefs.Bpp].getLogo('dark')}
                transformation={logoTransformation}
              />
            </Link>
          </LogoImgWrapper>
          <LogoImgWrapper>
            <Link href={process.env.REACT_APP_DUKECE_SITE_LINK}>
              <SmallLogoImg
                path={TENANTS_CONFIG[TenantsRefs.Dukece].getLogo('dark')}
                transformation={logoTransformation}
              />
            </Link>
          </LogoImgWrapper>
          <LogoImgWrapper>
            <Link href={process.env.REACT_APP_SSKU_SITE_LINK}>
              <LogoImg
                path={TENANTS_CONFIG[TenantsRefs.Ssku].getLogo('dark')}
                transformation={logoTransformation}
              />
            </Link>
          </LogoImgWrapper>
        </LogosWrapper>
      </Wrapper>
    </FullScreenBackgroundWrap>
  );
};

export default AuthErrorPage;

const Wrapper = styled('div')`
  padding: 0 16px;
`;

const TopWrapper = styled('div')`
  max-width: 480px;
  margin-bottom: ${LARGE_MARGIN_PX};
  margin-left: auto;
  margin-right: auto;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${BIG_MARGIN_PX};
  }
`;

const Heading = styled('div')`
  margin-bottom: ${SMALL_MARGIN_PX};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIcon = styled(ErrorIcon)`
  width: 40px;
  height: 40px;
  margin-right: ${SMALL_MARGIN_PX};
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 48px;
    height: 48px;
  }
`;

const Header = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.status.brightError};
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 32px;
    line-height: 38px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 40px;
    line-height: 48px;
  }
`;

const ErrorDescription = styled(Typography)`
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 20px;
    line-height: 30px;
  }
`;

const InstructionText = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.palette.common.text.secondaryToWhite};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: ${SMALL_MARGIN_PX};
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 18px;
    line-height: 28px;
  }
`;

const LogosWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.palette.common.white};
    opacity: 0.33;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:last-child::after {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ${MIDDLE_MARGIN_PX};
    }

    &::after {
      display: none;
    }
  }
`;

const LogoImgWrapper = styled('div')`
  position: relative;
  padding: ${MIDDLE_MARGIN_PX};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.palette.common.white};
    opacity: 0.33;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:last-child::after {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 ${MIDDLE_MARGIN_PX};

    &::after {
      width: 1px;
      height: 100%;
      top: 0;
    }
  }
`;

const LogoImg = styled(IKImage)`
  height: 40px;
  max-width: 100%;
  object-fit: contain;
`;
const SmallLogoImg = styled(LogoImg)`
  height: 32px;
`;
const Link = styled('a')`
  display: block;
  width: 100%;
  text-align: center;
`;
