import React, { FC, useState } from 'react';

import { useTheme } from '@mui/material';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import ChevronTopIcon from '~/assets/icons/ChevronTopIcon';
import MegaMenu from '~/blocks/Header/components/MegaMenu';
import { ExpandedNavigationItem } from '~/blocks/Header/const';
import SafeLink from '~/components/SafeLink';
import { useUserInfoContext } from '~/context/UserInfoProvider';
import { CssUtilityType } from '~/styled';
import { MIDDLE_MARGIN_PX, SMALL_MARGIN_PX } from '~/theme';
import { NavigationItem } from '~/types';

interface MenuItemProps {
  item: NavigationItem | ExpandedNavigationItem;
  isMobile: boolean;
  wrapperCss?: CssUtilityType;
  isManager?: boolean | null;
  handleClickLink?: () => void;
  scrolled: boolean;
}

function isExpandedNavigationItem(
  pet: NavigationItem | ExpandedNavigationItem,
): pet is ExpandedNavigationItem {
  return (pet as ExpandedNavigationItem).managerUrl !== undefined;
}

const MenuItem: FC<MenuItemProps> = ({
  isManager,
  isMobile,
  item,
  wrapperCss,
  handleClickLink,
  scrolled,
}) => {
  const theme = useTheme();
  const isMegaMenu = item?.type === 'MegaMenu';
  const { hasAccessToContent } = useUserInfoContext();

  const location = useLocation();
  const pathname = location?.pathname;

  const managerLink = isExpandedNavigationItem(item) && item?.managerUrl;
  const avoidManagerUrl =
    isExpandedNavigationItem(item) && item?.avoidManagerUrlWithoutSub && !hasAccessToContent;
  const link = isManager && managerLink && !avoidManagerUrl ? managerLink : item?.url;
  const isActive = pathname === item?.url || pathname === managerLink;

  const [submenuOpened, setSubmenuOpened] = useState(false);

  const toggleSubmenu = () => {
    if (!isMobile) return;
    setSubmenuOpened((prevState) => !prevState);
  };

  return (
    <LinkWrapper
      className={cx(
        isMegaMenu && 'hasSubItems',
        isExpandedNavigationItem(item) && item?.className ? item.className : '',
      )}
      isMegaMenu={isMegaMenu}
      optionalStyles={wrapperCss}
      data-testid="menuItem"
    >
      <LinkWrap isSubmenuOpened={isMegaMenu && submenuOpened}>
        <Link
          to={link}
          isSubmenuOpened={isMegaMenu && submenuOpened}
          className={cx(isActive ? 'active' : '', isMegaMenu && 'withSubItems')}
          onClick={() => handleClickLink && handleClickLink()}
          data-testid="menuItemLink"
        >
          {item?.title}
        </Link>
        {isMegaMenu && (
          <StyledChevronTopIcon
            color={
              isMobile
                ? submenuOpened
                  ? theme.palette.common.primary
                  : theme.palette.common.white
                : theme.palette.common.white
            }
            className={cx('linkIcon', submenuOpened && 'opened')}
            onClick={toggleSubmenu}
          />
        )}
      </LinkWrap>
      {isMegaMenu && (
        <Submenu className="sublistList" opened={submenuOpened} scrolled={scrolled}>
          {/*@ts-ignore*/}
          <MegaMenu blocks={item?.blocks} />
        </Submenu>
      )}
    </LinkWrapper>
  );
};

export default MenuItem;

const LinkWrap = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSubmenuOpened',
})<{ isSubmenuOpened: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ isSubmenuOpened, theme }) =>
    isSubmenuOpened ? theme.palette.common.answerItem : theme.palette.common.header.overlay};
  margin: 0 -24px;
  padding: 0 24px;
  width: 100vw;
  transition: background 0.5s ease-in-out;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 0 -30px;
    padding: 0 30px;
  }
  ${({ theme }) => theme.breakpoints.up('xl')} {
    justify-content: flex-start;
    width: auto;
    background: transparent;
    padding: unset;
    margin: unset;
  }
`;

const Link = styled(SafeLink, {
  shouldForwardProp: (prop) => prop !== 'isSubmenuOpened',
})`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  color: ${({ isSubmenuOpened, theme }) =>
    isSubmenuOpened ? theme.palette.common.primary : theme.palette.common.white};
  display: block;
  transition: 0.3s;
  padding: ${SMALL_MARGIN_PX} 0;
  margin-left: 0;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('xl')} {
    font-size: 16px;
    color: ${({ theme }) => theme.palette.common.white};
    padding: 16px 0;
    text-align: center;
    display: flex;
    align-items: center;
    color: inherit;
    font-weight: bold;
    position: relative;
    &.withSubItems {
      z-index: 10;
    }
    &:not(.withSubItems):after {
      content: '';
      position: absolute;
      top: 80%;
      height: 3px;
      width: 0%;
      left: 1%;
      background-color: ${({ theme }) => theme.palette.primary.main};
      transition: 0.5s ease all 0.3s;
    }
    &:after {
      width: 99%;
      transition: 0.3s ease all;
    }
    &:not(.withSubItems).active:after {
      width: 99%;
    }
  }
  ${({ theme }) => theme.breakpoints.between('sm', 'lg')} {
    font-size: 16px;
  }
`;

const Submenu = styled('div', {
  shouldForwardProp: (prop) => prop !== 'opened' && prop !== 'scrolled',
})<{ opened: boolean; scrolled: boolean }>`
  display: ${({ opened }) => (opened ? 'block' : 'none')};
  width: 100%;
  transition: all 0.5s ease-in-out;
  ${({ theme }) => theme.breakpoints.up('xl')} {
    position: fixed;
    top: ${({ scrolled }) => (scrolled ? '68' : '85')}px;
    left: 0;
    width: 100vw;
  }
`;
const StyledChevronTopIcon = styled(ChevronTopIcon)`
  display: block;
  width: 12px;
  height: 12px;
  margin-left: 6px;
  transform: rotate(180deg);
  transition: transform 0.3s;
  &.opened {
    transform: rotate(0deg);
  }
`;
const LinkWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMegaMenu' && prop !== 'optionalStyles',
})<{
  isMegaMenu: boolean;
  optionalStyles?: CssUtilityType;
}>`
  z-index: 2;
  position: relative;
  display: flex;
  &.hasSubItems {
    flex-direction: column;
    justify-content: center;
  }
  &.home-link {
    display: none;
  }
  ${({ theme }) => theme.breakpoints.up('xl')} {
    margin-left: ${({ isMegaMenu }) => (isMegaMenu ? '0' : MIDDLE_MARGIN_PX)};
    padding-left: ${({ isMegaMenu }) => (isMegaMenu ? MIDDLE_MARGIN_PX : '0')};
    &.home-link {
      display: flex;
    }
    &:first-child {
      margin-left: 0;
      padding-left: 0;
    }
    &.hasSubItems {
      &:hover .linkIcon {
        ${({ theme }) => theme.breakpoints.up('md')} {
          transform: rotate(0);
        }
      }
      &:hover .sublistList {
        opacity: 1;
        display: block;
        height: auto;
      }
    }
  }
  ${({ optionalStyles }) => optionalStyles}
`;
