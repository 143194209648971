import { useCallback, useEffect, useRef, useState } from 'react';

const useTick = (timeInterval: number, initialValue: number = 0) => {
  const [tickValue, setTickValue] = useState(initialValue);
  const [targetValue, setTargetValue] = useState(initialValue);
  const [ticking, setTicking] = useState<boolean>(false);
  const intervalInstance = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    setTickValue(initialValue);
    setTargetValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!ticking) return;

    if (intervalInstance.current) {
      clearInterval(intervalInstance.current);
    }

    intervalInstance.current = setInterval(() => {
      setTickValue((oldTickValue) => {
        const tickUp = targetValue >= oldTickValue;
        const tickDown = !tickUp;

        if (tickUp) {
          const isMax = oldTickValue >= targetValue;

          if (isMax) {
            setTicking(false);
            clearInterval(intervalInstance.current);
            return targetValue;
          }

          return oldTickValue + 1;
        }

        if (tickDown) {
          const isMin = oldTickValue <= targetValue;

          if (isMin) {
            setTicking(false);
            clearInterval(intervalInstance.current);
            return targetValue;
          }

          return oldTickValue - 1;
        }

        return oldTickValue;
      });
    }, timeInterval);
  }, [ticking, targetValue, intervalInstance, initialValue, timeInterval]);

  const tickTo = useCallback((value) => {
    setTargetValue(value);
    setTicking(true);
  }, []);

  return {
    tickTo,
    tickValue,
  };
};

export default useTick;
