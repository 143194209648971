import React, { FC, ReactNode } from 'react';

import { styled } from '@mui/material/styles';

interface ISwitchIcons {
  originalIcon: ReactNode;
  replacementIcon: ReactNode;
  showOriginal: boolean;
  className?: string;
}

const FadedSwitchIcons: FC<ISwitchIcons> = ({
  originalIcon,
  replacementIcon,
  showOriginal,
  className,
  ...rest
}) => (
  <Icons className={className}>
    <IconWrapper active={showOriginal} {...rest}>
      {originalIcon}
    </IconWrapper>
    <ReplacementIconWrapper active={!showOriginal} {...rest}>
      {replacementIcon}
    </ReplacementIconWrapper>
  </Icons>
);

export default FadedSwitchIcons;

const Icons = styled('div')`
  position: relative;
  display: inline-block;
`;
const IconWrapper = styled('span', {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>`
  display: flex;

  > * {
    opacity: ${({ active }) => (active ? 1 : 0)};
    transition: 0.3s;
  }
`;
const ReplacementIconWrapper = styled(IconWrapper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
