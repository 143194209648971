import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import cx from 'classnames';

import ChevronTopIcon from '~/assets/icons/ChevronTopIcon';
import IKImage from '~/components/IKImage/IKImage';
import LazyLoadImage from '~/components/LazyloadImage/LazyLoadImage';
import SafeLink from '~/components/SafeLink';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import {
  BIG_MARGIN_PX,
  BREAKPOINTS_VALUES,
  LARGE_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  SMALL_MARGIN_PX,
} from '~/theme';
import { AllProfessionals, Background, Maybe } from '~/types';

const SRCSET = [{ width: 54 }, { width: 50 }];

interface HalfWidthImgBlockProps {
  data: AllProfessionals;
}

const HalfWidthImgBlock: FC<HalfWidthImgBlockProps> = ({ data }) => {
  const background = data?.background;
  const desktopImg = data?.image;
  const heading = data?.heading;
  const items = data?.content;

  const { t } = useTenantTranslation();

  if (!items?.length) return null;

  return (
    <Wrapper
      className={cx(background === 'Dark' ? 'dark-block' : 'light-block', 'half-width-img-block')}
      background={background}
    >
      <HalfWidthImg className="half-width-img" background={background}>
        {desktopImg && (
          <LazyLoadImage
            path={desktopImg}
            draggable={false}
            alt={heading}
            srcset={[{ width: 900 }]}
            sizes={`(min-width:${BREAKPOINTS_VALUES['md']}px) 900px`}
          />
        )}
      </HalfWidthImg>
      <Content>
        <Heading variant="h2" component="h2">
          {heading}
        </Heading>

        <Items>
          {items &&
            items.map((item) => {
              const heading = item?.title;
              const description = item?.sub_title || t('block.halfImgBlock.seePathways');

              return (
                <Item
                  to={item?.link || '#'}
                  key={item?.image}
                  className="half-width-img-block__item"
                >
                  <ImageWrap>
                    <IKImage
                      path={item?.image}
                      draggable={false}
                      alt={heading}
                      srcset={SRCSET}
                      sizes={`(max-width:${BREAKPOINTS_VALUES['md']}px) 54px, 50px`}
                    />
                  </ImageWrap>
                  <TextWrapper>
                    <ItemTitle variant="h3" component="h3">
                      {heading}
                      <StyledChevronTopIcon />
                    </ItemTitle>
                    <ItemText>{description}</ItemText>
                  </TextWrapper>
                </Item>
              );
            })}
        </Items>
      </Content>
    </Wrapper>
  );
};

export default HalfWidthImgBlock;

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background?: Maybe<Background> }>`
  color: ${({ theme }) => theme.palette.common.white};
  position: relative;
  overflow: hidden;
  background: ${({ background, theme }) =>
    background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light};
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    padding: 0;
  }
`;
const HalfWidthImg = styled(Box, { shouldForwardProp: (prop) => prop !== 'background' })<{
  background?: Maybe<Background>;
}>`
  overflow: hidden;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      ${({ theme }) => alpha(theme.palette.common.blackSecondary, 0.52)} 52.18%,
      ${({ background, theme }) =>
          background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light}
        88.64%
    );
    -webkit-background-origin: padding-box;
    -o-background-origin: padding-box;
    -moz-background-origin: padding;
  }
  & img {
    min-width: 100%;
    min-height: 100%;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 360px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 360px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    position: absolute;
    max-width: 50vw;
    left: 0;
    right: 50vw;
    top: 0;
    bottom: 0;
    &::after {
      background: linear-gradient(
        90deg,
        ${({ theme }) => alpha(theme.palette.common.blackSecondary, 0.1)} 37.59%,
        ${({ background, theme }) =>
            background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light}
          95.39%
      );
    }
  }
`;
const Content = styled(Box)`
  padding: ${MIDDLE_MARGIN_PX} ${SMALL_MARGIN_PX};
  position: relative;
  margin-top: -100px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 0;
    left: 50vw;
    max-width: 50vw;
    width: 50vw;
    padding: ${LARGE_MARGIN_PX} 20px ${LARGE_MARGIN_PX} ${MIDDLE_MARGIN_PX};
  }
`;
const Heading = styled(Typography)`
  & {
    margin-bottom: ${SMALL_MARGIN_PX};
    font-size: 24px;
    line-height: 1.4;
    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-bottom: ${MIDDLE_MARGIN_PX};
      font-size: 28px;
      max-width: 560px;
    }
  }
` as typeof Typography;
const Items = styled(Box)`
  margin-top: 22px;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: ${MIDDLE_MARGIN_PX};
    max-width: 560px;
  }
`;
const Item = styled(SafeLink)`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: ${MIDDLE_MARGIN_PX};
    ${({ theme }) => theme.breakpoints.up('xl')} {
      margin-bottom: ${BIG_MARGIN_PX};
    }
  }
  &:hover {
    text-decoration: none;
    & svg {
      color: ${({ theme }) => theme.palette.common.violet};
    }
  }
`;
const ImageWrap = styled('div')`
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 54px;
    height: 54px;
  }
  & img {
    width: 100%;
    max-height: 100%;
  }
`;
const TextWrapper = styled('div')`
  flex: 2;
  padding-left: ${SMALL_MARGIN_PX};
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-left: ${MIDDLE_MARGIN_PX};
  }
`;
const ItemTitle = styled(Typography)`
  font-size: 18px;
  line-height: 1.4;
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 20px;
    margin-bottom: 10px;
  }
` as typeof Typography;
const StyledChevronTopIcon = styled(ChevronTopIcon)`
  width: 16px;
  height: 16px;
  transform: rotate(90deg);
  margin-top: 6px;
  transition: color 0.3s;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 20px;
    height: 20px;
  }
`;
const ItemText = styled(Typography)`
  font-size: 16px;
  line-height: 1.4;
  color: ${({ theme }) => theme.palette.common.violet};
` as typeof Typography;
