import React, { FC, useRef } from 'react';

import { styled } from '@mui/material/styles';

import BaseSelect, { BaseSelectProps } from '~/components/Select/BaseSelect';
import { MIDDLE_MARGIN_PX, SMALL_MARGIN_PX } from '~/theme';

interface SelectProps extends BaseSelectProps {
  className?: string;
}

const Select: FC<Omit<SelectProps, 'anchorRef'>> & { Wrapper: typeof SelectWrapper } = ({
  className,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <SelectWrapper ref={ref} className={className}>
      <BaseSelect anchorRef={ref} {...rest} />
    </SelectWrapper>
  );
};

export default Select;

const SelectWrapper = styled('div')`
  display: inline-flex;
  position: relative;
  align-items: center;
  max-width: 100%;
  border-radius: 25px;
  background: ${({ theme }) => theme.palette.common.quiz.bg};

  & > button {
    flex-grow: 1;
  }

  & > button:last-of-type {
    padding-right: ${SMALL_MARGIN_PX};
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;

    ${({ theme }) => theme.breakpoints.up('md')} {
      padding-right: ${MIDDLE_MARGIN_PX};
    }
  }

  & > button:first-of-type {
    padding-left: ${SMALL_MARGIN_PX};
    border-bottom-left-radius: inherit;
    border-top-left-radius: inherit;

    ${({ theme }) => theme.breakpoints.up('md')} {
      padding-left: ${MIDDLE_MARGIN_PX};
    }
  }
`;

Select.Wrapper = SelectWrapper;
