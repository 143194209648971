import React from 'react';

import { styled } from '@mui/material/styles';

import Trans from '~/components/Trans';
import UserCom from '~/utils/UserCom';

const SubscriptionError = () => (
  <SubscriptionErrorWrapper>
    <Trans i18nKey="alert.subscriptionError">
      We’ve encountered a problem with your subscription. Please
      <PointerBold onClick={UserCom.openWidget}>contact support</PointerBold> so we can resolve it
      quickly.
    </Trans>
  </SubscriptionErrorWrapper>
);

export const errorMessages = {
  GetCompanyOwnerChargebeeInfo: <SubscriptionError />,
  GetB2CLearnerChargebeeInfo: <SubscriptionError />,
  GetB2BLearnerChargebeeInfo: <SubscriptionError />,
};

const PointerBold = styled('b')`
  cursor: pointer;
`;
const SubscriptionErrorWrapper = styled('div')`
  text-align: center;
`;
