import React from 'react';

import { styled } from '@mui/material';
import cn from 'classnames';

import { LARGE_MARGIN_PX, MIDDLE_MARGIN_PX } from '~/theme';
import { Background } from '~/types';

const CustomBlockWrapper = ({ children, background, className = '', ...rest }) => {
  return (
    <Wrapper
      className={cn(background === 'Dark' ? 'dark-block' : 'light-block', className)}
      background={background}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

export default CustomBlockWrapper;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background?: Background | null }>`
  padding-top: ${MIDDLE_MARGIN_PX};
  padding-bottom: ${MIDDLE_MARGIN_PX};
  background: ${({ theme }) => theme.palette.secondary.dark};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: ${LARGE_MARGIN_PX};
    padding-bottom: ${LARGE_MARGIN_PX};
    background: ${({ background, theme }) =>
      background === 'Dark'
        ? theme.palette.secondary.dark
        : theme.palette.common.blockBackground.contrast};
  }
`;
