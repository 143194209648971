import React, { FC } from 'react';

import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import ChevronRightIcon from '~/assets/icons/ChevronRightIcon';
import IKImage from '~/components/IKImage/IKImage';
import SafeLink from '~/components/SafeLink';
import { ButtonText, MetaText } from '~/components/UI/Texts';
import useIsSsku from '~/hooks/tenant/useIsSsku';
import { MIDDLE_RADIUS_PX, SMALL_MARGIN_PX, TINY_MARGIN_PX } from '~/theme';

interface CardWithIconAndLinkProps {
  title?: string | null;
  description?: string | null;
  imageUrl?: string | null;
  link?: string | null;
}

const CardWithIconAndLink: FC<CardWithIconAndLinkProps> = ({
  title,
  description,
  imageUrl,
  link,
}) => {
  const theme = useTheme();
  const isSsku = useIsSsku();

  return (
    <Wrapper>
      <StyledLink aria-label={title} to={link}>
        <HeaderRow>
          <TitleWrapper>
            {imageUrl && <Icon path={imageUrl} />}
            <Title>{title}</Title>
          </TitleWrapper>
          {!isSsku && <ArrowIcon className="arrow-icon" color={theme.palette.common.violet} />}
        </HeaderRow>
        {description && <Description>{description}</Description>}
      </StyledLink>
    </Wrapper>
  );
};

export default CardWithIconAndLink;

const ArrowIcon = styled(ChevronRightIcon)`
  width: 16px;
  height: 16px;
  visibility: hidden;
  margin-left: ${SMALL_MARGIN_PX};
`;

const Wrapper = styled('div')`
  position: relative;
  display: flex;
  border-radius: ${MIDDLE_RADIUS_PX};
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    &:hover {
      background: ${({ theme }) => theme.palette.common.blockBackground.main};
      box-shadow: 0px 3px 6px ${({ theme }) => theme.palette.common.header.shadowValue};
      cursor: pointer;
      & .arrow-icon {
        visibility: visible;
      }
    }
  }
`;

const StyledLink = styled(SafeLink)`
  display: block;
  padding: ${SMALL_MARGIN_PX};
  width: 100%;
`;

const HeaderRow = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const Icon = styled(IKImage)`
  height: 24px;
  margin-right: ${TINY_MARGIN_PX};
`;

const Title = styled(ButtonText)`
  font-weight: bold;
  ${({ theme }) => theme.breakpoints.up('xl')} {
    text-transform: uppercase;
  }
`;

const Description = styled(MetaText)`
  color: ${({ theme }) => theme.palette.common.text.secondaryToPrimary};
  margin-top: ${SMALL_MARGIN_PX};
  display: none;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
  }
`;
