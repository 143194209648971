import { useCallback, useEffect, useState } from 'react';

function useAnimationPlayState(duration: number) {
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (playing) {
      setTimeout(() => setPlaying(false), duration + 100);
    }
  }, [playing, duration]);

  const trigger = useCallback(() => setPlaying(true), []);

  return { trigger, playing };
}

export default useAnimationPlayState;
