import React, { FC } from 'react';

import { Container } from '@mui/material';
import { styled } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import Trans from '~/components/Trans';
import { BodyIntroText, H1 } from '~/components/UI/Texts';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { LARGE_MARGIN_PX, MIDDLE_MARGIN_PX } from '~/theme';

const IncognitoWarning: FC = () => {
  const { t } = useTenantTranslation();

  return (
    <>
      <CssBaseline />
      <Wrapper>
        <Container>
          <Title variant="h1" align="center">
            {t('error.cookiesErrorTitle')}
          </Title>
          <Description align="center">
            <Trans i18nKey="error.cookiesErrorDesc">
              Your browser has blocked access to third-party cookies which are required to view this
              course; this may be because you are using "incognito" mode. Please either re-open this
              page in not in "incognito" mode or enable third-party cookie access for this site. You
              can follow these&#x20
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.microsoft.com/en-us/office/enable-cookies-6b018d22-1d24-43d9-8543-3d35ddb2cb52"
              >
                instructions
              </a>{' '}
              to allow third-party cookies
            </Trans>
          </Description>
        </Container>
      </Wrapper>
    </>
  );
};

export default IncognitoWarning;

const Wrapper = styled('div')`
  padding: ${LARGE_MARGIN_PX} 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
`;
const Title = styled(H1)`
  && {
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }
`;
const Description = styled(BodyIntroText)`
  color: ${({ theme }) => theme.palette.common.gray};

  & a {
    font-weight: bold;
    color: ${({ theme }) => theme.palette.common.white};

    &:hover {
      color: ${({ theme }) => theme.palette.common.blue};
    }
  }
`;
