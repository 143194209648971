import React, { FC } from 'react';

import { keyframes, styled } from '@mui/material/styles';

import StarEdgyFilledIcon from '~/assets/icons/StarEdgyFilledIcon';
import StarEdgyIcon from '~/assets/icons/StarEdgyIcon';
import Sparks from '~/components/Sparks';
import FadedSwitchIcons from '~/components/animations/FadedSwitchIcons';
import useAnimationPlayState from '~/hooks/useAnimationPlayState';

const DURATION_OF_SPARKLING_ANIM = 600;

const SparklingStar: FC<{
  filled: boolean;
  sparkling?: boolean;
  playing?: boolean;
  onClick?: () => void;
  loading?: boolean;
  onMouseOver?: () => void;
  maxSize?: number;
}> = ({
  filled = false,
  sparkling,
  playing: controlledPlaying,
  onClick,
  loading,
  maxSize,
  ...rest
}) => {
  const { trigger, playing: localPlaying } = useAnimationPlayState(DURATION_OF_SPARKLING_ANIM);

  const handleClick = () => {
    trigger();
    onClick?.();
  };

  const playing = controlledPlaying || localPlaying;

  return (
    <StartStarWrapper onClick={handleClick} {...rest}>
      <StarWrapper shouldPlay={playing} maxSize={maxSize}>
        <FadedSwitchIcons
          originalIcon={<StarIcon loading={loading} />}
          replacementIcon={<FilledStarIcon loading={loading} />}
          showOriginal={!filled}
        />
      </StarWrapper>
      {sparkling && <StyledSparks playing={playing} />}
    </StartStarWrapper>
  );
};

export default SparklingStar;

const bounce = ({ max = 1.2 }) => keyframes`
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(${max});
  }
  100% {
    transform: scale(1);
  }
`;
const StarWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'shouldPlay' && prop !== 'maxSize',
})<{
  shouldPlay?: boolean;
  maxSize?: number;
}>`
  position: relative;
  z-index: 1;
  display: flex;
  animation-name: ${(props) => props.shouldPlay && bounce({ max: props.maxSize })};
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-play-state: ${({ shouldPlay }) => (shouldPlay ? 'running' : 'paused')};
`;
const StartStarWrapper = styled('div')`
  position: relative;
`;
const createStyledStarIcon = (tag) => styled(tag, {
  shouldForwardProp: (prop) => prop !== 'loading',
})`
  position: relative;
  z-index: 1;
  width: 40px;
  height: 40px;
  color: ${({ theme }) => theme.palette.common.accent};
  cursor: ${({ loading }) => (loading ? 'wait' : 'pointer')};
  transition: 0.3s;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 64px;
    height: 64px;
  }
`;
const StarIcon = createStyledStarIcon(StarEdgyIcon);
const FilledStarIcon = createStyledStarIcon(StarEdgyFilledIcon);
const StyledSparks = styled(Sparks)`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%, -50%) scale(1.1) rotate(10deg);
  pointer-events: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    transform: translate(-50%, -50%) scale(1.4) rotate(10deg);
  }
`;
