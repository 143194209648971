import { styled } from '@mui/material';
import { alpha, SxProps, Theme } from '@mui/material/styles';

import { MIDDLE_RADIUS_PX } from '~/theme';

const AnimatedSkeleton = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'squared' &&
    prop !== 'xs' &&
    prop !== 'sm' &&
    prop !== 'md' &&
    prop !== 'lg' &&
    prop !== 'ml' &&
    prop !== 'sx',
})<{
  squared?: boolean;
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  ml?: string;
  sx?: SxProps<Theme>;
}>`
  width: 100%;
  height: ${({ xs = 0 }) => !!xs && xs};
  background: linear-gradient(
    90deg,
    ${({ theme }) => alpha(theme.palette.common.violetSecondary, 0.3)},
    ${({ theme }) => theme.palette.common.blackPure},
    transparent
  );
  background-size: 200% 200%;
  animation: gradient 3s ease-in-out infinite;
  animation-direction: alternate;
  opacity: 0.2;
  border-radius: ${({ squared }) => !squared && MIDDLE_RADIUS_PX};

  @keyframes gradient {
    0% {
      background-size: 1000% 200%;
      background-position: 0 0;
    }
    100% {
      background-size: 200% 200%;
      background-position: 100% 0;
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: ${({ sm = 0 }) => !!sm && sm};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: ${({ md = 0 }) => !!md && md};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    height: ${({ lg = 0 }) => !!lg && lg};
  }

  ${({ theme }) => theme.breakpoints.up('ml')} {
    height: ${({ ml = 0 }) => !!ml && ml};
  }
`;

export default AnimatedSkeleton;
