import { validateEmail } from '~/utils/validateEmail';

// validation fn returns error message or empty string
type Validation = (x: any) => string;

class Validations {
  errorMsg: string | string[] = '';
  valueIsArray = false;
  validations: Validation[] = [];

  pushValidation(validationFn: Validation) {
    if (this.valueIsArray) {
      this.validations.push((value) => {
        return value.map(validationFn);
      });
    } else {
      this.validations.push(validationFn);
    }
  }

  required(errorMsg = '') {
    this.pushValidation((value) => (!value?.toString().trim() ? errorMsg : ''));
    return this;
  }

  email(errorMsg = '') {
    this.pushValidation((value) => (!validateEmail(value) ? errorMsg : ''));
    return this;
  }

  exist(errorMsg = '') {
    this.pushValidation((value) => (!value ? errorMsg : ''));
    return this;
  }

  empty(errorMsg = '') {
    this.pushValidation((value) => (!value.length ? errorMsg : ''));
    return this;
  }

  minLength(errorMsg = '', length = 3) {
    this.pushValidation((value) => {
      if (Array.isArray(value)) {
        return value?.length <= length ? errorMsg : '';
      }

      return value?.trim().length <= length ? errorMsg : '';
    });
    return this;
  }

  maxLength(errorMsg = '', length = 200) {
    this.pushValidation((value) => {
      if (Array.isArray(value)) {
        return value?.length > length ? errorMsg : '';
      }

      return value?.trim().length > length ? errorMsg : '';
    });
    return this;
  }

  greaterThan(errorMsg = '', min) {
    this.pushValidation((value) => (value <= min ? errorMsg : ''));
    return this;
  }

  lessThan(errorMsg = '', min) {
    this.pushValidation((value) => (value > min ? errorMsg : ''));
    return this;
  }

  match(regExp, errorMsg = '') {
    this.pushValidation((value) => (!value?.trim().match(regExp) ? errorMsg : ''));
    return this;
  }

  each() {
    this.valueIsArray = true;
    return this;
  }

  run(value) {
    this.errorMsg = this.validations.reduce((acc, validation) => {
      let hasError: boolean;

      if (Array.isArray(acc)) {
        hasError = !!acc.filter(Boolean).length;
      } else {
        hasError = !!acc.length;
      }

      if (!hasError) {
        const msg = validation(value);
        return msg;
      }
      return acc;
    }, '');
    return this;
  }
}

export const createValidation = () => new Validations();

interface ISchema {
  [key: string]: Validations;
}

export const createSchema = (schema: ISchema) => ({
  cast: <T extends {}>(fields: T) => {
    const errors: Partial<T> = Object.entries(fields).reduce((accErrors, [key, value]) => {
      const validations = schema[key];

      if (!validations) {
        return accErrors;
      }

      const validated = validations?.run(value);

      if (validated?.errorMsg) {
        return { ...accErrors, [key]: validated.errorMsg };
      } else {
        return accErrors;
      }
    }, {});

    return errors;
  },
});
