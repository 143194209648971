import { useEffect } from 'react';

import useSubscriptionInfo from '~/hooks/useSubscriptionInfo';
import { USER_ROLES } from '~/pages/const';
import { LearnerInfo } from '~/types';
import { updateUserComPlanInfo } from '~/utils/userComUtils';

const useUpdateUserComSubInfo = (userInfo?: LearnerInfo | null) => {
  const subscription = useSubscriptionInfo(userInfo);

  useEffect(() => {
    if (!userInfo) {
      return;
    }

    if (userInfo?.role === USER_ROLES.LearnerWithoutSubscription) {
      return updateUserComPlanInfo({ userInfo });
    }

    if (subscription?.type || subscription?.renews_automaticly_on) {
      const isOwnerSub =
        subscription?.__typename === 'MySubscriptionCompanyOwnerChargebeeInfo' ||
        subscription?.__typename === 'MySubscriptionB2CLearnerChargebeeInfo';

      return updateUserComPlanInfo({
        plan: subscription?.type,
        planType: subscription?.subscription_group_type,
        renewDate: subscription?.renews_automaticly_on,
        trialLeft: isOwnerSub ? subscription?.remaining_trials?.left : 0,
        userInfo,
      });
    }
  }, [subscription, userInfo]);
};

export default useUpdateUserComSubInfo;
