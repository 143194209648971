import React from 'react';

import { styled } from '@mui/material';

import { SMALL_MARGIN_PX } from '~/theme';

interface ShareButtonProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const ShareButton: React.FC<ShareButtonProps> = ({ children, className, onClick, ...rest }) => {
  return (
    <Wrapper onClick={onClick} className={className} {...rest}>
      {children}
    </Wrapper>
  );
};

export default ShareButton;

const Wrapper = styled('div')`
  padding: ${SMALL_MARGIN_PX};
  background: ${({ theme }) => theme.palette.common.category.cpd};
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.white};
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  white-space: nowrap;

  & svg {
    width: 18px;
    height: 18px;
    margin-right: 9px;
  }
`;
