import React, { ElementType } from 'react';

import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';
import cx from 'classnames';

import { blink } from '~/components/Blink/Blink';
import { useTenantContext } from '~/context/TenantProvider';
import { TenantsRefs } from '~/utils/tenantsConfig';

export enum BUTTON_VARIANT {
  Contained = 'contained',
  Outlined = 'outlined',
}

export enum BUTTON_COLOR {
  Primary = 'primary',
  Secondary = 'secondary',
}

interface CustomButtonProps {
  btnClass?: string;
  className?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  color?: 'primary' | 'secondary';
  variant?: 'contained' | 'outlined';
  disabled?: boolean;
  type?: 'button' | 'submit';
  id?: string;
  loading?: boolean;
  'data-testid'?: string;
  component?: ElementType;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  btnClass,
  onClick,
  color = BUTTON_COLOR.Primary,
  variant,
  disabled = false,
  type = 'button',
  loading,
  id,
  ...rest
}) => {
  const { tenant } = useTenantContext();
  const defaultVariant =
    tenant?.name === TenantsRefs.Ssku ? BUTTON_VARIANT.Contained : BUTTON_VARIANT.Outlined;

  const content =
    typeof children === 'string' ? (
      <TextChild>{children}</TextChild>
    ) : Array.isArray(children) ? (
      children.map((child, index) =>
        typeof child === 'string' ? <TextChild key={index}>{child}</TextChild> : child,
      )
    ) : (
      children
    );

  return (
    <StyledButton
      id={id}
      color={color}
      onClick={onClick}
      disabled={disabled}
      type={type}
      loading={loading}
      classes={{
        contained: 'btnContained',
        outlined: 'btnOutlined',
        containedPrimary: 'btnContainedPrimary',
        containedSecondary: 'btnContainedSecondary',
        outlinedPrimary: 'btnOutlinedPrimary',
        outlinedSecondary: 'btnOutlinedSecondary',
        disabled: 'btnDisabled',
      }}
      {...rest}
      className={cx(btnClass, rest?.className)}
      variant={variant || defaultVariant}
    >
      {content}
    </StyledButton>
  );
};

export default CustomButton;

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'loading',
})<{ loading?: boolean }>`
  border-radius: 999em;
  line-height: 1;
  font-weight: bold;
  box-shadow: 0 3px 6px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)};
  text-decoration: none;
  font-size: 16px;
  min-width: 150px;
  animation: ${({ theme }) =>
      blink(alpha(theme.palette.common.white, 0), alpha(theme.palette.common.white, 0.2))}
    1.2s infinite ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 0.3s;
  animation-play-state: ${({ loading }) => (loading ? 'running' : 'paused')};
  padding: 19px 35px;
  border-width: 3px;
  border-style: solid;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 15px 32px;
  }

  &.btnOutlined {
    &:hover {
      border-width: 3px;
    }
  }

  &.btnContained {
    &:hover {
      background: ${({ theme }) => theme.palette.common.transparent};
    }
  }

  &.btnOutlinedPrimary {
    color: ${({ theme }) => theme.palette.common.white};
    border-color: ${({ theme }) => theme.palette.primary.light};

    &:hover {
      background: ${({ theme }) => theme.palette.primary.light};
      color: ${({ theme }) => theme.palette.common.blueDark};
    }

    &.btnDisabled {
      border: 3px solid ${({ theme }) => alpha(theme.palette.common.text.white, 0.33)};
      color: ${({ theme }) => theme.palette.common.text.white};

      &:hover {
        background: transparent;
      }

      & > * {
        opacity: 0.33;
      }
    }
  }

  &.btnOutlinedSecondary {
    color: ${({ theme }) => theme.palette.common.white};
    background: ${({ theme }) => theme.palette.common.button.secondary.outlined.bg};
    border-color: ${({ theme }) => theme.palette.common.button.secondary.outlined.border};

    &:hover {
      background: ${({ theme }) => theme.palette.common.button.secondary.outlined.hover.bg};
      color: ${({ theme }) => theme.palette.common.button.secondary.outlined.hover.color};
    }

    &.btnDisabled {
      border: 3px solid
        ${({ theme }) =>
          alpha(theme.palette.common.icon.autocompleteSearch, theme.isSsku ? 0.1 : 0.33)};
      color: ${({ theme }) =>
        alpha(theme.palette.common.icon.autocompleteSearch, theme.isSsku ? 1 : 0.33)};
      background: ${({ theme }) => theme.palette.common.transparent};
    }
  }

  &.btnContainedPrimary {
    color: ${({ theme }) => theme.palette.common.button.color};
    border-color: ${({ theme }) => theme.palette.primary.light};

    &:hover {
      opacity: 0.9;
      border-color: ${({ theme }) => theme.palette.primary.light};
      color: ${({ theme }) => theme.palette.common.white};
    }

    &.btnDisabled {
      border: 3px solid ${({ theme }) => alpha(theme.palette.common.header.text, 0.33)};
      color: ${({ theme }) => theme.palette.common.header.text};
      background: ${({ theme }) => alpha(theme.palette.common.text.white, 0.12)};

      &:hover {
        opacity: 1;
        background: ${({ theme }) => alpha(theme.palette.common.black, 0.12)};
      }

      & > * {
        opacity: 0.33;
      }
    }
  }

  &.btnContainedSecondary {
    border: 3px solid ${({ theme }) => theme.palette.common.button.secondary.contained.border};
    background: ${({ theme }) => theme.palette.common.button.secondary.contained.bg};
    color: ${({ theme }) => theme.palette.common.button.secondary.contained.color};

    &:hover {
      background: ${({ theme }) => theme.palette.common.button.secondary.contained.hover.bg};
    }
  }
`;
const TextChild = styled('span')`
  position: relative;
  top: -1px;
`;
