import React from 'react';

import { useLocation, Navigate } from 'react-router-dom';

import { useRXDContext } from '~/context/RXDContext';
import { useUserInfoContext } from '~/context/UserInfoProvider';
import useAuth from '~/hooks/useAuth';
import { LOGIN_ROUTE, PRICING_ROUTE, RXD_ROUTE, RxdRoutes } from '~/routes';
import Cookies from '~/utils/cookies';

const RequireRXDAuth = ({ children }) => {
  const lms_token = Cookies.get('lms_token');
  const { rxdUserInfo } = useRXDContext();
  const location = useLocation();
  const { isInitialized, isLoading } = useAuth();
  const { userInfo, userLoading, hasAccessToContent } = useUserInfoContext();

  if (userLoading || !isInitialized || isLoading) {
    return null;
  } else if (!(!userLoading && hasAccessToContent) && (!lms_token || !rxdUserInfo?.id)) {
    if (window?.frameElement) {
      return <Navigate to={`${RXD_ROUTE}${RxdRoutes.Landing}`} replace />;
    } else if (!userInfo) {
      return <Navigate to={LOGIN_ROUTE} state={{ prevPath: location.pathname }} replace />;
    } else if (!hasAccessToContent) {
      return <Navigate to={PRICING_ROUTE} replace />;
    }
  }

  return children;
};

export default RequireRXDAuth;
