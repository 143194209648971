import React, { FC } from 'react';

import { useTheme } from '@mui/material';

import { Svg, useSvgColor } from '~/assets/icons/styles';
import { TwoColorIconProps } from '~/assets/icons/types';

const CourseIcon: FC<TwoColorIconProps> = ({ color, secondaryColor, ...rest }) => {
  const theme = useTheme();
  const svgColor = useSvgColor(color);
  secondaryColor = secondaryColor || theme.palette.common.blue;
  return (
    <Svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      svgColor={svgColor}
      {...rest}
    >
      <path
        d="M51.5714 10.4545V25.5L46 20.5L40.5 25.5V7.45455H16.4616C13 7.45455 13 4.75234 13 4.75234C13 3.23227 14.2323 2 15.7523 2H44.1538C42.1758 3.4876 42.6703 5.96694 44.1538 7.45455H48.5714C50.2283 7.45455 51.5714 8.79769 51.5714 10.4545Z"
        fill={secondaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4.74211C14.0003 4.74795 14.001 4.75997 14.0026 4.77734C14.0063 4.81725 14.0144 4.88305 14.032 4.96577C14.068 5.13422 14.139 5.35214 14.2748 5.56409C14.507 5.92661 15.0396 6.45455 16.4616 6.45455H42.2735C42.0408 5.92785 41.8936 5.36462 41.8577 4.78948C41.8199 4.18263 41.908 3.57373 42.1393 3H15.7523C14.788 3 14.0055 3.77902 14 4.74211ZM44.5981 6.45455C44.1577 5.90891 43.8913 5.26634 43.8538 4.66507C43.812 3.99364 44.0467 3.33183 44.7549 2.79921C45.0987 2.54064 45.2386 2.09117 45.1023 1.68315C44.966 1.27512 44.584 1 44.1538 1H15.7523C13.68 1 12 2.67998 12 4.75234V59C12 61.2091 13.7909 63 16 63H48.5714C50.7806 63 52.5714 61.2091 52.5714 59V32.5C52.5714 31.9477 52.1237 31.5 51.5714 31.5C51.0191 31.5 50.5714 31.9477 50.5714 32.5V59C50.5714 60.1046 49.676 61 48.5714 61H16C14.8954 61 14 60.1046 14 59V7.92007C14.6334 8.24904 15.4425 8.45455 16.4616 8.45455H39.5V25.5C39.5 25.8958 39.7335 26.2544 40.0954 26.4145C40.4574 26.5746 40.8798 26.5062 41.1727 26.2399L46.0043 21.8475L50.9035 26.2442C51.1972 26.5078 51.6185 26.574 51.9789 26.4132C52.3393 26.2524 52.5714 25.8947 52.5714 25.5V10.4545C52.5714 8.24541 50.7806 6.45455 48.5714 6.45455H44.5981ZM41.5 8.45455V23.2394L45.3273 19.7601C45.7069 19.415 46.2861 19.4131 46.6679 19.7558L50.5714 23.2589V10.4545C50.5714 9.34998 49.676 8.45455 48.5714 8.45455H41.5Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default CourseIcon;
