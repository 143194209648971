import React from 'react';

import { Wrapper, Col, StyledTextField } from '~/blocks/LetsTalkBlock/styles';
import useTenantTranslation from '~/hooks/useTenantTranslation';

const FormFields = ({ values, errors, handleChange }) => {
  const { t } = useTenantTranslation();

  return (
    <Wrapper>
      <Col>
        <StyledTextField
          variant="outlined"
          name="name"
          value={values.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
          label={`${t('input.placeholder.yourName')}*`}
        />
        <StyledTextField
          variant="outlined"
          name="email"
          type="email"
          value={values.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          label={`${t('input.placeholder.workEmail')}*`}
        />
      </Col>
      <Col>
        <StyledTextField
          variant="outlined"
          name="company"
          value={values.company}
          onChange={handleChange}
          error={!!errors.company}
          helperText={errors.company}
          label={`${t('input.placeholder.companyName')}*`}
        />
        <StyledTextField
          variant="outlined"
          name="phone"
          value={values.phone}
          onChange={handleChange}
          error={!!errors.phone}
          helperText={errors.phone}
          label={`${t('input.placeholder.phoneNumber')}`}
        />
      </Col>
    </Wrapper>
  );
};

export default FormFields;
