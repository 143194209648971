import React from 'react';

import { Element, useSvgColor, Svg } from '~/assets/icons/styles';

const CloseIcon = ({ color = '', ...props }) => {
  const svgColor = useSvgColor(color);
  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
      svgColor={svgColor}
    >
      <Element as="line" x1="3" y1="21.00001" x2="21" y2="3.00001" />
      <Element as="line" x1="21" y1="21.00001" x2="3" y2="3.00001" />
    </Svg>
  );
};

export default CloseIcon;
