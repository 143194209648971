import React from 'react';

import { styled } from '@mui/material';

import Trans from '~/components/Trans';
import { MetaText } from '~/components/UI/Texts';

const PrivacyBlock = ({ className = '' }) => {
  return (
    <Text className={className}>
      <Trans i18nKey="block.letsTalk.privacyPolicy">
        By submitting this form, you agree to our
        <a href="https://xunlocked.com/terms">terms</a>
        and
        <a href="https://xunlocked.com/privacy-policy">privacy policy</a>
      </Trans>
    </Text>
  );
};

export default PrivacyBlock;

const Text = styled(MetaText)`
  & a {
    color: ${({ theme }) => theme.palette.common.violetSecondary};
  }
`;
