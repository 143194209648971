import { ElementType } from 'react';

import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material';

export const H1 = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'special',
})<{ special?: boolean }>`
  color: ${({ theme }) => theme.palette.common.white};
  & {
    font-size: 32px;
    line-height: 1.2;
    font-weight: bold;
    font-family: ${({ theme, special }) =>
      special ? theme.typography.specialFontFamily : 'inherit'};
    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: ${({ special }) => (special ? 54 : 40)}px;
    }
  }
`;
interface H2Props extends TypographyProps {
  special?: boolean;
  component?: ElementType;
}
export const H2 = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'special',
})<H2Props>`
  color: ${({ theme }) => theme.palette.common.white};
  & {
    font-family: ${({ theme, special }) =>
      special ? theme.typography.specialFontFamily : 'inherit'};
    font-size: 24px;
    line-height: 1.2;
    font-weight: bold;
    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: ${({ special }) => (special ? 42 : 28)}px;
    }
  }
`;

export const H3 = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'special',
})<{ special?: boolean }>`
  min-height: 24px;
  color: ${({ theme }) => theme.palette.common.white};
  font-family: ${({ theme, special }) =>
    special ? theme.typography.specialFontFamily : 'inherit'};
  & {
    font-size: ${({ special }) => (special ? 28 : 20)}px;
    line-height: 1.2;
    font-weight: bold;
  }
`;

export const H4 = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  & {
    font-size: 18px;
    line-height: 1.2;
    font-weight: bold;
  }
`;

export const BodyIntroText = styled(Typography)`
  font-size: 18px;
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 20px;
  }
`;

export const BodyText = styled(Typography)`
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.common.text.secondaryAccent};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 18px;
    line-height: 1.44;
  }
`;

export const ButtonText = styled(Typography)`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.common.white};
`;

export const MetaText = styled(Typography)`
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.common.text.secondaryToPrimary};
`;
