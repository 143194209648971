import React from 'react';

import { Helmet } from 'react-helmet';

import { HERO_IMG_SRC_SET } from '~/const';
import { generateImageKitUrl, generateSrcset } from '~/modules/imageKit';

interface PreloadImageLinkProps {
  path?: string | null;
  srcset?: any;
  sizes?: string;
}

const PreloadImageLink: React.FC<PreloadImageLinkProps> = ({
  path,
  srcset = HERO_IMG_SRC_SET,
  sizes = '100vw',
}) => {
  return (
    <Helmet>
      <link
        /*
    // @ts-ignore */
        imagesizes={sizes}
        imagesrcset={generateSrcset(path, srcset)}
        rel="preload"
        as="image"
        href={generateImageKitUrl({
          path: path,
          transformation: null,
        })}
      />
    </Helmet>
  );
};

export default PreloadImageLink;
