import React from 'react';

import { useTheme } from '@mui/material';

import { Svg } from '~/assets/icons/styles';

const RemoveFromList = ({ color = '', ...props }) => {
  const theme = useTheme();
  const svgColor = color || theme.palette.common.primary;

  return (
    <Svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      svgColor={svgColor}
    >
      <path d="M12 4H0V6H12V4ZM12 0H0V2H12V0ZM0 10H8V8H0V10Z" fill="currentColor" />
      <path
        d="M10.9998 9.49998L12.4998 11.0378L15.9998 7.49998"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </Svg>
  );
};

export default RemoveFromList;
