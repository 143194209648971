import { Tenant } from '~/types';
import { TenantsRefs } from '~/utils/tenantsConfig';

export const OTHER_TENANTS = 'OTHER_TENANTS';
export const SSKU = 'SSKU';

export const translateByTenant = (tenantName: Tenant['name'], t) => (key, options?) => {
  const tenantKey = tenantName === TenantsRefs.Ssku ? SSKU : OTHER_TENANTS;
  const tenantSpecificTranslation = t(`${key}.${tenantKey}`, options);

  if (tenantSpecificTranslation && !tenantSpecificTranslation.endsWith(tenantKey)) {
    return tenantSpecificTranslation;
  }

  return t(key, options);
};
