import React, { FC } from 'react';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';

import MenuItem from '~/blocks/Header/components/MenuItem';
import { ExpandedNavigationItem } from '~/blocks/Header/const';
import { css, CssUtilityType } from '~/styled';
import { NavigationItem } from '~/types';

interface MenuItemsProps {
  isMobile: boolean;
  items?: (NavigationItem | ExpandedNavigationItem)[];
  scrolled?: boolean;
  wrapperCss?: CssUtilityType;
  isManager?: boolean | null;
  handleClickLink?: () => void;
}

const MenuItems: FC<MenuItemsProps> = ({
  isManager,
  isMobile,
  items,
  scrolled = false,
  wrapperCss,
  handleClickLink,
}) => {
  if (items?.length === 0) {
    return null;
  }

  return (
    <Wrapper optionalStyles={wrapperCss} data-testid="menuItems">
      {items &&
        items.map((item, i) => (
          <MenuItem
            key={i}
            item={item}
            isMobile={isMobile}
            isManager={isManager}
            wrapperCss={menuItemWrapperCss(i === items.length - 1)}
            handleClickLink={handleClickLink}
            scrolled={scrolled}
          />
        ))}
      <HeaderBackgroundBox className="headerBackgroundBox" />
    </Wrapper>
  );
};

export default MenuItems;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})<{ optionalStyles?: CssUtilityType }>`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up('xl')} {
    flex-direction: row;
  }
  ${({ optionalStyles }) => optionalStyles}
`;
const menuItemWrapperCss =
  (isLastItem) =>
  ({ theme }) =>
    css`
      border-bottom: ${isLastItem
        ? 'none'
        : '1px solid ' + alpha(theme.palette.common.white, 0.15)};
      ${theme.breakpoints.up('xl')} {
        border-bottom: none;
        &.hasSubItems:hover ~ .headerBackgroundBox {
          opacity: 1;
          background: ${theme.palette.common.blockBackground.light};
        }
      }
    `;
const HeaderBackgroundBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'scrolled',
})<{ scrolled?: boolean }>`
  width: 100vw;
  position: absolute;
  background: transparent;
  height: ${({ scrolled }) => (scrolled ? '80' : '100')}px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: none;
  ${({ theme }) => theme.breakpoints.up('xl')} {
    display: block;
  }
`;
