import React from 'react';

import { useTheme } from '@mui/material';

import { Svg } from '~/assets/icons/styles';

const AddToList = ({ color, ...props }) => {
  const theme = useTheme();
  const svgColor = color || theme.palette.common.primary;

  return (
    <Svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      svgColor={svgColor}
    >
      <path
        d="M12 4H0V6H12V4ZM12 0H0V2H12V0ZM16 8V4H14V8H10V10H14V14H16V10H20V8H16ZM0 10H8V8H0V10Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default AddToList;
