import React, { forwardRef } from 'react';

import { Container } from '@mui/material';

import HeroLayout, { HeroLayoutProps } from '~/components/Hero/HeroLayout';
import { HeroTitle } from '~/components/Hero/style';
import useIsSsku from '~/hooks/tenant/useIsSsku';
import { CssUtilityType } from '~/styled';

interface HeroProps extends HeroLayoutProps {
  title?: string;
  children?: React.ReactNode;
  titleCss?: CssUtilityType;
}

const Hero = forwardRef<HTMLDivElement, HeroProps>(
  (
    {
      title,
      children,
      imageUrl,
      imageSrcset,
      image,
      wrapperCss,
      imageWrapperCss,
      contentWrapperCss,
      contentWrapperClass = '',
      titleCss,
      fadedOverlay = true,
      fadedFromLeftToRight = true,
      fadedFromBottomToTop = true,
      alt,
      foregroundUrl,
      foregroundAlt,
      foregroundCss,
    },
    ref,
  ) => {
    const isSsku = useIsSsku();

    return (
      <HeroLayout
        ref={ref}
        imageUrl={imageUrl}
        imageSrcset={imageSrcset}
        image={image}
        wrapperCss={wrapperCss}
        imageWrapperCss={imageWrapperCss}
        contentWrapperCss={contentWrapperCss}
        contentWrapperClass={contentWrapperClass}
        fadedOverlay={fadedOverlay}
        fadedFromLeftToRight={fadedFromLeftToRight}
        fadedFromBottomToTop={fadedFromBottomToTop}
        alt={alt || title}
        foregroundUrl={foregroundUrl}
        foregroundAlt={foregroundAlt}
        foregroundCss={foregroundCss}
      >
        {title && (
          <Container>
            <HeroTitle
              special={isSsku}
              data-testid="pageTitle"
              className="headerTitle"
              variant="h1"
              optionalStyles={titleCss}
            >
              {title}
            </HeroTitle>
          </Container>
        )}
        {children}
      </HeroLayout>
    );
  },
);

export default Hero;
