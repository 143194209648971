import { captureException, setTag } from '@sentry/react';

import UserCom from '~/utils/UserCom';
import getApiUrl from '~/utils/getApiUrl';
import { hostToTenantName } from '~/utils/hostToTenantName/hostToTenantName';
import { TENANTS_CONFIG, TenantsRefs } from '~/utils/tenantsConfig';

class DomainAvailabilityError extends Error {
  constructor(message) {
    super(message);
    this.name = 'DomainAvailabilityError1';
  }
}

enum ErrorTypes {
  OwnApi = 'OwnApi',
  XunlApi = 'XunlApi',
  ImgCdn = 'ImgCdn',
  Player = 'Player',
  UserComScript = 'UserComScript',
  UserComRequest = 'UserComRequest',
}

type Field = {
  name: string;
  value: string | number;
};
const reportError = (url, fields: Field[], type: ErrorTypes, e: string) => {
  fields.forEach((field) => {
    setTag(field.name, field.value);
  });
  setTag('requested_url', url);
  captureException(new DomainAvailabilityError(`Type: ${type}; ${e}`));
};

const fetchUrl = async (url, fields: Field[], type: ErrorTypes, isJSON = false, data?: any) => {
  try {
    const res = await fetch(url, data);
    if (isJSON) {
      await res.json();
    }
    if (res.status >= 400) {
      reportError(url, fields, type, `response status: ${res.status}`);
    }
    return res;
  } catch (e) {
    reportError(url, fields, type, JSON.stringify(e));
  }
};

const checkDomainsAvailability = async (fields: Field[]) => {
  if (process.env.REACT_APP_BUILD_TYPE !== 'test' && process.env.NODE_ENV === 'production') {
    const apiUrl = getApiUrl(window?.location?.hostname);

    await fetchUrl(`${apiUrl}/api/healthcheck`, fields, ErrorTypes.OwnApi);
    if (apiUrl !== TENANTS_CONFIG[TenantsRefs.Xunl].apiUrl) {
      await fetchUrl(
        `${TENANTS_CONFIG[TenantsRefs.Xunl].apiUrl}/api/healthcheck`,
        fields,
        ErrorTypes.XunlApi,
      );
    }
    await fetchUrl('https://img-cdn.xunlocked.com/check/index.html', fields, ErrorTypes.ImgCdn);
    await fetchUrl(
      'https://player.xunlocked.com/story/65372-xunlocked/json',
      fields,
      ErrorTypes.Player,
      true,
    );
    await fetchUrl(
      hostToTenantName(window.location?.hostname) === TenantsRefs.Ssku
        ? 'https://openacademyskills.user.com/widget.js'
        : 'https://xunlocked.user.com/widget.js',
      fields,
      ErrorTypes.UserComScript,
      false,
      {
        headers: {
          'Content-Type': 'application/javascript',
        },
        mode: 'no-cors',
      },
    );
    try {
      if (UserCom.checkHasUserCom()) {
        const res = await UserCom.updateClient({});
        if (typeof res !== 'object') {
          reportError(
            'https://xunlocked.user.com/api',
            fields,
            ErrorTypes.UserComRequest,
            `failed: ${res}`,
          );
        }
      }
    } catch (e) {
      reportError(
        'https://xunlocked.user.com/api',
        fields,
        ErrorTypes.UserComRequest,
        JSON.stringify(e),
      );
    }
  }
};

export default checkDomainsAvailability;
