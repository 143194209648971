import React from 'react';

import { Svg, useSvgColor } from '~/assets/icons/styles';

const ReminderIcon = ({ color = '', ...props }) => {
  const svgColor = useSvgColor(color);
  return (
    <Svg
      width="18"
      height="23"
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      svgColor={svgColor}
      {...props}
    >
      <path
        d="M5.35943 2.92422L3.20432 2.9225C2.37543 2.92184 1.70312 3.59361 1.70312 4.4225V19.9823C1.70312 20.8107 2.3747 21.4823 3.20313 21.4823H14.8167C15.6451 21.4823 16.3167 20.8107 16.3167 19.9823L16.3167 4.4225C16.3167 3.59361 15.6444 2.92184 14.8155 2.9225L12.6604 2.92422M6.8499 4.84844H11.1699C11.9931 4.84844 12.6604 4.18113 12.6604 3.35797V2.49047C12.6604 1.66731 11.9931 1 11.1699 1H6.8499C6.02674 1 5.35943 1.66731 5.35943 2.49047V3.35797C5.35943 4.18113 6.02674 4.84844 6.8499 4.84844Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.84375 8.62891H12.8438"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00781 11.9609H13.0078"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 15.2891H9.67358"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default ReminderIcon;
