import { useEffect, useState } from 'react';

import useAuth from '~/hooks/useAuth';
import useGetLoginDomainByConnection from '~/hooks/useGetLoginDomainByConnection';

const useUpdateLoginDomain = (connection?: string) => {
  const [updating, setUpdating] = useState(false);
  const { updatingClient, updateLoginDomain, loginDomain: initialLoginDomain } = useAuth();
  const { domain: loginDomain, loadingDomain } = useGetLoginDomainByConnection(connection);

  useEffect(() => {
    if (loadingDomain || updatingClient) {
      return setUpdating(true);
    }

    if (connection && loginDomain && initialLoginDomain !== loginDomain) {
      updateLoginDomain(loginDomain || '');
    }
    setUpdating(false);
  }, [
    connection,
    loginDomain,
    loadingDomain,
    updateLoginDomain,
    initialLoginDomain,
    updatingClient,
  ]);

  return updating;
};

export default useUpdateLoginDomain;
