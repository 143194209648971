import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import parse from 'html-react-parser';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { H2 } from '~/components/UI/Texts';
import { InfoItem } from '~/components/WeAreBlock/InfoItem';
import { SliderCard } from '~/components/WeAreBlock/SliderCard';
import useEventListener from '~/hooks/useEventListener';
import useIsSSR from '~/hooks/useIsSSR';
import {
  BIG_MARGIN_PX,
  BREAKPOINTS_VALUES,
  LARGE_MARGIN_PX,
  MIDDLE_MARGIN,
  MIDDLE_MARGIN_PX,
  SMALL_MARGIN,
  useIsWidthUp,
} from '~/theme';
import { Background, Maybe, WeAreBlock as WeAreBlockType } from '~/types';
import { parseHighlightedText } from '~/utils/stringUtils';

const swiperSettings: SwiperOptions = {
  slidesPerView: 1.15,
  spaceBetween: SMALL_MARGIN,
  observer: true,
  pagination: {
    clickable: true,
  },
  breakpoints: {
    [BREAKPOINTS_VALUES.lg]: {
      slidesPerView: 1.5,
      spaceBetween: MIDDLE_MARGIN,
    },
  },
};

interface WeAreBlockProps {
  data?: WeAreBlockType;
}

const WeAreBlock: FC<WeAreBlockProps> = ({ data }) => {
  const isSsr = useIsSSR();
  const isMobile = !useIsWidthUp('md');
  const isLg = useIsWidthUp('lg');

  const swiperWrapperRef = useRef<HTMLDivElement>(null);

  const [lastSlideOffset, setLastSlideOffset] = useState(0);

  const title = useMemo(
    () =>
      data?.heading
        ? parse(
            parseHighlightedText(
              data?.heading,
              (match) => `<span>${match.slice(2, -2)}</span><br />`,
            ),
          )
        : '',
    [data],
  );

  const settings = useMemo(() => {
    return {
      ...swiperSettings,
      slidesOffsetAfter: lastSlideOffset,
    };
  }, [lastSlideOffset]);

  const getLastSlideOffset = useCallback(() => {
    const width = swiperWrapperRef?.current?.clientWidth;
    let offset = 0;

    if (width) {
      offset = isLg ? (width / 1.5) * 0.5 : (width / 1.15) * 0.15;
    }

    setLastSlideOffset(offset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiperWrapperRef.current, isLg]);

  useEventListener('resize', getLastSlideOffset);

  useEffect(() => {
    getLastSlideOffset();
  }, [getLastSlideOffset]);

  return (
    <Wrapper
      className={data?.background === 'Dark' ? 'dark-block' : 'light-block'}
      background={data?.background}
    >
      {isMobile && (
        <TextWrapper>
          <Header variant="h2">{title}</Header>
        </TextWrapper>
      )}
      <Container>
        <Col>
          <TextWrapper>
            {!isMobile && <Header variant="h2">{title}</Header>}
            {Boolean(data?.items?.length) && !isSsr && (
              <div>
                {data?.items?.map((item) => (
                  <StyledInfoItem
                    key={item?.icon}
                    iconUrl={item?.icon}
                    title={item?.title}
                    description={item?.description}
                  />
                ))}
              </div>
            )}
          </TextWrapper>
        </Col>
        {Boolean(data?.sliders?.length && data?.sliders?.length > 1) && (
          <RightCol ref={swiperWrapperRef}>
            <Swiper {...settings}>
              {data?.sliders?.map((item) => (
                <SwiperSlide key={item?.image}>
                  <div>
                    <SliderCard imageUrl={item?.image} labelText={item?.label} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </RightCol>
        )}
        {Boolean(data?.sliders?.length && data.sliders.length === 1 && data.sliders[0]) && (
          <RightCol>
            <SingleCardWrapper>
              {/*@ts-ignore*/}
              <SliderCard imageUrl={data.sliders[0]?.image} labelText={data.sliders[0]?.label} />
            </SingleCardWrapper>
          </RightCol>
        )}
      </Container>
    </Wrapper>
  );
};

export default WeAreBlock;

const StyledInfoItem = styled(InfoItem)`
  margin-bottom: ${MIDDLE_MARGIN_PX};

  &:last-child {
    margin-bottom: 0;
  }
`;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background?: Maybe<Background> }>`
  padding-top: ${MIDDLE_MARGIN_PX};
  padding-bottom: ${MIDDLE_MARGIN_PX};
  background: ${({ theme }) => theme.palette.secondary.dark};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: ${LARGE_MARGIN_PX};
    padding-bottom: ${LARGE_MARGIN_PX};
    background: ${({ background, theme }) =>
      background === Background.Dark
        ? theme.palette.secondary.dark
        : theme.palette.secondary.light};
  }
`;
const TextWrapper = styled('div')`
  padding: 0 16px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: auto;
    padding: 0 ${BIG_MARGIN_PX} 0 16px;
    max-width: 600px;
  }
`;
const Header = styled(H2)`
  && {
    margin-bottom: ${MIDDLE_MARGIN_PX};

    & span {
      color: ${({ theme }) => theme.palette.common.violet};
    }
  }
`;
const Container = styled('div')`
  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => theme.breakpoints.up('md')} {
    align-items: center;
    flex-direction: row;
  }
`;
const Col = styled('div')`
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 50%;
    max-width: 50%;
    flex: 1;
  }
`;
const RightCol = styled('div')`
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 50%;
    max-width: 50%;
    flex: 1;
  }

  flex: 1;
  padding: 0 0 0 16px;
  margin-bottom: ${MIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0;
    margin-bottom: -56px;
  }

  & .swiper-pagination {
    ${({ theme }) => theme.breakpoints.up('lg')} {
      padding-right: calc(33.33% + 12px);
    }
  }

  & .swiper {
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: calc(13% - 12px);
      background: linear-gradient(
        -90deg,
        ${({ theme }) => theme.palette.common.blueDark} 0%,
        ${({ theme }) => alpha(theme.palette.common.blueDark, 0)} 100%
      );
      z-index: 2;

      ${({ theme }) => theme.breakpoints.up('lg')} {
        width: calc(21% - 12px);
      }
    }
  }
`;
const SingleCardWrapper = styled('div')`
  width: 100%;
  padding: 0 16px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 600px;
    padding: 0;
  }
`;
