import React from 'react';

import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material';
import { alpha } from '@mui/material/styles';

import RouteWithCode from '~/components/RouteWithCode/RouteWithCode';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { LARGE_MARGIN_PX, SMALL_MARGIN_PX } from '~/theme';

const NotFoundPage = () => {
  const { t } = useTenantTranslation();

  return (
    <RouteWithCode code={404}>
      <Container data-testid="notFoundPage">
        <Wrapper>
          <Typography variant="h1">{t('page.notFound.error')}</Typography>
          <Typography variant="h3">{t('page.notFound.errorTitle')}</Typography>
          <Typography align="center">{t('page.notFound.errorDescription')}</Typography>
        </Wrapper>
      </Container>
    </RouteWithCode>
  );
};

export default NotFoundPage;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 300px);
  color: ${({ theme }) => theme.palette.common.white};
  ${({ theme }) => theme.breakpoints.up('md')} {
    min-height: calc(100vh - 250px);
  }
  & h1 {
    color: ${({ theme }) => alpha(theme.palette.common.white, 0.5)};
    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 50px;
    }
  }
  & h3 {
    margin: ${LARGE_MARGIN_PX} 0 ${SMALL_MARGIN_PX};
  }
`;
