import { createContext } from 'react';

import {
  GetTokenSilentlyOptions,
  BaseLoginOptions,
  LogoutOptions,
  GetTokenWithPopupOptions,
  PopupConfigOptions,
  PopupLoginOptions,
} from '@auth0/auth0-spa-js';

import { User } from '~/context/AuthProvider/AuthState';
import { AuthState, initialAuthState } from '~/context/AuthProvider/AuthState';

export type AuthMode = 'login' | 'signUp' | 'forgotPassword';

export interface ExtendedPopupLoginOptions extends PopupLoginOptions {
  redirectUri?: string;
}

export interface RedirectLoginOptions extends BaseLoginOptions {
  redirectUri?: string;
  isDirectUrl?: boolean;
  appState?: any;
  fragment?: string;
  mode?: AuthMode;
}

export interface AuthContextInterface extends AuthState {
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>;
  getAccessTokenWithPopup: (
    options?: GetTokenWithPopupOptions,
    config?: PopupConfigOptions,
  ) => Promise<string>;

  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void> | void;
  loginWithPopup: (
    options?: ExtendedPopupLoginOptions,
    config?: PopupConfigOptions,
  ) => Promise<void>;
  logout: (options?: LogoutOptions) => void;
  updateUserInfo: (user: User) => void;
  updateLoginDomain: (domain: string) => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

const initialContext = {
  ...initialAuthState,
  domain: '',
  getAccessTokenSilently: stub,
  getAccessTokenWithPopup: stub,
  loginWithRedirect: stub,
  loginWithPopup: stub,
  logout: stub,
  updateUserInfo: stub,
  updateLoginDomain: stub,
};

export const AuthContext = createContext<AuthContextInterface>(initialContext);
