import React, { forwardRef } from 'react';

import { styled } from '@mui/material/styles';

import { MIDDLE_RADIUS, SMALL_RADIUS } from '~/theme';

interface CardWrapperProps {
  children: React.ReactNode;
  className?: string;
  background?: 'light' | 'dark';
  radius?: 'sm' | 'md';
}

const radiusMap = {
  sm: SMALL_RADIUS,
  md: MIDDLE_RADIUS,
};

const CardWrapper = forwardRef<
  HTMLDivElement,
  CardWrapperProps & React.HTMLAttributes<HTMLDivElement>
>(({ children, background = 'light', className = '', radius = 'sm', ...props }, ref) => {
  const clickable = !!props.onClick;

  return (
    <Wrapper
      ref={ref}
      className={className}
      clickable={clickable}
      background={background}
      radius={radiusMap[radius]}
      {...props}
    >
      {children}
    </Wrapper>
  );
});

export default CardWrapper;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'clickable' && prop !== 'background' && prop !== 'radius',
})<{ background: CardWrapperProps['background']; clickable: boolean; radius: number }>`
  position: relative;
  padding: 24px;
  background: ${({ background, theme }) =>
    background === 'dark'
      ? theme.palette.common.card.background.dark
      : theme.palette.common.card.background.light};
  border-radius: ${({ radius }) => radius}px;
  cursor: ${({ clickable }) => clickable && 'pointer'};
  transition: background 0.3s;

  &:hover {
    ${({ clickable, background, theme }) =>
      clickable &&
      'background: ' +
        (background === 'dark'
          ? theme.palette.common.card.background.dark
          : theme.palette.common.card.background.light) +
        ';'}
  }
`;
