import React from 'react';

import { useTheme } from '@mui/material';

import { Svg } from '~/assets/icons/styles';

const ErrorIcon = ({ color, ...props }) => {
  const theme = useTheme();
  const currColor = color || theme.palette.common.coral;

  return (
    <Svg
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      svgColor={currColor}
    >
      <path
        d="M24 2C36.1506 2 46 11.8516 46 24C46 36.1484 36.1506 46 24 46C11.8494 46 2 36.1484 2 24C2 11.8516 11.8494 2 24 2ZM24 5.6674C13.891 5.6674 5.6674 13.891 5.6674 24C5.6674 34.109 13.891 42.3326 24 42.3326C34.109 42.3326 42.3326 34.109 42.3326 24C42.3326 13.891 34.109 5.6674 24 5.6674V5.6674ZM23.9978 29.5044C24.5807 29.5044 25.1397 29.736 25.5519 30.1481C25.964 30.5603 26.1956 31.1193 26.1956 31.7022C26.1956 32.2851 25.964 32.8441 25.5519 33.2563C25.1397 33.6684 24.5807 33.9 23.9978 33.9C23.4149 33.9 22.8559 33.6684 22.4437 33.2563C22.0316 32.8441 21.8 32.2851 21.8 31.7022C21.8 31.1193 22.0316 30.5603 22.4437 30.1481C22.8559 29.736 23.4149 29.5044 23.9978 29.5044V29.5044ZM23.9868 13C24.3859 12.9995 24.7717 13.1436 25.0726 13.4058C25.3736 13.6679 25.5693 14.0302 25.6236 14.4256L25.639 14.6478L25.6478 24.5522C25.6482 24.9704 25.4898 25.3732 25.2046 25.6791C24.9194 25.985 24.5286 26.1712 24.1114 26.2C23.6941 26.2288 23.2815 26.098 22.957 25.8342C22.6324 25.5704 22.4202 25.1931 22.3632 24.7788L22.3478 24.5544L22.339 14.6522C22.3387 14.4353 22.3812 14.2205 22.464 14.0201C22.5468 13.8197 22.6683 13.6375 22.8215 13.4841C22.9747 13.3306 23.1567 13.2089 23.3571 13.1258C23.5574 13.0428 23.7721 13 23.989 13H23.9868Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default ErrorIcon;
