import React from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import AnimatedSkeleton from '~/components/AnimatedSkeleton';
import AnimatedSkeletonMultiline from '~/components/AnimatedSkeletonMultiline';
import LazyLoadImage from '~/components/LazyloadImage/LazyLoadImage';
import { FALLBACK_LINK } from '~/routes';
import { css } from '~/styled';
import {
  BREAKPOINTS_VALUES,
  SKELETON_TEXT_HEIGHT_PX,
  SKELETON_TEXT_HEIGHT_SMALL_PX,
  TINY_MARGIN_PX,
} from '~/theme';
import { Background, Maybe } from '~/types';

interface INewsCard {
  link?: string;
  avatarUrl?: string | null;
  imageUrl?: string | null;
  avatarAlt?: string;
  background?: Background;
  title?: string;
  description?: string | null;
  infoSubtitle?: string;
  loading?: boolean;
}

const NewsCard = ({
  link,
  avatarUrl,
  imageUrl,
  avatarAlt,
  background,
  title,
  description,
  infoSubtitle,
  loading = false,
}: INewsCard) => {
  if (loading) {
    return (
      <WrapperLink to={FALLBACK_LINK}>
        <LoadingImageWrapper />
        <TextWrapper>
          <LoadingTitle xs={SKELETON_TEXT_HEIGHT_PX} />
          <AnimatedSkeletonMultiline
            xs={SKELETON_TEXT_HEIGHT_SMALL_PX}
            lines={2}
            spacer={TINY_MARGIN_PX}
            lastLineWidth={80}
          />
          <LoadingInfo xs={SKELETON_TEXT_HEIGHT_SMALL_PX} />
        </TextWrapper>
      </WrapperLink>
    );
  }

  return (
    <WrapperLink to={link || FALLBACK_LINK} background={background}>
      {avatarUrl ? (
        <RoundImageWrapper>
          <LazyLoadImage
            path={avatarUrl}
            draggable={false}
            alt={avatarAlt}
            srcset={[
              { width: 50, height: 50, focus: 'auto' },
              { width: 60, height: 60, focus: 'auto' },
              { width: 70, height: 70, focus: 'auto' },
            ]}
            sizes={`(max-width:${BREAKPOINTS_VALUES['sm']}px) 50px, (max-width:${BREAKPOINTS_VALUES['lg']}px) 60px,70px`}
            wrapperCss={lazyAvatarWrapper}
          />
        </RoundImageWrapper>
      ) : (
        <StyledCircleIcon />
      )}
      <ImageWrapper>
        {imageUrl ? (
          <LazyImage
            className={'preview'}
            path={imageUrl}
            draggable={false}
            alt={title}
            srcset={[{ width: 440 }, { width: 360 }, { width: 350 }, { width: 340 }]}
            sizes={`(max-width:${BREAKPOINTS_VALUES['sm']}px) 440px, (max-width:${BREAKPOINTS_VALUES['md']}px) 360px, (max-width:${BREAKPOINTS_VALUES['lg']}px) 350px, 340px`}
          />
        ) : (
          <ImagePlaceholder />
        )}
      </ImageWrapper>
      <TextWrapper>
        <div>
          <GeneratedTitle variant="h3" component="h3" align="left">
            {title}
          </GeneratedTitle>
          <Description align="left">{description}</Description>
        </div>
        {infoSubtitle && <Info align="left">{infoSubtitle}</Info>}
      </TextWrapper>
    </WrapperLink>
  );
};

export default NewsCard;

const WrapperLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background?: Maybe<Background> }>`
  display: flex;
  flex-direction: column;
  background: ${({ background, theme }) =>
    background === 'Dark' ? theme.palette.secondary.light : theme.palette.secondary.dark};
  box-shadow: 0 3px 5px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)};
  border-radius: 5px;
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;

  &:hover {
    & img.preview {
      width: 110%;
    }
  }
`;
const createRoundImage = (tag) => styled(tag)`
  position: absolute;
  margin-top: 100px;
  margin-left: 18px;
  width: 52px;
  height: 52px;
  border: 3px solid ${({ theme }) => theme.palette.secondary.light};
  background: ${({ theme }) => theme.palette.secondary.light};
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin: auto;
  overflow: hidden;
  z-index: 3;

  & img {
    height: 100%;
    width: 100%;
    border-radius: 40%;
    object-fit: cover;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 120px;
    margin-left: 22px;
    width: 63px;
    height: 63px;
  }
`;
const RoundImageWrapper = createRoundImage('div');
const StyledCircleIcon = createRoundImage(AccountCircleIcon);
const createImageWrapper = (tag) => styled(tag)`
  width: 100%;
  height: 126px;
  overflow: hidden;
  position: relative;
  border-radius: 5px 5px 0 0;

  & img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: width 0.3s ease;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 153px;
  }
`;
const ImageWrapper = createImageWrapper('div');
const LoadingImageWrapper = styled(createImageWrapper(AnimatedSkeleton))`
  margin-bottom: ${TINY_MARGIN_PX};
`;
const LazyImage = styled(LazyLoadImage)`
  width: 100%;
  height: 126px;
  overflow: hidden;
  object-fit: cover;
  background: ${({ theme }) => alpha(theme.palette.common.blueDark, 0.4)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 153px;
  }
`;
const ImagePlaceholder = styled('div')`
  width: 100%;
  height: 126px;
  overflow: hidden;
  object-fit: cover;
  background: ${({ theme }) => alpha(theme.palette.common.blueDark, 0.4)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 153px;
  }
`;
const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  height: 178px;
  padding: 18px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 0;
    height: 216px;
    padding: 22px;
  }
`;
const GeneratedTitle = styled(Typography)`
  & {
    margin-top: 10px;
    max-width: 1000px;
    overflow: hidden;
    white-space: inherit;
    text-overflow: ellipsis;
    font-size: 20px;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      margin-top: 18px;
      font-size: 20px;
    }
  }
` as typeof Typography;
const Description = styled(Typography)`
  margin-top: 12px;
  font-size: 16px;
  max-width: 1000px;
  color: ${({ theme }) => theme.palette.common.gray};
  overflow: hidden;
  white-space: inherit;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 16px;
    font-size: 16px;
    line-height: 1.5;
  }
`;
const Info = styled(Typography)`
  margin-top: 9px;
  max-width: 1000px;
  color: ${({ theme }) => theme.palette.common.violet};
  overflow: hidden;
  white-space: inherit;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.typography.defaultFontFamily};
  font-size: 12px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 14px;
    line-height: 1.5;
    margin-top: 0;
  }
`;
const lazyAvatarWrapper = css`
  width: 100%;
  height: 100%;
`;
const LoadingTitle = styled(AnimatedSkeleton)`
  max-width: 70%;
`;
const LoadingInfo = styled(AnimatedSkeleton)`
  max-width: 50%;
`;
