import { styled } from '@mui/material';

import { SMALL_MARGIN_PX } from '~/theme';

export const SwitchWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>`
  margin-left: ${SMALL_MARGIN_PX};
  display: flex;
  align-items: center;
  opacity: ${({ disabled }) => disabled && 0.5};
  pointer-events: ${({ disabled }) => disabled && 'none'};

  & > span:first-child {
    margin-right: ${SMALL_MARGIN_PX};
  }

  & > span:last-child {
    margin-left: ${SMALL_MARGIN_PX};
  }
`;

export const Label = styled('span', {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'disabled',
})<{ active?: boolean; disabled?: boolean }>`
  color: ${({ active, disabled, theme }) =>
    disabled
      ? theme.palette.common.gray
      : active
      ? theme.palette.common.primary
      : theme.palette.common.gray};
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  text-transform: uppercase;
  cursor: pointer;
}
`;
