import { useMemo } from 'react';

import { useTheme } from '@mui/material';

import { useUserInfoContext } from '~/context/UserInfoProvider';

const useAvatarColor = () => {
  const theme = useTheme();
  const { userInfo } = useUserInfoContext();

  const avatarColors = useMemo(
    () => [
      {
        ids: ['0', '1'],
        background: theme.palette.common.header.userAvatar.coral.background,
        color: theme.palette.common.header.userAvatar.coral.color,
      },
      {
        ids: ['2', '3'],
        background: theme.palette.common.header.userAvatar.blue.background,
        color: theme.palette.common.header.userAvatar.blue.color,
      },
      {
        ids: ['4', '5'],
        background: theme.palette.common.header.userAvatar.blueLight.background,
        color: theme.palette.common.header.userAvatar.blueLight.color,
      },
      {
        ids: ['6', '7'],
        background: theme.palette.common.header.userAvatar.yellow.background,
        color: theme.palette.common.header.userAvatar.yellow.color,
      },
      {
        ids: ['8', '9'],
        background: theme.palette.common.header.userAvatar.pink.background,
        color: theme.palette.common.header.userAvatar.pink.color,
      },
    ],
    [theme],
  );

  return avatarColors.find((item) => userInfo?.id && item.ids.includes(userInfo?.id?.slice(-1)));
};

export default useAvatarColor;
