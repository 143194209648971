import { useState } from 'react';

import { useQuery } from '@apollo/client';

import { useUserInfoContext } from '~/context/UserInfoProvider';
import { GET_ANNOUNCEMENTS_LIST } from '~/graphql/dashboard';
import { Query } from '~/types';
import { checkIsSSR } from '~/utils/checkIsSSR';

const FIRST_PAGE = 1;
const ITEMS_PER_PAGE = 10;

const usePaginatedNotifications = () => {
  const isSSR = checkIsSSR();
  const { userInfo } = useUserInfoContext();

  const [page, setPage] = useState(FIRST_PAGE);

  const query = useQuery<Pick<Query, 'getAnnouncementsList'>>(GET_ANNOUNCEMENTS_LIST, {
    variables: {
      first: ITEMS_PER_PAGE,
      page: page,
    },
    skip: isSSR || !userInfo,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const notificationsList = query.data?.getAnnouncementsList?.data || [];
  const ableFetchMore =
    notificationsList?.length < (query.data?.getAnnouncementsList?.paginatorInfo?.total || 0);
  const fetchMore = () => setPage((old) => ++old);

  return {
    ...query,
    ableFetchMore,
    fetchMore,
  };
};

export default usePaginatedNotifications;
