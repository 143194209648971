import React from 'react';

import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material';

import IKImage from '~/components/IKImage/IKImage';
import LazyLoadImage from '~/components/LazyloadImage/LazyLoadImage';
import Button from '~/components/UI/Button/Button';
import useIsIE from '~/hooks/useIsIE';
import {
  BREAKPOINTS_VALUES,
  LARGE_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  SMALL_MARGIN_PX,
  SUBMIDDLE_MARGIN_PX,
} from '~/theme';
import { Background, Maybe } from '~/types';

const WhyUsWideImgBlock = ({ data }) => {
  const background = data?.background;
  const desktopImg = data?.desktop_image;
  const heading = typeof data?.heading === 'string' ? data?.heading : data?.heading?.en;
  const buttonUrl = data?.button_url;
  const buttonText = data?.button_text;
  const items = data?.items;

  const isIE = useIsIE();

  if (!items || items.length === 0) return null;

  return (
    <Wrapper
      className={background === 'Dark' ? 'dark-block' : 'light-block'}
      background={background}
    >
      <StyledContainer>
        <Content background={background}>
          <Heading variant="h2" component="h2">
            {heading}
          </Heading>

          <Items>
            {items &&
              items.map((item, index) => {
                const heading =
                  typeof item?.heading === 'string' ? item?.heading : item?.heading?.en;
                const description =
                  typeof item?.description === 'string' ? item?.description : item?.description?.en;

                return (
                  <Item key={index} className={'image-array-block__item'}>
                    <ImageWrap>
                      <IKImage
                        path={item.image}
                        draggable={false}
                        alt={heading}
                        srcset={[{ width: 65 }, { width: 50 }]}
                        sizes={`(max-width:${BREAKPOINTS_VALUES['md']}px) 65px, 50px`}
                      />
                    </ImageWrap>
                    <TextWrapper>
                      <ItemTitle variant="h3" component="h3">
                        {heading}
                      </ItemTitle>
                      <ItemText>{description}</ItemText>
                    </TextWrapper>
                  </Item>
                );
              })}
          </Items>
          <StyledButton text={buttonText} link={buttonUrl} />
        </Content>

        <WideImg className={'wide-img-bg'}>
          <LazyLoadImage
            path={desktopImg}
            draggable={false}
            alt={heading}
            srcset={[{ width: 900 }]}
            sizes={`(min-width:${BREAKPOINTS_VALUES['md']}px) 900px`}
            transformation={
              isIE
                ? [
                    {
                      width: 900,
                    },
                  ]
                : null
            }
          />
        </WideImg>
      </StyledContainer>
    </Wrapper>
  );
};

export default WhyUsWideImgBlock;

const StyledButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background?: Maybe<Background> }>`
  display: flex;
  color: ${({ theme }) => theme.palette.common.white};
  background: ${({ theme }) => theme.palette.secondary.dark};
  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: ${LARGE_MARGIN_PX};
    padding-bottom: ${LARGE_MARGIN_PX};
    background: ${({ background, theme }) =>
      background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light};
  }
`;
const StyledContainer = styled(Container)`
  position: relative;
  display: flex;
`;
const Content = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background?: Maybe<Background> }>`
  border-radius: 5px;
  padding: ${MIDDLE_MARGIN_PX} ${SUBMIDDLE_MARGIN_PX};
  background: ${({ theme }) => theme.palette.secondary.dark};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 480px;
    padding: 0;
    background: ${({ background, theme }) =>
      background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: auto;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    align-items: flex-start;
    min-width: 480px;
  }
`;
const Heading = styled(Typography)`
  && {
    margin-bottom: ${SMALL_MARGIN_PX};
    width: 100%;

    ${({ theme }) => theme.breakpoints.down('md')} {
      text-align: center;
    }
  }
` as typeof Typography;
const Items = styled(Box)`
  margin-top: 22px;
  margin-bottom: 28px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: ${MIDDLE_MARGIN_PX};
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }
`;
const Item = styled('div')`
  display: flex;
  margin-bottom: 32px;
`;
const ImageWrap = styled('div')`
  width: 48px;
  flex-shrink: 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 65px;
  }

  & img {
    width: 100%;
  }
`;
const TextWrapper = styled('div')`
  padding-left: ${SMALL_MARGIN_PX};
`;
const ItemTitle = styled(Typography)`
  && {
    font-size: 24px;
    line-height: 1;
    margin-bottom: 10px;
  }
` as typeof Typography;
const ItemText = styled(Typography)`
  font-size: 16px;
  line-height: 1.6;
  color: ${({ theme }) => theme.palette.common.gray};
`;
const WideImg = styled(Box)`
  display: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
    position: relative;
    margin-left: ${LARGE_MARGIN_PX};
    left: 0;
    top: 0;
  }

  & img {
    width: initial;
  }
`;
