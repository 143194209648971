import { DEFAULT_DISCOVER_URL } from '~/const';
import { Maybe, Video } from '~/types';

export const FALLBACK_LINK = '#';

export const MY_ACCOUNT = '/my-account';
export const EDIT_ACCOUNT = '/my-account/edit-account';
export const MEMBERSHIP_AREA = '/my-account/membership-area/';
export const JOIN_TEAM_LINK = '/my-account/join-team/';
export const TEAMS_LINK = '/my-account/teams';
export const JOIN_TEAM_ROUTE = `${JOIN_TEAM_LINK}:hash`;

export const MAIN_FONT_LINK = 'https://use.typekit.net/ein6xai.css';

export const INSIGHTS_SERP_ROUTE = `/insights`;

export const AuthRoutes = {
  Logout: '/logout',
  LoginSso: '/login/:ssoKey',
  Registration: '/registration',
  ForgotPassword: '/forgot-password',
};

export const DiscoverRoutes = {
  Search: `/search`,
  Videos: `/videos`,
  Pathways: `/pathways`,
  Skills: `/skills`,
  Glossary: `/glossary`,
  Courses: `/courses`,
  Insights: INSIGHTS_SERP_ROUTE,
  Experts: `/experts`,
};

export const SERP_ROUTES = [
  DiscoverRoutes.Search,
  DiscoverRoutes.Videos,
  DiscoverRoutes.Pathways,
  DiscoverRoutes.Glossary,
  DiscoverRoutes.Courses,
];

export const DISCOVER_SEARCH_ROUTE = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Search}`;
export const DISCOVER_VIDEOS_ROUTE = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Videos}`;
export const DISCOVER_PATHWAYS_ROUTE = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Pathways}`;
export const DISCOVER_SKILLS_ROUTE = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Skills}`;
export const DISCOVER_GLOSSARY_ROUTE = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Glossary}`;
export const DISCOVER_COURSES_ROUTE = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Courses}`;
export const DISCOVER_INSIGHTS_ROUTE = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Insights}`;
export const DISCOVER_EXPERTS_ROUTE = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Experts}`;

export const PRICING_ROUTE = '/pricing';
export const PricingRoutes = {
  Individual: '/individual',
  Business: '/business',
};
export const MY_MEMBERSHIP_ROUTE = '/my-account/membership-area';
export const HOME_ROUTE = '/home';
export const ANALYTICS_ROUTE = '/analytics';
export const ANALYTICS_DASHBOARD_ROUTE = '/analytics/dashboard';
export const PAYMENT_SUCCESSFUL_ROUTE = '/payment-successful';
export const NEWS_ROUTE = '/news';
export const ROOT_ROUTE = '/';
export const LEARNER_AL_ROUTE = '/assigned-learning';
export const MANAGER_AL_ROUTES = {
  List: '/assigned-learning/dashboard',
  FormTypeSelect: '/assigned-learning/dashboard/new',
};
export const SHARE_PATHWAY_ROUTE = '/share';
export const SHARE_COURSE_ROUTE = '/share/courses';
export const SHARE_ASSIGNMENTS_ROUTE = '/share/assignments';
export const SUBSCRIPTION_ROUTE = '/subscription';
export const RXD_ROUTE = '/rxd';
export const NOT_FOUND_ROUTE = '/404';
export const CONTACT_ROUTE = '/contact';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';

export const RxdRoutes = {
  Videos: '/videos',
  Landing: '/landing',
  Test: '/test',
};

export const AUTH_ERROR_ROUTE = '/auth-error';

export const MAINTENANCE = '/maintenance';
export const hardcodedCourseUrls = [
  '/courses/Master-the-project-and-people-challenge',
  '/courses/An-introduction-to-coding',
  '/courses/Green-strategies-for-financial-savings',
];
export const oldExpertPageRoutes = ['/expert/:expertSlug', '/experts/:expertSlug'];
export const homepageRoutes = ['/home', '/recently-watched-my-watchlist/'];
export const LOGIN_ROUTE = '/login';
export const loginRoutes = [LOGIN_ROUTE, '/sso'];

export const getVideoLink = (video?: Maybe<Video>) => {
  if (!video) {
    return ROOT_ROUTE;
  }

  return video?.video_url
    ? `${DiscoverRoutes.Videos}/${video?.video_url}/`
    : `${DiscoverRoutes.Videos}/${video?.id}/`;
};

export const CASE_STUDY_PATH = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Pathways}/:pathwaySlug/case-study/:studyId`;
export const SINGLE_NEWS_PATH = `${NEWS_ROUTE}/:id`;
export const SINGLE_INSIGHT_PATH = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Insights}/:slug`;
export const COURSE_PROGRESS_PATH = `${DiscoverRoutes.Courses}/:slug/progress`;
export const SINGLE_COURSE_PATH = `${DiscoverRoutes.Courses}/:slug`;
export const PATHWAY_CONTINUE_WATCHING_PATH = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Pathways}/:slug/continue-watching`;
export const SINGLE_PATHWAY_CONTINUE_WATCHING_PATH = '/pathway/:slug/continue-watching';
export const PATHWAY_TEST_PATH = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Pathways}/:slug/test/:testId`;
export const DISCOVER_PATHWAY_PATH = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Pathways}/:slug`;
export const SINGLE_PATHWAY_TEST_PATH = '/pathway/:slug/test/:testId';
export const SINGLE_PATHWAY_PATH = '/pathway/:slug';
export const SHARE_PATHWAY_PATH = `${SHARE_PATHWAY_ROUTE}/:hash`;
export const SHARE_ASSIGNMENTS_PATH = `${SHARE_ASSIGNMENTS_ROUTE}/:hash`;
export const SHARE_COURSE_PATH = `${SHARE_COURSE_ROUTE}/:hash`;
export const SINGLE_SUBSCRIPTION_PATH = `${SUBSCRIPTION_ROUTE}/:id`;
export const RXD_VIDEO_PATH = `${RxdRoutes.Videos}/:slug`;
export const DISCOVER_GLOSSARY_TERM_PATH = `${DiscoverRoutes.Glossary}/:term`;
export const DISCOVER_EXPERT_PATH = `${DiscoverRoutes.Experts}/:expertSlug`;
export const DISCOVER_SKILL_PATH = `${DiscoverRoutes.Skills}/:skill`;

export const MY_ACCOUNT_PATHS = `${MY_ACCOUNT}/*`;
export const DISCOVER_PATHS = `${DEFAULT_DISCOVER_URL}/*`;
export const ASSIGNED_LEARNING_PATHS = `${LEARNER_AL_ROUTE}/*`;
export const ANALYTICS_PATHS = `${ANALYTICS_ROUTE}/*`;
export const RXD_PATHS = `${RXD_ROUTE}/*`;

export const getAssignmentByTeamsCompanyCsvLink = (
  apiUrl?: Maybe<string>,
  assignmentId?: Maybe<string>,
) =>
  apiUrl && assignmentId
    ? `${apiUrl}/csv/assignments/${assignmentId}/by-teams-company-assignment.csv`
    : '';

export const getExpertLink = (video?: Maybe<Video>) =>
  video ? `${DISCOVER_EXPERTS_ROUTE}/${video.user?.slug}` : ROOT_ROUTE;

export const buildDiscoverRoute = (slug?: Maybe<string>) =>
  slug ? `${DEFAULT_DISCOVER_URL}/${slug}` : ROOT_ROUTE;
export const buildSkillRoute = (slug?: Maybe<string>) =>
  slug ? `${DISCOVER_SKILLS_ROUTE}/${slug}` : ROOT_ROUTE;
export const buildCategoryRoute = (parentSlug?: Maybe<string>, slug?: Maybe<string>) =>
  slug && parentSlug ? `${DEFAULT_DISCOVER_URL}/${parentSlug}/${slug}` : ROOT_ROUTE;
export const buildPathwayRoute = (slug?: Maybe<string>) =>
  slug ? `${DISCOVER_PATHWAYS_ROUTE}/${slug}` : ROOT_ROUTE;
export const buildPathwayTestRoute = (slug?: Maybe<string>, testId?: Maybe<string>) =>
  slug && testId ? `${DISCOVER_PATHWAYS_ROUTE}/${slug}/test/${testId}` : ROOT_ROUTE;
export const buildPathwayContinueWatchingRoute = (slug?: Maybe<string>) =>
  slug ? `${DISCOVER_PATHWAYS_ROUTE}/${slug}/continue-watching` : ROOT_ROUTE;
export const buildCaseStudyRoute = (pathwaySlug?: Maybe<string>, studyId?: Maybe<string>) =>
  pathwaySlug && studyId
    ? `${DISCOVER_PATHWAYS_ROUTE}/${pathwaySlug}/case-study/${studyId}`
    : ROOT_ROUTE;
export const buildExpertRoute = (slug?: Maybe<string>) =>
  slug ? `${DISCOVER_EXPERTS_ROUTE}/${slug}` : ROOT_ROUTE;
export const buildCourseRoute = (slug?: Maybe<string>) =>
  slug ? `${DiscoverRoutes.Courses}/${slug}` : ROOT_ROUTE;
export const buildGlossaryRoute = (slug?: Maybe<string>) =>
  slug ? `${DISCOVER_GLOSSARY_ROUTE}/${slug}` : ROOT_ROUTE;
export const buildInsightsRoute = (slug?: Maybe<string>) =>
  slug ? `${DISCOVER_INSIGHTS_ROUTE}/${slug}` : ROOT_ROUTE;
export const buildCourseProgressRoute = (slug?: Maybe<string>) =>
  slug ? `${buildCourseRoute(slug)}/progress` : ROOT_ROUTE;
export const buildCoursesShareRoute = (courseHash?: Maybe<string>) =>
  courseHash ? `${SHARE_COURSE_ROUTE}/${courseHash}` : ROOT_ROUTE;
export const buildSubscriptionRoute = (chargebeePlanId?: Maybe<string>) =>
  chargebeePlanId ? `${SUBSCRIPTION_ROUTE}/${chargebeePlanId}` : ROOT_ROUTE;
export const buildRXDVideoRoute = (slug?: Maybe<string>) =>
  slug ? `${RXD_ROUTE}${RxdRoutes.Videos}/${slug}` : ROOT_ROUTE;
export const buildLearnerAslTestRoute = (slug?: Maybe<string>) =>
  slug ? `${LEARNER_AL_ROUTE}/${slug}/test` : ROOT_ROUTE;
