import React from 'react';

import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Svg } from '~/assets/icons/styles';
import { TwoColorIconProps } from '~/assets/icons/types';

const BellIcon: React.FC<TwoColorIconProps> = ({
  color,
  secondaryColor,
  withCounter = false,
  filled,
  ...rest
}) => {
  const theme = useTheme();
  const iconColor = color || theme.palette.common.blue;
  const iconSecondaryColor = secondaryColor || theme.palette.common.blackSecondary;

  return (
    <Svg
      width="91"
      height="90"
      viewBox="0 0 91 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      svgColor={iconColor}
    >
      <path
        d="M53 75H38V75.0075C37.9999 75.9915 38.1935 76.9658 38.57 77.8749C38.9465 78.784 39.4984 79.6101 40.1942 80.3058C40.8899 81.0016 41.716 81.5535 42.6251 81.93C43.5342 82.3065 44.5085 82.5001 45.4925 82.5H45.5075C46.4915 82.5001 47.4658 82.3065 48.3749 81.93C49.284 81.5535 50.1101 81.0016 50.8058 80.3058C51.5016 79.6101 52.0535 78.784 52.43 77.8749C52.8065 76.9658 53.0001 75.9915 53 75.0075V75Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        fill={filled ? iconColor : undefined}
        d="M73.4938 33.6825C73.0197 26.5824 69.8649 19.9278 64.6684 15.0664C59.4718 10.2051 52.622 7.50034 45.506 7.5H45.491C38.3751 7.50032 31.5252 10.205 26.3287 15.0664C21.1321 19.9278 17.9774 26.5824 17.5033 33.6825C17.12 39.42 16.7308 45.27 16.3978 50.265C15.8076 59.1021 12.9122 67.6303 8 75H82.997C78.0848 67.6303 75.1894 59.1021 74.5993 50.265C74.2663 45.27 73.877 39.42 73.4938 33.6825Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {withCounter && (
        <>
          <rect x="60" y="5.5" width="19" height="19" rx="9.5" fill={iconSecondaryColor} />
          <path
            d="M66.312 14.908C66.312 17.526 67.068 19.64 69.742 19.64C72.416 19.64 73.144 17.526 73.144 14.908C73.144 12.262 72.416 10.148 69.742 10.148C67.068 10.148 66.312 12.262 66.312 14.908ZM68.272 14.908C68.272 13.354 68.622 12.052 69.742 12.052C70.848 12.052 71.184 13.354 71.184 14.908C71.184 16.448 70.848 17.736 69.742 17.736C68.622 17.736 68.272 16.448 68.272 14.908Z"
            fill="currentColor"
          />
          <rect
            x="60"
            y="5.5"
            width="19"
            height="19"
            rx="9.5"
            stroke="currentColor"
            strokeWidth="3"
          />
        </>
      )}
    </Svg>
  );
};

export default BellIcon;

const StyledPath = styled('path', {
  shouldForwardProp: (prop) => prop !== 'fill',
})<{ fill?: string }>`
  fill: ${({ fill }) => fill};
`;
