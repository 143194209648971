import { useMutation } from '@apollo/client';

import { ADD_VIDEO_TO_WATCHLIST, REMOVE_VIDEO_FROM_WATCHLIST } from '~/graphql/videos';
import {
  Mutation,
  MutationAddVideoInWatchListArgs,
  MutationDeleteVideoFromWatchListArgs,
} from '~/types';

const useToggleFromWatchlist = (existInWatchlist, videoId) => {
  const [addToWatchList, { loading: addToWatchListLoading }] = useMutation<
    Pick<Mutation, 'AddVideoInWatchList'>,
    MutationAddVideoInWatchListArgs
  >(ADD_VIDEO_TO_WATCHLIST, {
    variables: {
      video_id: videoId,
    },
    refetchQueries: ['GetVideosExistInUserWatchList', 'GetWatchlist'],
    awaitRefetchQueries: true,
  });

  const [removeFromWatchList, { loading: removeFromWatchListLoading }] = useMutation<
    Pick<Mutation, 'DeleteVideoFromWatchList'>,
    MutationDeleteVideoFromWatchListArgs
  >(REMOVE_VIDEO_FROM_WATCHLIST, {
    variables: {
      video_id: videoId,
    },
    refetchQueries: ['GetVideosExistInUserWatchList', 'GetWatchlist'],
    awaitRefetchQueries: true,
  });

  return {
    toggleWatchlist: !existInWatchlist ? addToWatchList : removeFromWatchList,
    loading: addToWatchListLoading || removeFromWatchListLoading,
  };
};

export default useToggleFromWatchlist;
