import React, { useMemo } from 'react';

import { ApolloProvider, gql } from '@apollo/client';

import { Severity, useAlert } from '~/context/AlertProvider';
import { useLanguageContext } from '~/context/LanguageProvider';
import createApolloClient from '~/graphql';
import useAuth from '~/hooks/useAuth';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { errorMessages } from '~/utils/errors/const';
import handleGraphQlError from '~/utils/handleGraphQlError';
import { checkIfHasInsights } from '~/utils/tenantsConfig';

function ApolloLoader({ type = 'client', client, host, children }) {
  const { isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth();
  const { showAlert } = useAlert();
  const { t } = useTenantTranslation();
  const { language } = useLanguageContext();

  const apolloClient = useMemo(() => {
    const handleError = (error, operation) => {
      handleGraphQlError(error, operation);

      let errorMessage = <>{errorMessages[operation.operationName] || t('error.errorHappened')}</>;

      if (process.env.NODE_ENV === 'development') {
        errorMessage = (
          <>
            {operation.operationName}: {errorMessage}
          </>
        );
      }

      showAlert({ message: errorMessage, severity: Severity.Error });
    };

    if (type === 'server') {
      return client;
    }

    return createApolloClient({
      handleError,
      getToken: isAuthenticated
        ? async () => {
            try {
              return await getAccessTokenSilently();
            } catch (e) {
              try {
                return await getAccessTokenWithPopup();
              } catch (e) {}
            }
          }
        : null,
      lang: language,
    });
  }, [
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    client,
    type,
    isAuthenticated,
    showAlert,
    t,
    language,
  ]);

  apolloClient.writeQuery({
    query: gql`
      query GetHasNewsSetup {
        hasNews
      }
    `,
    data: {
      hasNews: checkIfHasInsights(host),
    },
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

export default ApolloLoader;
