import React, { FC, useMemo } from 'react';

import { styled } from '@mui/material';

import VideosBlock, { VideoBlockItem, VideoItem } from '~/components/VideosBlock/VideosBlock';
import { ContentItemListBlock, ContentItemType, Orientation } from '~/types';
import assertNever from '~/utils/errors/assertNever';
import { toBoolean } from '~/utils/toBoolean';

interface ContentListBlockProps {
  data?: ContentItemListBlock | null;
  withArrows?: boolean;
  classes?: {
    wrapper?: string;
    container?: string;
    heading?: string;
    videoWrapper?: string;
  };
  titleToLeft?: boolean;
  loading?: boolean;
  wrapperId?: string;
  withChunksOnMobile?: boolean;
}

const ContentListBlock: FC<ContentListBlockProps> = ({ data, ...rest }) => {
  const formattedData = useMemo(() => {
    return {
      ...data,
      orientation: Orientation.Lanscape,
      items: data?.content_items
        ?.map((item) => {
          if (!item?.type || !item?.entity) {
            return null;
          }

          if (item.type === ContentItemType.Video) {
            return {
              type: VideoItem.Video,
              video: item.entity,
            };
          }

          if (item.type === ContentItemType.Pathway) {
            return {
              type: VideoItem.Pathway,
              pathway: item.entity,
            };
          }

          return assertNever(item.type);
        })
        .filter(toBoolean<VideoBlockItem>),
    };
  }, [data]);

  return <StyledVideosBlock data={formattedData} {...rest} />;
};

export default ContentListBlock;

const StyledVideosBlock = styled(VideosBlock)`
  & .swiper {
    ${({ theme }) => theme.breakpoints.up('md')} {
      padding-top: 35px;
    }
  }
`;
