import React, { FC, useMemo } from 'react';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { alpha } from '@mui/material/styles';
import parse from 'html-react-parser';

import { ContactForm } from '~/components/ContactFormBlock/ContactForm';
import IKImage from '~/components/IKImage/IKImage';
import { H1 } from '~/components/UI/Texts';
import { HERO_IMG_SRC_SET } from '~/const';
import {
  BIG_MARGIN_PX,
  LARGE_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  SMALL_MARGIN_PX,
  SMALL_RADIUS_PX,
  TINY_MARGIN_PX,
} from '~/theme';
import {
  LearnerInfo,
  ContactFormBlock as ContactFormBlockType,
  ContactFormItem,
  Maybe,
  Background,
} from '~/types';

interface ContactFormBlockProps {
  data: ContactFormBlockType;
  userInfo?: LearnerInfo;
}

const ContactFormBlock: FC<ContactFormBlockProps> = ({ data: blockData, userInfo }) => {
  const background = blockData?.background;
  const heading = blockData?.heading;

  const reasonItems: ContactFormItem[] = useMemo(() => {
    return blockData?.items
      ? (blockData.items.filter((item) => Boolean(item)) as ContactFormItem[])
      : [];
  }, [blockData]);

  return (
    <Wrapper background={background} className="contact-form-block">
      {blockData?.background_image && (
        <BgImageWrapper>
          <BackgroundImage
            path={blockData?.background_image}
            alt={heading}
            srcset={HERO_IMG_SRC_SET}
            sizes="100vw"
            draggable={false}
          />
        </BgImageWrapper>
      )}
      <StyledContainer>
        <Content>
          <Heading variant="h1">{blockData?.heading}</Heading>
          <Subtitle data-testid="subtitle">
            {Boolean(blockData?.sub_title) && parse(blockData.sub_title as string)}
          </Subtitle>
          <ContactForm
            userName={userInfo?.name}
            userEmail={userInfo?.email}
            reasonItems={reasonItems}
            userComFormId={blockData?.user_com_formId}
            nameField={blockData?.name_field}
            emailField={blockData?.email_field}
            contactReasonField={blockData?.contact_reason_field}
            contactMessageField={blockData?.contact_message_field}
          />
        </Content>
      </StyledContainer>
    </Wrapper>
  );
};

export default ContactFormBlock;

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background?: Maybe<Background> }>`
  padding-top: ${MIDDLE_MARGIN_PX};
  padding-bottom: 0;
  background: ${({ theme }) => theme.palette.secondary.dark};
  overflow-x: hidden;
  position: relative;
  &:first-child {
    padding-top: 260px;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    backgorund: ${({ background, theme }) =>
      background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light};
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: ${LARGE_MARGIN_PX};
    padding-bottom: ${LARGE_MARGIN_PX};
    &:first-child {
      padding-top: 190px;
      padding-bottom: 150px;
    }
  }
`;
const StyledContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
`;
const BgImageWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  max-height: 500px;
  height: 100%;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 540px;
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.palette.common.blueDark} 12.5%,
      ${({ theme }) => alpha(theme.palette.common.blueDark, 0.0001)} 100%
    );
    z-index: 1;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-height: unset;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        ${({ theme }) => alpha(theme.palette.common.blueDark, 0.720783)} 0%,
        ${({ theme }) => alpha(theme.palette.common.blueDark, 0.0001)} 100%
      );
      z-index: 1;
    }
  }
`;
const BackgroundImage = styled(IKImage)`
  position: absolute;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-35%);
  ${({ theme }) => theme.breakpoints.up('sm')} {
    transform: translateX(-50%);
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    transform: translateX(-42%);
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    transform: translateX(-50%);
  }
`;
const Content = styled('div')`
  width: 100%;
  max-width: 720px;
  position: relative;
  padding: ${MIDDLE_MARGIN_PX} ${SMALL_MARGIN_PX};
  background: ${({ theme }) => theme.palette.common.blackSecondary};
  border-radius: ${SMALL_RADIUS_PX};
  box-shadow: 0px 3px 6px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)};
  z-index: 2;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${BIG_MARGIN_PX} ${MIDDLE_MARGIN_PX};
    max-width: 500px;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-width: 720px;
  }
`;
const Heading = styled(H1)`
  && {
    margin-bottom: ${TINY_MARGIN_PX};
  }
`;
const Subtitle = styled('div')`
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: ${MIDDLE_MARGIN_PX};
  color: ${({ theme }) => theme.palette.common.gray};
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 20px;
  }
  & h1,& h2,& h3,& h4,& h5,& h6,& p,& ul,& ol{
    margin-bottom: ${SMALL_MARGIN_PX};
    margin-top: 0;
  }
  & h1,& h2,& h3,& h4,& h5,& h6 {
    color ${({ theme }) => theme.palette.common.white};
    line-height: 1.2;
  }
  & ul, & ol {
    padding-left: 20px;
    & li::marker {
      color: ${({ theme }) => theme.palette.common.primary};
    }
  }
  & a, & a>span {
    color: ${({ theme }) => theme.palette.common.white}; !important;
  }
`;
