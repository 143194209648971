import React, { useRef, useState } from 'react';

import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { alpha, Theme } from '@mui/material/styles';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types/swiper-options';

import ChevronLeftIcon from '~/assets/icons/ChevronLeftIcon';
import ChevronRightIcon from '~/assets/icons/ChevronRightIcon';
import Select from '~/components/Select/Select';
import Slide from '~/components/TabsSliderBlock/components/Slide/Slide';
import useIsSSR from '~/hooks/useIsSSR';
import {
  BIG_MARGIN,
  LARGE_MARGIN,
  MIDDLE_MARGIN,
  SUBMIDDLE_MARGIN,
  SUBMIDDLE_MARGIN_PX,
  useIsWidthUp,
} from '~/theme';
import { Background } from '~/types';

const swiperSetting: SwiperOptions = {
  slidesPerView: 1,
  spaceBetween: 20,
};

const TabsSliderBlock = ({ data }) => {
  const isMobile = !useIsWidthUp('md');
  const isSsr = useIsSSR();

  const swiperRef = useRef<any>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleRealIndexChanged = (swiper) => setSelectedTabIndex(swiper?.activeIndex);

  //@ts-ignore
  const goNext = () => swiperRef.current?.slideNext();
  //@ts-ignore
  const goPrev = () => swiperRef.current?.slidePrev();
  //@ts-ignore
  const slideTo = (index) => swiperRef.current?.slideTo(index);

  const currentIndex = swiperRef.current?.activeIndex;

  const slides = data?.content;
  const options = slides.map((slide, index) => ({ value: index, display: slide.title }));
  const isDark = data?.background === Background.Dark;

  return (
    <Wrapper
      className={cn('tabs-slider-block', isDark ? 'dark-block' : 'light-block')}
      isDark={isDark}
    >
      <Container>
        <Title data-testid="tabsSliderBlockTitle" variant="h2">
          {data?.heading}
        </Title>
        {isMobile ? (
          <StyledSelect
            items={options}
            value={options[selectedTabIndex]}
            onChange={({ value }) => slideTo(value)}
          />
        ) : (
          <Tabs>
            {slides.map((slide, index) => (
              <Tab
                data-testid="tabsSliderBlockTab"
                key={index}
                selected={selectedTabIndex === index}
                onClick={() => slideTo(index)}
              >
                {slide.title}
              </Tab>
            ))}
          </Tabs>
        )}
        <SwiperWrapper>
          <NavLeft disabled={currentIndex === 0} onClick={goPrev}>
            <StyledLeftChevron />
          </NavLeft>
          {!isSsr && (
            <Swiper
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              onRealIndexChange={handleRealIndexChanged}
              {...swiperSetting}
            >
              {slides.map(({ image, theses, title }, index) => (
                <SwiperSlide key={index}>
                  <Slide
                    imagePath={image}
                    listItems={theses}
                    title={title}
                    slideColor={isDark ? Background.Light : Background.Dark}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          <NavRight disabled={currentIndex === slides.length - 1} onClick={goNext}>
            <StyledRightChevron />
          </NavRight>
        </SwiperWrapper>
      </Container>
    </Wrapper>
  );
};

export default TabsSliderBlock;

const Container = styled('div')(({ theme }: { theme: Theme }) => ({
  maxWidth: 1440,
  margin: '0 auto',
  padding: '0 16px',
  boxSizing: 'border-box',
  [theme.breakpoints.up('md')]: {
    padding: '0 24px',
  },
}));
const NavLeft = styled('div')<{ disabled: boolean }>(({ theme, disabled }) => ({
  display: 'none',
  cursor: !disabled ? 'pointer' : 'initial',
  [theme.breakpoints.up('lg')]: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  '&:hover svg': {
    opacity: !disabled ? 1 : 0.5,
  },
}));
const NavRight = styled('div')<{ disabled: boolean }>(({ theme, disabled }) => ({
  display: 'none',
  cursor: !disabled ? 'pointer' : 'initial',
  [theme.breakpoints.up('lg')]: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  '&:hover svg': {
    opacity: !disabled ? 1 : 0.5,
  },
}));
const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDark',
})<{ isDark: boolean }>`
  padding: ${BIG_MARGIN}px 0;
  background: ${({ theme }) => theme.palette.common.blueDark};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    background: ${({ isDark, theme }) =>
      isDark ? theme.palette.common.blueDark : theme.palette.common.blackSecondary};
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${LARGE_MARGIN}px 0;
  }
`;
const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
  marginBottom: SUBMIDDLE_MARGIN,
  textAlign: 'center',
  fontSize: 24,

  [theme.breakpoints.up('sm')]: {
    marginBottom: SUBMIDDLE_MARGIN,
    fontSize: 24,
  },

  [theme.breakpoints.up('md')]: {
    marginBottom: MIDDLE_MARGIN,
    fontSize: 28,
  },
}));
const SwiperWrapper = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  [theme.breakpoints.up('lg')]: {
    padding: '0 100px',
  },
  '& .swiper-pagination': {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  '& .swiper-slide': {
    height: 'auto',
  },
}));
const StyledRightChevron = styled(ChevronRightIcon)({
  width: 44,
  marginLeft: BIG_MARGIN,
  opacity: 0.5,
  transition: '0.3s opacity',
});
const StyledLeftChevron = styled(ChevronLeftIcon)({
  width: 44,
  marginRight: BIG_MARGIN,
  opacity: 0.5,
  transition: '0.3s opacity',
});
const Tabs = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginBottom: 26,
});
const Tab = styled('span')<{ selected: boolean }>(({ selected, theme }) => ({
  position: 'relative',
  margin: '0 14px 10px',
  color: selected ? theme.palette.common.white : alpha(theme.palette.common.white, 0.5),
  cursor: 'pointer',
  fontSize: 18,
  '&:after': {
    content: '""',
    position: 'absolute',
    top: '100%',
    left: 0,
    height: 2,
    width: selected ? '100%' : '0%',
    background: theme.palette.common.blue,
    transition: '0.3s width',
  },
}));
const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};
`;
