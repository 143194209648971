import React, { FC } from 'react';

import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

import ChevronRightIcon from '~/assets/icons/ChevronRightIcon';
import CircleCheckIcon from '~/assets/icons/CircleCheckIcon';
import CloseIcon from '~/assets/icons/CloseIcon';
import { useAlert } from '~/context/AlertProvider';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { ANALYTICS_ROUTE } from '~/routes';
import { MIDDLE_MARGIN_PX, TINY_MARGIN } from '~/theme';

interface IToggleWatchlistPopper {
  existInWatchList: boolean | undefined;
}

const ToggleWatchlistPopper: FC<IToggleWatchlistPopper> = ({ existInWatchList }) => {
  const theme = useTheme();
  const { t } = useTenantTranslation();
  const popperIconColor = theme.palette.common.blueDark;
  const { hideAlert } = useAlert();

  return (
    <>
      <StyledCheckIcon color={popperIconColor} />
      <PopperContent>
        <PopperMessage>
          {existInWatchList
            ? t('alert.videoRemovedFromWatchList')
            : t('alert.videoAddedToWatchList')}
        </PopperMessage>
        <StyledLink to={`${ANALYTICS_ROUTE}/#watchlist`}>
          {t('button.checkWatchlist')} <StyledChevronRightIcon color={popperIconColor} />
        </StyledLink>
      </PopperContent>
      <StyledCloseIcon onClick={() => hideAlert()} color={popperIconColor} />
    </>
  );
};

export default ToggleWatchlistPopper;

const createSmallPopperIcon = (tag) => styled(tag)`
  width: 12px;
  height: 12px;
`;
const StyledCheckIcon = styled(CircleCheckIcon)`
  position: relative;
  top: 2px;
  width: 16px;
  height: 16px;
`;
const StyledChevronRightIcon = createSmallPopperIcon(styled(ChevronRightIcon)`
  margin-left: ${TINY_MARGIN / 2}px;
`);
const StyledCloseIcon = createSmallPopperIcon(styled(CloseIcon)`
  cursor: pointer;
`);
const PopperContent = styled('div')`
  margin-left: ${TINY_MARGIN * 2}px;
  margin-right: ${MIDDLE_MARGIN_PX};
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.common.blueDark};
`;
const PopperMessage = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: ${TINY_MARGIN}px;
`;
const StyledLink = styled(Link)`
  font-size: 14px;
  line-height: 18px;
  width: fit-content;
  color: ${({ theme }) => theme.palette.common.blueDark};
  display: flex;
  align-items: center;
`;
