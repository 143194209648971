import React, { useRef } from 'react';

import { useQuery } from '@apollo/client';
import { TextField } from '@mui/material';
import { styled } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { captureException } from '@sentry/react';
import { scroller } from 'react-scroll';

import CircleCheckIcon from '~/assets/icons/CircleCheckIcon';
import PrivacyBlock from '~/blocks/PrivacyBlock';
import StickyBlockContent from '~/blocks/RequestADemo/StickyBlockContent';
import { requestDemoFormSchema } from '~/blocks/RequestADemo/validation';
import AnimatedSkeleton from '~/components/AnimatedSkeleton';
import Checkbox from '~/components/Checkbox/Checkbox';
import HidingStickyBlock from '~/components/HidingStickyBlock';
import IKImage from '~/components/IKImage/IKImage';
import CustomButton from '~/components/UI/CustomButton/CustomButton';
import { HERO_IMG_SRC_SET } from '~/const';
import { useAlert } from '~/context/AlertProvider';
import { useTenantContext } from '~/context/TenantProvider';
import { GET_LIST_PARTNERS_BY_FILTER } from '~/graphql/partners';
import useIsAboveViewport from '~/hooks/useIsAboveViewport';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { getUtmParameters } from '~/hooks/useTrackUtmParameters';
import useValidation from '~/hooks/useValidation';
import {
  BIG_MARGIN,
  BIG_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  MIDDLE_RADIUS_PX,
  SMALL_MARGIN_PX,
  SUBMIDDLE_MARGIN_PX,
} from '~/theme';
import { Query, QueryListPartnersArgs } from '~/types';
import UserCom from '~/utils/UserCom';
import { checkIsSSR } from '~/utils/checkIsSSR';
import { GtmEvents, sendEventToGTM } from '~/utils/gtmUtils';
import replaceByRegExp from '~/utils/stringManipulations/replaceByRegExp';

const FALLBACK_IMAGE =
  'https://img-cdn.financeunlocked.com/page-images/requestDemo.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668363740187';

const RequestADemo = ({ data: blockData }) => {
  const blockRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTenantTranslation();
  const { showAlert } = useAlert();
  const isSSR = checkIsSSR();
  const stickyVisible = useIsAboveViewport(blockRef);
  const { tenant } = useTenantContext();

  const { values, errors, handleChange, handleSubmit, resetToDefault, setFieldManually } =
    useValidation(
      {
        email: '',
        name: '',
        message: '',
        companyName: '',
        phone: '',
        newsletterPositiveConsent: true,
      },
      requestDemoFormSchema,
      onSubmit,
    );

  const handleClickStickyBlock = () => {
    scroller.scrollTo('request-demo-form', {
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -80,
    });
  };

  async function onSubmit(values) {
    try {
      if (UserCom.checkHasUserCom()) {
        const name = values.name.split(' ');
        const firstName = name[0];
        const lastName = name.length > 1 ? name.slice(1).join(' ') : '';

        const data = {
          email: values.email,
          company_name: values.companyName,
          ...(values?.phone && { phone_number: values.phone }),
          contact_message_json: { message: values.message.trim() },
          newsletter_positive_consent: values.newsletterPositiveConsent,
          request_form_title_automation: blockData?.form_title_automation || 'Request a demo',
          form_tenant: tenant?.name || '',
          ...getUtmParameters(),
        };

        await UserCom.updateClient({
          //name attribute does not update user name in user.com,just save it as custom attribute
          first_name: firstName,
          last_name: lastName,
          ...data,
        });

        await UserCom.sendEvent('form_conversion', {
          form_id: blockData.usercom_form_id,
          name: values.name,
          ...data,
        });

        sendEventToGTM(GtmEvents.submitForm, { email: data.email });
        showAlert({ message: t('alert.messageSendSuccessfully') });
        resetToDefault();
      } else {
        throw new Error("window.userengage wasn't loaded");
      }
    } catch (e) {
      captureException(e);
    }
  }

  const { data, loading: loadingData } = useQuery<
    Pick<Query, 'listPartners'>,
    QueryListPartnersArgs
  >(GET_LIST_PARTNERS_BY_FILTER, {
    variables: {
      filter: {
        type_id: blockData?.partner_type?.id,
      },
    },
    skip: !blockData?.partner_type || isSSR,
  });
  const loading = loadingData || isSSR;

  const replaceWithButton = replaceByRegExp(new RegExp(/\*\*(?:(?!\*\*).)+\*\*/g), (match) => (
    <StyledButton
      type="button"
      onClick={() => window.open(blockData.book_time_link, 'demo', 'width=700,height=600')}
    >
      {match?.slice(2, -2)}
    </StyledButton>
  ));

  return (
    <Wrapper>
      <BackgroundWrapper>
        <ImageOverlay />
        <BackgroundImage
          path={blockData?.preview_image_url || FALLBACK_IMAGE}
          alt={blockData?.seo_image_name}
          srcset={HERO_IMG_SRC_SET}
          sizes="100vw"
          draggable={false}
        />
      </BackgroundWrapper>
      <StyledContainer ref={blockRef}>
        <Row>
          <LeftCol>
            <Title variant="h2">{blockData?.demo_title}</Title>
            <Subtitle>{blockData?.subtitle}</Subtitle>
            <ListTitle variant="h3">
              {blockData?.about_our_title || t('block.requestADemo.learnMoreAboutOur')}:
            </ListTitle>
            <List>
              {blockData?.about_our?.map((item, index) => (
                <ListItem key={index}>
                  <StyledCheckIcon />
                  {item}
                </ListItem>
              ))}
            </List>
            {!!data?.listPartners?.length && (
              <>
                <PartnersTitle variant="h3">
                  {blockData.partners_title || t('block.requestADemo.trustedBy')}
                </PartnersTitle>
                <Partners>
                  {loading
                    ? new Array(3)
                        .fill(null)
                        .map((item, index) => <LoadingPartnerLogo key={index} xs="70px" />)
                    : data?.listPartners?.map((partner) => (
                        <PartnerLogo
                          key={partner.id}
                          path={partner.image_url}
                          alt={partner.title}
                        />
                      ))}
                </Partners>
              </>
            )}
          </LeftCol>
          <Col>
            <StyledForm id="request-demo-form" onSubmit={handleSubmit}>
              <FormTitle variant="h2">{blockData.form_title}</FormTitle>
              <FieldsWrapper>
                <StyledField
                  name="email"
                  label={t('input.placeholder.workEmail') + '*'}
                  value={values.email}
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={handleChange}
                />
                <StyledField
                  name="name"
                  label={t('input.placeholder.yourName') + '*'}
                  value={values.name}
                  error={!!errors.name}
                  helperText={errors.name}
                  onChange={handleChange}
                />
                <StyledField
                  name="companyName"
                  label={t('page.subscription.companyName') + '*'}
                  value={values.companyName}
                  error={!!errors.companyName}
                  helperText={errors.companyName}
                  onChange={handleChange}
                />
                <StyledField
                  name="phone"
                  label={t('input.placeholder.phoneNumber')}
                  value={values.phone}
                  error={!!errors.phone}
                  helperText={errors.phone}
                  onChange={handleChange}
                />
                <StyledField
                  name="message"
                  label={t('input.placeholder.message')}
                  value={values.message}
                  error={!!errors.message}
                  helperText={errors.message}
                  onChange={handleChange}
                  multiline
                  rows={9}
                  inputProps={{
                    'aria-invalid': errors.message ? true : undefined,
                  }}
                />
                <Checkbox
                  name="newsletterPositiveConsent"
                  onChange={() =>
                    setFieldManually(
                      'newsletterPositiveConsent',
                      !values?.newsletterPositiveConsent,
                    )
                  }
                  checked={values?.newsletterPositiveConsent}
                >
                  {t('block.letsTalk.newsletterAgreement')}
                </Checkbox>
              </FieldsWrapper>
              <FormActions>
                <StyledCustomButton type="submit">{blockData.demo_button_text}</StyledCustomButton>
                <LinkWrapper>
                  {blockData?.book_time_link_title ? (
                    blockData.book_time_link ? (
                      replaceWithButton(blockData?.book_time_link_title)
                    ) : (
                      blockData?.book_time_link_title
                    )
                  ) : (
                    <>
                      {t('block.requestADemo.bookTimeDirectly')}{' '}
                      <StyledButton
                        type="button"
                        onClick={() =>
                          window.open(blockData.book_time_link, 'demo', 'width=700,height=600')
                        }
                      >
                        {t('block.requestADemo.here')}.
                      </StyledButton>
                    </>
                  )}
                </LinkWrapper>
              </FormActions>
              <StyledPrivacyBlock />
            </StyledForm>
          </Col>
        </Row>
      </StyledContainer>

      {blockData?.show_sticky_block && (
        <HidingStickyBlock visible={stickyVisible}>
          <StickyBlockContent
            title={blockData?.demo_title}
            subtitle={blockData?.subtitle}
            btnTitle={blockData?.demo_button_text}
            handleClick={handleClickStickyBlock}
          />
        </HidingStickyBlock>
      )}
    </Wrapper>
  );
};

export default RequestADemo;

const HEADER_HEIGHT_PX = '120px';
const StyledContainer = styled(Container)`
  position: relative;
  z-index: 1;
`;
const Row = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;
const Col = styled('div')`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 50%;
  }
`;
const BackgroundImage = styled(IKImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Wrapper = styled('div')`
  position: relative;
  padding-top: 220px;
  padding-bottom: ${HEADER_HEIGHT_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: 230px;
  }
`;
const BackgroundWrapper = styled('div')`
  position: absolute;
  width: 100%;
  height: 370px;
  left: 0;
  top: 0;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-bottom: ${HEADER_HEIGHT_PX};
    height: 100%;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 70%;
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.palette.common.blueDark} 0%,
      ${({ theme }) => alpha(theme.palette.common.blueDark, 0)} 100%
    );

    ${({ theme }) => theme.breakpoints.up('md')} {
      height: 400px;
      bottom: ${HEADER_HEIGHT_PX};
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      90deg,
      ${({ theme }) => alpha(theme.palette.common.blueDark, 0.7)} 0%,
      ${({ theme }) => alpha(theme.palette.common.blueDark, 0.0001)} 100%
    );

    ${({ theme }) => theme.breakpoints.up('md')} {
      bottom: ${HEADER_HEIGHT_PX};
      background: linear-gradient(
        90deg,
        ${({ theme }) => theme.palette.common.blueDark} 0%,
        ${({ theme }) => alpha(theme.palette.common.blueDark, 0)} 100%
      );
    }
  }
`;
const ImageOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 130%;
  height: 100%;
  background: radial-gradient(
    82% 64.09% at 50% 50%,
    ${({ theme }) => alpha(theme.palette.common.violet, 0.396078)} 0%,
    ${({ theme }) => alpha(theme.palette.common.primary, 0.4)} 100%
  );

  ${({ theme }) => theme.breakpoints.up('md')} {
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.palette.common.blueDark} 0%,
      ${({ theme }) => alpha(theme.palette.common.blueDark, 0)} 100%
    );
  }

  &:after {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    position: absolute;
    background: transparent
      linear-gradient(
        180deg,
        ${({ theme }) => theme.palette.common.blueDark} 0%,
        ${({ theme }) => alpha(theme.palette.common.blueDark, 0)} 100%
      )
      0% 0% no-repeat padding-box;
  }
`;
const Title = styled(Typography)`
  ${({ theme }) => theme.breakpoints.up('xs')} {
    font-size: 32px;
    margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 40px;
    margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  }
`;
const Subtitle = styled(Typography)`
  margin-bottom: ${MIDDLE_MARGIN_PX};
  font-size: 20px;
  line-height: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
  }
`;
const ListTitle = styled(Typography)`
  font-weight: 700;

  ${({ theme }) => theme.breakpoints.up('xs')} {
    margin-bottom: ${SUBMIDDLE_MARGIN_PX};
    font-size: 20px;
  }
`;
const PartnersTitle = styled(ListTitle)`
  display: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
  }
`;
const List = styled('ul')`
  list-style-type: none;
  margin-bottom: ${BIG_MARGIN_PX};
  padding-left: 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 115px;
  }
`;
const ListItem = styled('li')`
  display: flex;
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};
`;
const StyledCheckIcon = styled(CircleCheckIcon)`
  width: 24px;
  height: 24px;
  min-width: 24px;
  margin-right: ${SMALL_MARGIN_PX};
`;
const LeftCol = styled(Col)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-right: 90px;
  }
`;
const StyledForm = styled('form')`
  width: 100%;
  padding: ${MIDDLE_MARGIN_PX} ${SMALL_MARGIN_PX};
  background: ${({ theme }) => theme.palette.common.blackSecondary};
  box-shadow: 0 3px 6px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)};
  border-radius: ${MIDDLE_RADIUS_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${BIG_MARGIN_PX} ${MIDDLE_MARGIN_PX};
  }
`;
const FormTitle = styled(Typography)`
  ${({ theme }) => theme.breakpoints.up('xs')} {
    font-size: 28px;
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 28px;
  }
`;
const StyledPrivacyBlock = styled(PrivacyBlock)`
  margin-top: ${BIG_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: ${MIDDLE_MARGIN_PX};
  }
`;
const FormActions = styled('div')`
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
const LinkWrapper = styled('div')`
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 18px;
  }
`;
const StyledButton = styled('button')`
  min-height: 24px;
  padding: 0;
  border: none;
  color: ${({ theme }) => theme.palette.common.primary};
  background: transparent;
  cursor: pointer;
  font-size: inherit;
`;
const Partners = styled('div')`
  display: none;
  margin-bottom: ${BIG_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -${BIG_MARGIN / 2}px 0;
  }
`;
const FieldsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: ${MIDDLE_MARGIN_PX};
`;
const StyledField = styled(TextField)`
  &:not(:last-child) {
    margin-bottom: ${SMALL_MARGIN_PX};
  }
`;
const createPartnerLogo = (tag) => styled(tag)`
  width: calc(100% / 3 - ${BIG_MARGIN_PX});
  margin: 0 ${BIG_MARGIN / 2}px ${SMALL_MARGIN_PX};
  object-fit: contain;
`;
const PartnerLogo = createPartnerLogo(IKImage);
const LoadingPartnerLogo = createPartnerLogo(AnimatedSkeleton);
const StyledCustomButton = styled(CustomButton)`
  width: 100%;
  margin-bottom: ${MIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: unset;
    margin-bottom: 0;
  }
`;
