import React, { FC } from 'react';

import { styled } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';

import Loader from '~/components/Loader/Loader';
import LoginWithPopupTriggerView from '~/components/LoginWithPopupTriggerView/LoginWithPopupTriggerView';
import { useTenantContext } from '~/context/TenantProvider';
import { useUserInfoContext } from '~/context/UserInfoProvider';
import useUpdateLoginDomain from '~/hooks/auth/useUpdateLoginDomain';
import useIsSsku from '~/hooks/tenant/useIsSsku';
import useAuth from '~/hooks/useAuth';
import useQueryParams from '~/hooks/useQueryParams';
import { getConnectionName } from '~/pages/LoginPage/LoginPage';
import { PRICING_ROUTE } from '~/routes';
import { checkIsIframed } from '~/utils/checkIsIframed';

interface RequireAuthProps {
  withSubscription?: boolean;
  children: React.ReactElement;
}

const RequireAuth: FC<RequireAuthProps> = ({ withSubscription = true, children }) => {
  const { isInitialized, isLoading, loginWithRedirect, loginWithPopup } = useAuth();
  const { userLoading, userInfo, hasAccessToContent } = useUserInfoContext();
  const { config } = useTenantContext();
  const location = useLocation();
  const isSsku = useIsSsku();
  const params = useQueryParams();
  const connection =
    isSsku && process.env.REACT_APP_BUILD_TYPE === 'prod'
      ? getConnectionName(params) || config.auth.connection
      : getConnectionName(params);
  const updatingLoginDomain = useUpdateLoginDomain(connection);

  if (process.env.REACT_APP_MSW) {
    return children;
  }

  if (userLoading || !isInitialized || isLoading || updatingLoginDomain) {
    return (
      <PageContainer>
        <Loader />
      </PageContainer>
    );
  }

  if (!userInfo) {
    if (checkIsIframed()) {
      return <LoginWithPopupTriggerView handleLogin={loginWithPopup} />;
    } else {
      loginWithRedirect({
        redirectUri: `${location.pathname}${location.search}`,
        isDirectUrl: location.pathname === config?.appMainPage,
      });
      return null;
    }
  }

  if (!hasAccessToContent && withSubscription) {
    return <Navigate to={PRICING_ROUTE} replace />;
  }

  return children;
};

export default RequireAuth;

const PageContainer = styled('div')`
  min-height: 100vh;
`;
