import React from 'react';

import { styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import PlaySmallIcon from '~/assets/icons/PlaySmallIcon';
import IKImage from '~/components/IKImage/IKImage';
import { H4, MetaText } from '~/components/UI/Texts';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { SMALL_MARGIN_PX, TINY_MARGIN_PX } from '~/theme';

//due to not standard breakpoint used img with width 20px for width < 1440px
const SRCSET = [{ width: 20 }, { width: 170 }, { width: 340 }];

const MegaMenuContentCard = ({ title, description, imageUrl, link, withPlayIcon }) => {
  const { t } = useTenantTranslation();
  return (
    <Wrapper data-testid="megamenuContentCard">
      <ImageWrapper>
        <Link to={link}>
          <StyledImage
            srcset={SRCSET}
            sizes="(max-width: 1439px) 20px, (max-width: 1920px) 170px, 340px"
            path={imageUrl}
          />
          {withPlayIcon && (
            <PlayBtn aria-label={t('button.playVideo')} data-testid="megamenuContentCardPlayBtn">
              <PlayIcon />
            </PlayBtn>
          )}
        </Link>
      </ImageWrapper>
      <TextWrapper>
        <Link to={link}>
          <Title>{title}</Title>
        </Link>
        <Description>{description}</Description>
      </TextWrapper>
    </Wrapper>
  );
};

export default MegaMenuContentCard;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'wrapperWidth',
})`
  display: flex;
  align-items: center;
`;

const ImageWrapper = styled('div')`
  margin-right: ${SMALL_MARGIN_PX};
  position: relative;
  flex: 1;
`;

const StyledImage = styled(IKImage)`
  width: 100%;
`;

const TextWrapper = styled('div')`
  flex: 2;
`;

const Title = styled(H4)`
  margin-bottom: ${TINY_MARGIN_PX};
`;

const PlayBtn = styled('button')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background: ${({ theme }) => alpha(theme.palette.common.blueDark, 0.5)};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const PlayIcon = styled(PlaySmallIcon)`
  width: 24px;
  height: 24px;
  margin-left: 4px;
`;

const Description = styled(MetaText)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
`;
