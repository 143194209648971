import React, { FC, RefObject, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { InputBase, useTheme } from '@mui/material';
import Autocomplete, { AutocompleteCloseReason } from '@mui/material/Autocomplete';
import ButtonBase from '@mui/material/ButtonBase';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { alpha, css, styled } from '@mui/material/styles';

import Blink from '~/components/Blink/Blink';
import Checkbox from '~/components/Checkbox/Checkbox';
import { SelectOptionType } from '~/hooks/useMultipleOptions';
import { layers, SMALL_MARGIN_PX } from '~/theme';

type OptionType = 'checkbox' | 'classic';

export interface BaseSelectProps {
  className?: string;
  id?: string;
  labelId?: string;
  value?: SelectOptionType | SelectOptionType[] | null;
  onChange?: (value, details) => void;
  items?: readonly SelectOptionType[];
  multiple?: boolean;
  anchorRef?: RefObject<HTMLElement>;
  widthByAnchor?: boolean;
  search?: boolean;
  popperLabel?: string;
  placement?: PopperPlacementType;
  blinking?: boolean;
  titleValue?: SelectOptionType | SelectOptionType[] | null;
  optionType?: OptionType;
  onOpen?: () => void;
  onValueSelected?: (value: string) => void;
}

const Input = (params) => {
  const className = params?.className;

  return (
    <InputBase
      className={className}
      ref={params.InputProps.ref}
      inputProps={params.inputProps}
      autoFocus
    />
  );
};

const BaseSelect: FC<BaseSelectProps> = ({
  className,
  id,
  value,
  onChange,
  items = [],
  multiple = false,
  anchorRef = null,
  widthByAnchor = false,
  search = false,
  popperLabel = '',
  placement = 'bottom-start',
  blinking = false,
  optionType = 'classic',
  titleValue,
  onOpen,
  onValueSelected,
  ...rest
}) => {
  const anchorEl = anchorRef?.current;
  const width = widthByAnchor ? anchorEl?.clientWidth : undefined;
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const theme = useTheme();

  const handleClick = () => {
    if (!open) {
      onOpen?.();
    }
    setOpen(true);
  };

  const handleClose = (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
    if (reason === 'toggleInput') {
      return;
    }

    setOpen(false);
  };

  const getOptionSelected = (option, value) => option?.value === value?.value;

  const renderOption = (props, option, { selected }) => {
    return (
      <OptionHighlighted {...props} selected={selected} hidden={option.hidden}>
        <div>{option.display}</div>
        <VisibilityIcon
          style={{ visibility: selected ? 'visible' : 'hidden', color: theme.palette.common.gray }}
        />
      </OptionHighlighted>
    );
  };
  const renderCheckboxOption = (props, option, { selected }) => {
    return (
      <Option {...props} selected={selected} hidden={option.hidden}>
        <StyledCheckbox
          variant={option.primary ? 'primary' : 'secondary'}
          name={props.id}
          checked={selected}
          onChange={(e) => e.stopPropagation()}
        >
          {option.display}
        </StyledCheckbox>
      </Option>
    );
  };

  const optionsByType = {
    classic: renderOption,
    checkbox: renderCheckboxOption,
  };

  const handleInputChange = (_, value, reason) => {
    if (!search) return;

    if (reason === 'reset') {
      setInputValue('');
      return;
    }
    setInputValue(value);
  };

  const handleOnChange = (_, value, reason, details) => {
    if (reason === 'selectOption') {
      onValueSelected?.(details.option.value);
    }
    onChange?.(value, details);
    if (!multiple) {
      setOpen(false);
    }
  };

  const resultValue = titleValue || value;

  return (
    <StyledButtonBase
      data-testid={rest['data-testid'] + 'Title'}
      className={className}
      disableRipple
      aria-describedby={id}
      onClick={handleClick}
      opened={open}
    >
      <Blink blinking={blinking} />
      <SelectHeader>
        {multiple ? (
          <>
            <SelectedItem id={id}>
              {Array.isArray(resultValue) &&
                resultValue.map((item, index) => {
                  const isLast = resultValue.length - 1 === index;

                  return (
                    <StyledSelectItem key={item?.value}>
                      {item?.display}
                      {isLast ? '' : ', '}
                    </StyledSelectItem>
                  );
                })}
            </SelectedItem>
            <ChevronIcon fontSize="large" />
          </>
        ) : (
          <>
            {!Array.isArray(resultValue) && (
              <StyledSelectItem id={id}>{resultValue?.display}</StyledSelectItem>
            )}
            <ChevronIcon fontSize="large" />
          </>
        )}
      </SelectHeader>
      {open && <FiltersOverlay />}
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        anchorWidth={width}
        disablePortal
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'offset',
            options: {
              offset: [0, -45],
            },
          },
        ]}
      >
        <div>
          <PopoverHeader>
            <StyledSelectItem>{popperLabel}</StyledSelectItem>
            <ChevronIcon fontSize="large" />
          </PopoverHeader>
        </div>
        <StyledAutocomplete
          data-testid={rest['data-testid']}
          open={open}
          onClose={handleClose}
          multiple={multiple}
          value={value}
          onChange={handleOnChange}
          disableCloseOnSelect
          fullWidth
          disablePortal
          inputValue={inputValue}
          onInputChange={handleInputChange}
          renderTags={() => null}
          noOptionsText="No options"
          isOptionEqualToValue={getOptionSelected}
          renderOption={optionsByType[optionType]}
          options={items}
          // @ts-ignore
          getOptionLabel={(option) => option.display || ''}
          renderInput={(params) => (
            <StyledInput optionalStyles={search ? undefined : inputHidedCss} {...params} />
          )}
        />
      </StyledPopper>
    </StyledButtonBase>
  );
};

export default BaseSelect;

const SelectedItem = styled('div')`
  flex-grow: 1;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 180px;
  }
`;
const StyledButtonBase = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'opened',
})<{ opened: boolean }>`
  position: relative;
  padding: 0 ${SMALL_MARGIN_PX};
  box-sizing: border-box;
  max-width: 100%;
  z-index: ${({ opened }) => opened && layers.dropdown};
`;
const SelectHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
  height: 45px;
  color: ${({ theme }) => theme.palette.common.text.secondaryAccent};
`;
const Option = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'hidden',
})<{ selected: boolean; hidden: boolean }>`
  && {
    padding: 11px;
    width: 100%;
    display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.common.text.secondaryAccent};

    &[aria-selected='true'],
    &[aria-selected='true'].Mui-focused,
    &[aria-selected='true']:hover {
      ${({ selected, theme }) =>
        selected && `background-color: ${theme.palette.common.transparent};`}
    }
  }
`;
const OptionHighlighted = styled(Option)`
  && {
    justify-content: space-between;

    &[aria-selected='true'],
    &[aria-selected='true'].Mui-focused,
    &[aria-selected='true']:hover {
      ${({ selected, theme }) => selected && `background-color: ${theme.palette.common.blue};`}
    }
  }
`;
const StyledSelectItem = styled('span')`
  font-size: 12px;
  font-weight: bold;
  color: inherit;
  text-align: left;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 16px;
  }
`;
const ChevronIcon = styled(KeyboardArrowDownIcon)`
  fill: ${({ theme }) => theme.palette.common.blue};
`;
const FiltersOverlay = styled('div')`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: ${({ theme }) => alpha(theme.palette.common.blueDark, 0.8)};
  z-index: 1;
`;
const StyledPopper = styled(Popper, {
  shouldForwardProp: (prop) => prop !== 'anchorWidth',
})<{ anchorWidth?: number }>`
  padding: 20px;
  background: ${({ theme }) => theme.palette.common.quiz.bg};
  border-radius: 20px;
  box-shadow: 0px 3px 6px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)};
  width: ${({ anchorWidth }) => anchorWidth + 'px' || 'unset'};
  min-width: 250px;
  box-sizing: border-box;
  z-index: 2;
`;
const PopoverHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  & > span {
    font-size: 18px;
  }
`;
const StyledAutocomplete = styled(Autocomplete)`
  & ~ .MuiAutocomplete-popper .MuiAutocomplete-paper {
    margin: 0;
    background: ${({ theme }) => theme.palette.common.quiz.bg};
    border-radius: 0;
    box-shadow: none;
  }

  & ~ .MuiAutocomplete-popper .MuiAutocomplete-option {
    text-align: left;
    border-radius: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & ~ .MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal {
    transform: translate(0, 0) !important;
    position: static !important;
    width: 100% !important;
  }
`;
const StyledInput = styled(Input, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  background: ${({ theme }) => alpha(theme.palette.common.white, 0.1)};
  border-radius: 50px;
  padding: 0 15px;
  height: 45px;
  width: 100%;
  margin-bottom: 20px;

  && {
    ${({ optionalStyles }) => optionalStyles}
  }
`;
const inputHidedCss = css`
  height: 0;
  padding: 0;
  margin-bottom: 0;
  opacity: 0;
  margin-top: -20px;

  & input {
    cursor: default;
  }
`;
// `pointer-events: none;` was added to prevent select lose its focus when clicking option with checkbox
const StyledCheckbox = styled(Checkbox)`
  pointer-events: none;
`;
