import { RefObject, useCallback } from 'react';

import { captureException } from '@sentry/react';

import useScriptLoader, { SCRIPTS, useScriptContext } from '~/context/ScriptLoader/useScriptLoader';

export enum VerseEvents {
  VideoCompleted = 'video_completed',
  LastChapterFinished = 'last-chapter-finished',
  VideoStartedPlaying = 'video_started_playing',
  VideoFinishedPlaying = 'video_finished_playing',
}

interface IVerseEvents {
  onHotspotClick: (cb: () => void) => void;
  changeUserId: (id: string, email: string, userComId: string) => void;
  changeVideoDuration: (duration: number | null, title?: string, name?: string) => void;
  onVideoClose: () => void;
}

const useVerse = (playerIframeRef?: RefObject<HTMLFrameElement>) => {
  const { isLoaded: isVerseLoaded } = useScriptLoader(SCRIPTS.VERSE);
  const { state } = useScriptContext();
  const verseEvents = state[SCRIPTS.VERSE] as IVerseEvents | null;

  const callIfVerseEvents = useCallback(
    (cb: () => void) => {
      if (verseEvents) {
        try {
          cb();
        } catch (error) {
          captureException(error);
        }
      }
    },
    [verseEvents],
  );

  const onHotspotClick = useCallback(
    (cb) => {
      callIfVerseEvents(() => verseEvents?.onHotspotClick(cb));
    },
    [verseEvents, callIfVerseEvents],
  );

  const onVideoClose = useCallback(() => {
    callIfVerseEvents(() => verseEvents?.onVideoClose());
  }, [verseEvents, callIfVerseEvents]);

  const changeUserId = useCallback(
    (id: string, email: string, userComId: string) => {
      callIfVerseEvents(() => verseEvents?.changeUserId(id, email, userComId));
    },
    [verseEvents, callIfVerseEvents],
  );

  const changeVideoDuration = useCallback(
    (duration: number | null, title?: string, name?: string) => {
      callIfVerseEvents(() => verseEvents?.changeVideoDuration(duration, title, name));
    },
    [verseEvents, callIfVerseEvents],
  );

  const callIfLoaded = useCallback(
    (cb: () => void) => {
      if (isVerseLoaded && playerIframeRef?.current?.contentWindow) {
        try {
          cb();
        } catch (error) {
          captureException(error);
        }
      } else {
        captureException(new Error('Sending event while verse script is not loaded'));
      }
    },
    [isVerseLoaded, playerIframeRef],
  );

  const goToChapter = useCallback(
    (index: string | number) => {
      callIfLoaded(() =>
        playerIframeRef?.current?.contentWindow?.postMessage(
          {
            action: 'goToChapter',
            index: index,
          },
          '*',
        ),
      );
    },
    [playerIframeRef, callIfLoaded],
  );

  const goToChapterId = useCallback(
    (chapterId: string | number) => {
      callIfLoaded(() =>
        playerIframeRef?.current?.contentWindow?.postMessage(
          {
            action: 'goToChapterId',
            chapterId,
          },
          '*',
        ),
      );
    },
    [playerIframeRef, callIfLoaded],
  );

  const play = useCallback(() => {
    callIfLoaded(() =>
      playerIframeRef?.current?.contentWindow?.postMessage({ action: 'play' }, '*'),
    );
  }, [playerIframeRef, callIfLoaded]);

  const seek = useCallback(
    (time) => {
      callIfLoaded(() =>
        playerIframeRef?.current?.contentWindow?.postMessage({ action: 'seek', time: time }, '*'),
      );
    },
    [playerIframeRef, callIfLoaded],
  );

  const setPlaybackRate = useCallback(
    (value) => {
      callIfLoaded(() =>
        playerIframeRef?.current?.contentWindow?.postMessage(
          { action: 'setPlaybackRate', value },
          '*',
        ),
      );
    },
    [playerIframeRef, callIfLoaded],
  );

  const setCaptionsLang = useCallback(
    (value) => {
      callIfLoaded(() =>
        playerIframeRef?.current?.contentWindow?.postMessage(
          { action: 'setCaptionsLang', value },
          '*',
        ),
      );
    },
    [playerIframeRef, callIfLoaded],
  );

  const sendCustomEvent = useCallback(
    (data) => {
      callIfLoaded(() => playerIframeRef?.current?.contentWindow?.postMessage(data, '*'));
    },
    [playerIframeRef, callIfLoaded],
  );

  return {
    goToChapter,
    goToChapterId,
    play,
    seek,
    onHotspotClick,
    onVideoClose,
    changeUserId,
    changeVideoDuration,
    sendCustomEvent,
    setPlaybackRate,
    setCaptionsLang,
  };
};

export default useVerse;
