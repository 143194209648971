import React, { createContext, useContext, useState } from 'react';

import { useUserInfoContext } from '~/context/UserInfoProvider';
import { GRID_VIEW, LIST_VIEW } from '~/pages/DiscoverPage/const';

type stateType = {
  searchState: any;
  setSearchState: Function;
  categoryTitle: string;
  setCategoryTitle: Function;
  openFiltersPanel: boolean;
  setOpenFiltersPanel: Function;
  tempSearchState: any;
  setTempSearchState: Function;
  discoverView: string;
  setDiscoverView: Function;
  refineSearchType?: (val: string) => void;
  setRefineSearchType: Function;
};

const STATE: stateType = {
  searchState: {},
  setSearchState: () => {},
  categoryTitle: '',
  setCategoryTitle: () => {},
  openFiltersPanel: false,
  setOpenFiltersPanel: () => {},
  tempSearchState: {},
  setTempSearchState: () => {},
  discoverView: LIST_VIEW,
  setDiscoverView: () => {},
  setRefineSearchType: () => {},
};

export const DiscoverContext = createContext(STATE);

export function useDiscoverContext() {
  const context = useContext(DiscoverContext);

  if (!context) {
    throw new Error('useDiscoverContext must be used within a DiscoverController');
  }

  return context;
}

export const DiscoverController = ({ children }) => {
  const [searchState, setSearchState] = useState({});
  const [categoryTitle, setCategoryTitle] = useState('');
  const [openFiltersPanel, setOpenFiltersPanel] = useState(false);
  const [tempSearchState, setTempSearchState] = useState({});
  const { userInfo } = useUserInfoContext();
  const [discoverView, setDiscoverView] = useState(userInfo ? LIST_VIEW : GRID_VIEW);
  const [refineSearchType, setRefineSearchType] = useState(undefined);

  return (
    <DiscoverContext.Provider
      value={{
        searchState,
        setSearchState,
        categoryTitle,
        setCategoryTitle,
        openFiltersPanel,
        setOpenFiltersPanel,
        tempSearchState,
        setTempSearchState,
        discoverView,
        setDiscoverView,
        refineSearchType,
        setRefineSearchType,
      }}
    >
      {children}
    </DiscoverContext.Provider>
  );
};
