import React, { FC, useEffect } from 'react';

import Loader from '~/components/Loader/Loader';
import { useTenantContext } from '~/context/TenantProvider';
import useAuth from '~/hooks/useAuth';

const ResetPasswordPage: FC = () => {
  const { loginWithRedirect, isInitialized } = useAuth();
  const { tenant, config } = useTenantContext();

  useEffect(() => {
    if (isInitialized) {
      loginWithRedirect({
        redirectUri: config?.appMainPage,
        mode: 'forgotPassword',
        isDirectUrl: true,
      });
    }
  }, [isInitialized, loginWithRedirect, tenant, config]);

  return <Loader />;
};

export default ResetPasswordPage;
