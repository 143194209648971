import React, { FC, useRef } from 'react';

import { IconButton } from '@mui/material';
import { styled } from '@mui/material';
import { alpha } from '@mui/material/styles';

import BellIcon from '~/assets/icons/BellIcon';
import Notifications from '~/blocks/Header/components/Notifications';
import HeaderDropdown from '~/components/HeaderDropdown';
import { ringing } from '~/components/animations/style';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { SMALL_MARGIN_PX } from '~/theme';
import { AnnouncementEntity } from '~/types';
import assertNever from '~/utils/errors/assertNever';

interface NotificationsBlockProps {
  notificationsList: AnnouncementEntity[];
  loading: boolean;
  fetchMore: () => void;
  showNotifications: boolean;
  setShowNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  ableFetchMore: boolean;
}

const isNewNotification = (notification: AnnouncementEntity) => {
  switch (notification.__typename) {
    case undefined:
    case 'NotificationForCompletedPathway':
    case 'NotificationForCompletedCourse':
      return false;
    case 'NotificationForAssignment':
    case 'NewVideoFromExpert':
    case 'Video':
    case 'Pathway':
      return notification?.is_highlighted;
    default:
      return assertNever(notification);
  }
};

const NotificationsBlock: FC<NotificationsBlockProps> = ({
  notificationsList,
  loading,
  fetchMore,
  showNotifications,
  setShowNotifications,
  ableFetchMore,
}) => {
  const { t } = useTenantTranslation();
  const notificationsButtonRef = useRef(null);
  const hasNewNotifications = notificationsList?.some(isNewNotification);

  return (
    <div id="notificationsCenter">
      <BellButton
        disabled={loading}
        ref={notificationsButtonRef}
        onClick={() => setShowNotifications((old) => !old)}
        opened={showNotifications}
        data-testid="headerNotificationsBtn"
        aria-label={t('page.dashboard.notifications')}
      >
        <BellWrapper marked={hasNewNotifications}>
          <StyledBellIcon />
        </BellWrapper>
      </BellButton>
      <HeaderDropdown opened={showNotifications} anchorElRef={notificationsButtonRef}>
        <Notifications
          items={notificationsList}
          fetchMore={fetchMore}
          disabledPagination={loading || !ableFetchMore}
          closeHandler={() => setShowNotifications(false)}
        />
      </HeaderDropdown>
    </div>
  );
};

export default NotificationsBlock;

const BellButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'marked' && prop !== 'opened',
})<{ opened: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  margin-right: 12px;
  border-radius: 50%;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  opacity: ${({ disabled }) => disabled && '0.3'};
  border: 1px solid ${({ theme }) => theme.palette.common.header.input.border};
  box-sizing: content-box;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 42px;
    min-width: 42px;
    height: 42px;
    margin-right: ${SMALL_MARGIN_PX};
    background: ${({ theme }) => theme.palette.common.header.input.bg};
  }

  &:hover {
    background: ${({ theme }) => alpha(theme.palette.common.header.input.bg, 0.8)};

    > div {
      animation: ${ringing} 2s;
      animation-fill-mode: forwards;
    }
  }
`;
const BellWrapper = styled('div')<{ marked: boolean }>`
  display: flex;
  transform-origin: 50% 0;
  transform: rotate(0deg);

  &:after {
    content: '';
    position: absolute;
    display: ${({ marked }) => (marked ? 'block' : 'none')};
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    transform: translate(calc(-50% + 7px), calc(-50% - 7px));
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.common.header.notificationAccent};
  }
`;
const StyledBellIcon = styled(BellIcon)`
  width: 24px;
  height: 24px;

  > * {
    stroke: ${({ theme }) => theme.palette.common.header.color};
    stroke-width: 7px;
  }
`;
