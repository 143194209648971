import { gql } from '@apollo/client';

export const GET_SHOW_GOALS_FLOW = gql`
  query GetGoalsFlow {
    showSetGoalsFlow
  }
`;

export const GET_LEARNER_GOALS = gql`
  query GetLearnerGoals {
    showLearnerGoals {
      categories {
        id
        updated_at
        title
      }
      answers {
        main_reason {
          id
          title
        }
        education_level {
          id
          title
        }
        employment_status {
          id
          title
        }
      }
      seconds_watched
      learning_goal_time_mins
      time_remaining_days
      is_goal_completed
      completed_weekly_goals_in_a_row
    }
  }
`;

export const GET_GOAL_OPTIONS = gql`
  query GetGoalOptions {
    getGoalsOptions {
      categories {
        id
        slug
        title
      }
      options {
        education_level {
          question
          answers {
            id
            title
          }
        }
        employment_status {
          question
          answers {
            id
            title
          }
        }
        main_reason {
          question
          answers {
            id
            title
          }
        }
      }
      timeForLearningGoals {
        value
      }
    }
  }
`;

export const SET_LEARNER_GOALS = gql`
  mutation SetLearnerGoals($input: SetGoalsAnswers!) {
    setGoalsForLearner(input: $input) {
      learning_goal_time_mins
      time_remaining_days
      seconds_watched
      relatable_group_type
      relatable_group_id
    }
  }
`;
