import { useEffect, useId } from 'react';

import { useUtilitiesContext } from '~/context/UtilitiesProvider/UtilitiesProvider';

const useRegisterOverflowHidden = (hidden: boolean) => {
  const tag = useId();
  const { overflowHidden } = useUtilitiesContext();
  const { register, unregister } = overflowHidden;

  useEffect(() => {
    register(tag, hidden);

    return () => unregister(tag);
  }, [register, unregister, hidden, tag]);
};

export default useRegisterOverflowHidden;
