import React, { FC, useCallback, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';

import CloseIcon from '~/assets/icons/CloseIcon';
import CustomButton from '~/components/UI/CustomButton/CustomButton';
import { BodyIntroText, H2 } from '~/components/UI/Texts';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { ModalWrapper } from '~/modals/modalStyles';
import { ModalProps } from '~/modals/types';
import NotificationsRadioGroup from '~/pages/AssignedLearningPage/components/NotificationsRadioGroup/NotificationsRadioGroup';
import SwitcherWithTitle from '~/pages/AssignedLearningPage/components/SwitcherWithTitle/SwitcherWithTitle';
import { checkIsNotificationOff } from '~/pages/AssignedLearningPage/const';
import { LARGE_MARGIN_PX, MIDDLE_MARGIN_PX, SMALL_MARGIN_PX } from '~/theme';
import { NotificationSettings } from '~/types';

export interface NotificationsModalProps {
  notificationSettings: NotificationSettings;
  handleSubmit: (val: NotificationSettings) => Promise<any>;
}

const NotificationsModal: FC<NotificationsModalProps & ModalProps> = ({
  notificationSettings,
  handleSubmit,
  closeModal,
  labelledBy,
}) => {
  const { t } = useTenantTranslation();

  const [notificationsType, setNotificationsType] = useState<NotificationSettings>(
    NotificationSettings.Off,
  );

  const toggleNotifications = useCallback(() => {
    setNotificationsType((prevState) =>
      checkIsNotificationOff(prevState) ? NotificationSettings.Auto : NotificationSettings.Off,
    );
  }, []);

  useEffect(() => {
    setNotificationsType(notificationSettings);
  }, [notificationSettings]);

  const isOff = checkIsNotificationOff(notificationsType);

  return (
    <StyledModalWrapper>
      <StyledCloseIcon onClick={closeModal} />
      <Title id={labelledBy} variant="h2" align="center">
        {t('page.assignedLearnings.notifications')}
      </Title>
      <Description align="center">
        {t('page.assignedLearnings.notificationsDescription')}
      </Description>
      <SwitchBtnWrapper>
        <SwitcherWithTitle
          title={t('page.assignedLearnings.notifications')}
          handleClick={toggleNotifications}
          active={!isOff}
        />
      </SwitchBtnWrapper>
      {notificationsType !== NotificationSettings.Off && (
        <StyledNotificationsRadioGroup
          value={notificationsType}
          handleChange={setNotificationsType}
        />
      )}
      <Buttons>
        <StyledCustomButton
          data-testid="cancelNotificationModal"
          variant="outlined"
          color="secondary"
          onClick={closeModal}
        >
          {t('button.cancel')}
        </StyledCustomButton>
        <StyledCustomButton
          data-testid="submitNotificationModal"
          onClick={async () => {
            await handleSubmit(notificationsType);
            closeModal?.();
          }}
        >
          {t('button.set')}
        </StyledCustomButton>
      </Buttons>
    </StyledModalWrapper>
  );
};

export default NotificationsModal;

const StyledNotificationsRadioGroup = styled(NotificationsRadioGroup)`
  margin-top: ${SMALL_MARGIN_PX};
`;
const StyledModalWrapper = styled(ModalWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${LARGE_MARGIN_PX};
  }
`;
const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  display: block;
  cursor: pointer;
  height: 14px;
  width: 14px;
  top: 16px;
  right: 29px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    top: 29px;
  }
`;
const Title = styled(H2)`
  && {
    margin-bottom: ${SMALL_MARGIN_PX};

    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-bottom: ${MIDDLE_MARGIN_PX};
    }
  }
`;
const Description = styled(BodyIntroText)`
  margin-bottom: ${SMALL_MARGIN_PX};
  color: ${({ theme }) => theme.palette.common.gray};
`;
const SwitchBtnWrapper = styled('div')`
  display: flex;
  justify-content: center;
`;
const Buttons = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${SMALL_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: ${MIDDLE_MARGIN_PX};
  }
`;
const StyledCustomButton = styled(CustomButton)`
  width: 100%;

  &:first-child {
    margin-bottom: ${SMALL_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: unset;

    &:first-child {
      margin-right: ${MIDDLE_MARGIN_PX};
      margin-bottom: 0;
    }
  }
`;
