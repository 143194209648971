import React, { lazy, Suspense, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import CustomPage from '~/blocks/CustomPage/CustomPage';
import NotFoundPage from '~/blocks/NotFoundPage/NotFoundPage';
import Loader from '~/components/Loader/Loader';
import RouteWithCode from '~/components/RouteWithCode/RouteWithCode';
import { DEFAULT_DISCOVER_URL } from '~/const';
import { useSsrContext } from '~/context/SsrContext';
import { GET_PAGE_BY_URL } from '~/graphql/pages';
import { GET_VIDEO_BY_SLUG } from '~/graphql/videos';
import { DISCOVER_SEARCH_ROUTE } from '~/routes';
import { Query, QueryGetPageByUrlArgs, QueryListVideosArgs } from '~/types';

const SingleVideoPage = lazy(() => import('../../pages/SingleVideoPage/SingleVideoPage'));

interface CustomPageLayoutProps {
  bumperUrl?: string | null;
}

const CustomPageLayout: React.FC<CustomPageLayoutProps> = ({ bumperUrl }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname;
  const cleanPathname =
    pathname.length > 1 && pathname[pathname.length - 1] === '/' ? pathname.slice(0, -1) : pathname;
  const slug: string = cleanPathname[0] === '/' ? cleanPathname.slice(1) : cleanPathname;
  const { redirect } = useSsrContext();

  const { data: pageData, loading: pageLoading } = useQuery<
    Pick<Query, 'getPageByUrl'>,
    QueryGetPageByUrlArgs
  >(GET_PAGE_BY_URL, {
    variables: {
      input: {
        url: [cleanPathname],
        include_test_publish_status: true,
      },
    },
    errorPolicy: 'all',
  });

  const page = pageData?.getPageByUrl;
  const blocks = page?.blocks;

  const { data: videoData, loading: videoLoading } = useQuery<
    Pick<Query, 'listVideos'>,
    QueryListVideosArgs
  >(GET_VIDEO_BY_SLUG, {
    variables: {
      filter: {
        video_url: [slug],
      },
    },
    skip: !slug,
  });

  const video = videoData?.listVideos && videoData?.listVideos[0] ? videoData?.listVideos[0] : null;
  const hasBlocks = blocks && blocks.length > 0;

  useEffect(() => {
    const pathname = location?.pathname;
    const queryParams = new URLSearchParams(location?.search);
    const searchValue = queryParams.get('s');
    if (searchValue && !pathname.includes(DEFAULT_DISCOVER_URL)) {
      navigate(`${DISCOVER_SEARCH_ROUTE}/?s=${searchValue}`);
    }
  }, [navigate, pageLoading, videoLoading, video, hasBlocks, location]);

  if (pageLoading || videoLoading) {
    return <Loader />;
  }

  //check which was created earlier if both video and page exists
  const showVideo =
    video &&
    (!hasBlocks ||
      (hasBlocks &&
        new Date(page.created_at || 0).getTime() > new Date(video.created_at || 0).getTime()));

  if (!showVideo && redirect && page?.url && location.pathname !== page?.url) {
    redirect(page?.url || '/');
  }

  if (showVideo) {
    return (
      <Routes>
        <Route
          path="/:slug"
          element={
            <Suspense fallback={<Loader />}>
              <SingleVideoPage isXApiPage={false} bumperUrl={bumperUrl} />
            </Suspense>
          }
        />
      </Routes>
    );
  } else if (hasBlocks) {
    return (
      <CustomPage
        blocks={blocks}
        blocksLoading={pageLoading}
        canonicalUrl={page?.url || undefined}
      />
    );
  } else {
    return (
      <RouteWithCode code={404}>
        <NotFoundPage />
      </RouteWithCode>
    );
  }
};

export default CustomPageLayout;
