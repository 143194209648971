import React, { FC } from 'react';

import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import CheckIcon from '~/assets/icons/CheckIcon';
import { MetaText } from '~/components/UI/Texts';
import { TINY_MARGIN_PX } from '~/theme';

interface CardWithCheckIconProps {
  title?: string | null;
  description?: string | null;
}

const CardWithCheckIcon: FC<CardWithCheckIconProps> = ({ title, description }) => {
  const theme = useTheme();
  return (
    <Wrapper>
      <TitleWrapper>
        <StyledIcon color={theme.palette.common.tabs.indicator} />
        <Title>{title}</Title>
      </TitleWrapper>
      <Description>{description}</Description>
    </Wrapper>
  );
};

export default CardWithCheckIcon;

const Wrapper = styled('div')`
  width: 100%;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const Title = styled(MetaText)`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.common.white};
`;

const StyledIcon = styled(CheckIcon)`
  width: 16px;
  height: 16px;
  margin-right: ${TINY_MARGIN_PX};
`;

const Description = styled(MetaText)`
  color: ${({ theme }) => theme.palette.common.gray};
  margin-top: ${TINY_MARGIN_PX};
  display: none;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
  }
`;
