import React, { FC } from 'react';

import { Element, Svg, useSvgColor } from '~/assets/icons/styles';
import { OneColorIconProps } from '~/assets/icons/types';

const CheckIcon: FC<OneColorIconProps> = ({ color, ...props }) => {
  const svgColor = useSvgColor(color);

  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
      svgColor={svgColor}
    >
      <Element as="polyline" points="2 13 8 19 22 5" />
    </Svg>
  );
};

export default CheckIcon;
