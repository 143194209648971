import React, { FC } from 'react';

import { useTheme } from '@mui/material';

import { Svg } from '~/assets/icons/styles';
import { TempColorIconProps } from '~/assets/icons/types';

const LinkedInIcon: FC<TempColorIconProps> = ({ updatedColor, color = '', ...props }) => {
  const theme = useTheme();
  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      svgColor={updatedColor ? updatedColor : color ? color : theme.palette.common.violet}
      {...props}
    >
      <path
        fill="currentColor"
        data-name="Icon awesome-linkedin"
        d="M22.29,0H1.71A1.72,1.72,0,0,0,0,1.72V22.27A1.73,1.73,0,0,0,1.71,24H22.29A1.73,1.73,0,0,0,24,22.27V1.72A1.73,1.73,0,0,0,22.29,0Zm-15,20.57H3.7V9.12H7.26V20.57Zm-1.78-13A2.07,2.07,0,1,1,7.54,5.49h0A2.07,2.07,0,0,1,5.47,7.56Zm15.12,13H17V15c0-1.33,0-3-1.85-3S13,13.41,13,14.9v5.67H9.49V9.12H12.9v1.56h0a3.75,3.75,0,0,1,3.37-1.85c3.6,0,4.27,2.38,4.27,5.46Z"
      />
    </Svg>
  );
};

export default LinkedInIcon;
