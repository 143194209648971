import React, { FC, useState } from 'react';

import { Container } from '@mui/material';
import { styled } from '@mui/material';
import cn from 'classnames';

import SingleAccordion from '~/blocks/AccordionsBlock/SingleAccordion';
import { H2 } from '~/components/UI/Texts';
import { useTenantContext } from '~/context/TenantProvider';
import { LARGE_MARGIN_PX, MIDDLE_MARGIN_PX, SUBMIDDLE_MARGIN_PX } from '~/theme';
import { Background, Faq, Maybe } from '~/types';
import { TenantsRefs } from '~/utils/tenantsConfig';

interface AccordionsBlockProps {
  data: Faq;
}

const AccordionsBlock: FC<AccordionsBlockProps> = ({ data }) => {
  const { background, heading, questions } = data;
  const { tenant } = useTenantContext();
  const isSSKU = tenant?.name === TenantsRefs.Ssku;

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleSelect = (index) => {
    setSelectedIndex((prevState) => (prevState !== index ? index : -1));
  };

  return (
    <Wrapper
      className={cn(
        background === Background.Dark ? 'dark-block' : 'light-block',
        'accordions-block',
      )}
      background={background}
    >
      <StyledContainer>
        <Title isSSKU={isSSKU} align="center" variant="h2">
          {heading}
        </Title>
        <AccordionsWrapper>
          {Boolean(questions?.length) &&
            questions?.map((item, i) => (
              <SingleAccordion
                key={i}
                id={i}
                title={item?.title}
                description={item?.description}
                isOpened={selectedIndex === i}
                handleOpen={handleSelect}
              />
            ))}
        </AccordionsWrapper>
      </StyledContainer>
    </Wrapper>
  );
};

export default AccordionsBlock;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background?: Maybe<Background> }>`
  padding: ${MIDDLE_MARGIN_PX} 0;
  background: ${({ theme }) => theme.palette.common.blockBackground.main};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    background: ${({ background, theme }) =>
      background === Background.Dark
        ? theme.palette.common.blockBackground.main
        : theme.palette.common.blockBackground.light};
    padding: ${LARGE_MARGIN_PX} 0;
  }
`;
const StyledContainer = styled(Container)`
  && {
    max-width: 1000px;
  }
`;
const Title = styled(H2, {
  shouldForwardProp: (prop) => prop !== 'isSSKU',
})<{ isSSKU: boolean }>`
  && {
    font-size: ${({ isSSKU }) => isSSKU && '56px'};
    color: ${({ theme }) => theme.palette.common.text.title};
    font-family: ${({ theme }) => theme.typography.specialFontFamily};
    margin-bottom: ${MIDDLE_MARGIN_PX};
    text-align: ${({ isSSKU }) => (isSSKU ? 'left' : 'center')};
    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: ${({ isSSKU }) => isSSKU && '128px'};
      margin-bottom: ${SUBMIDDLE_MARGIN_PX};
    }
    ${({ theme }) => theme.breakpoints.up('xl')} {
      margin-left: ${({ isSSKU }) => isSSKU && '-100px'};
    }
  }
`;
const AccordionsWrapper = styled('div')`
  width: 100%;
`;
