import React, { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import Loader from '~/components/Loader/Loader';
import { useTenantContext } from '~/context/TenantProvider';
import useAuth from '~/hooks/useAuth';
import { PRICING_ROUTE } from '~/routes';

const RegistrationPage: FC = () => {
  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated, isLoading, isInitialized } = useAuth();
  const { tenant, config } = useTenantContext();

  useEffect(() => {
    if (isInitialized && !isLoading) {
      if (isAuthenticated) {
        navigate(config?.appMainPage, { replace: true });
      } else {
        loginWithRedirect({
          redirectUri: PRICING_ROUTE,
          mode: 'signUp',
        });
      }
    }
  }, [isInitialized, isLoading, isAuthenticated, loginWithRedirect, navigate, tenant, config]);

  return <Loader />;
};

export default RegistrationPage;
