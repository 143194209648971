export const ANALYTICS_LEARNER_PAGE_EVENTS = {
  allTimeFilter: 'Analytics_Learner_filter',
  certificateAddToProfile: 'Analytics_Learner_myCert_Addtoprofile',
  certificateDownload: 'Analytics_Learner_myCert_dload',
  certificateShare: 'Analytics_Learner_myCert_Share',
  certShareLi: 'Analytics_Learner_myCert_LI',
  certShareTeams: 'Analytics_Learner_myCert_Teams',
  certShareFb: 'Analytics_Learner_myCert_fbook',
  certShareX: 'Analytics_Learner_myCert_X',
  removeFromWatchList: 'Analytics_Learner_remove_wlist',
};
export const ANALYTICS_MANAGER_PAGE_EVENTS = {
  allTeamsFilter: 'Analytics_Manager_filter_team',
  allLearnerFilter: 'Analytics_Manager_filter_learner',
  allTimeFilter: 'Analytics_Manager_filter_time',
  byTeamDownload: 'Analytics_Manager_data_team_dload',
  byLearnerDownload: 'Analytics_Manager_data_learner_dload',
  byPathwayDownload: 'Analytics_Manager_data_pway_dload',
  byPathwayAdvanced: 'Analytics_Manager_data_pway_adv',
  byPathwayViewDetails: 'Analytics_Manager_data_pway_view',
  byVideoDownload: 'Analytics_Manager_data_vid_dload',
  byVideoAdvanced: 'Analytics_Manager_data_vid_adv',
  byVideoViewDetails: 'Analytics_Manager_data_vid_view',
};
