import React, { useRef, useState } from 'react';

import { styled } from '@mui/material/styles';
import { useInView } from 'framer-motion';

import IKImage from '~/components/IKImage/IKImage';
import { CssUtilityType } from '~/styled';
import { Maybe } from '~/types';

interface LazyLoadImageProps {
  offset?: number;
  placeholderQuality?: number;
  placeholderBlur?: number;
  path?: Maybe<string>;
  transformation?: any[] | null;
  imageWrapperClass?: string;
  wrapperCss?: CssUtilityType;
  objectFitPolyfill?: string;

  [key: string]: any;
}

const LazyLoadImage: React.FC<LazyLoadImageProps> = ({
  offset = 300,
  placeholderQuality = 10,
  placeholderBlur = 10,
  path,
  transformation = null,
  srcset = [],
  imageWrapperClass,
  wrapperCss,
  sizes = null,
  objectFitPolyfill,
  ...props
}) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inView = useInView(wrapperRef, { once: true, margin: `${offset}px 0px 0px 0px` });

  const updatedSrcset = srcset.map((item) => ({
    ...item,
    quality: placeholderQuality,
    blur: placeholderBlur,
  }));

  const removePlaceholder = () => {
    setShowPlaceholder(false);

    if (window?.objectFitPolyfill && objectFitPolyfill) {
      window.objectFitPolyfill();
    }
  };

  if (!path) return null;

  return (
    <Wrapper ref={wrapperRef} className={imageWrapperClass} optionalStyles={wrapperCss}>
      {showPlaceholder && (
        <IKImage
          path={path}
          transformation={transformation}
          srcset={updatedSrcset}
          sizes={sizes}
          {...props}
        />
      )}
      {inView && (
        <MainImage
          path={path}
          transformation={transformation}
          srcset={srcset}
          sizes={sizes}
          onLoad={removePlaceholder}
          onError={removePlaceholder}
          data-object-fit={objectFitPolyfill}
          hidden={showPlaceholder}
          {...props}
        />
      )}
    </Wrapper>
  );
};

export default LazyLoadImage;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})<{ optionalStyles?: CssUtilityType }>`
  ${({ optionalStyles }) => optionalStyles}
`;
const MainImage = styled(IKImage, {
  shouldForwardProp: (prop) => prop !== 'hidden',
})<{ hidden: boolean }>`
  display: ${({ hidden }) => hidden && 'none'};
`;
