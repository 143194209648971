import React from 'react';

import { useTheme } from '@mui/material';

import { Svg } from '~/assets/icons/styles';

const TwitterIcon = ({ color = '', ...props }) => {
  const theme = useTheme();
  const svgColor = color || theme.palette.common.violet;
  return (
    <Svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      svgColor={svgColor}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4286 0H2.57143C1.15178 0 0 1.15178 0 2.57142V21.4286C0 22.8482 1.15178 24 2.57143 24H21.4286C22.8482 24 24 22.8482 24 21.4286V2.57142C24 1.15178 22.8482 0 21.4286 0ZM13.524 10.7757L13.524 10.7757L19.9999 19.9999H20L13.524 10.7757ZM4.00015 19.9999L10.2469 12.8954L10.2468 12.8953L4 19.9999H4.00015ZM19.4808 4.0001H18.0695L12.8947 9.88206L8.76467 4.0001H4.00011L4 3.99994H8.76466L12.8947 9.8819L18.0695 3.99994H19.481L19.4808 4.0001ZM8.94286 3.65709L12.925 9.32841L17.9145 3.65709H20.2389L13.9596 10.7993L20.6596 20.3428H15.0571L10.8426 14.3406L5.56653 20.3428H3.242L9.81122 12.8715L3.34027 3.65709H8.94286ZM17.4024 18.6667H16.0771L6.58689 5.38399H7.91227L11.875 10.9289L12.5062 11.8152L17.4024 18.6667ZM18.0687 19.0095H15.9006L15.7015 18.7308L15.9006 19.0097H18.0689L18.0687 19.0095ZM6.24062 5.4893L5.92053 5.0413H5.92042L6.24062 5.4893Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default TwitterIcon;
