import React, { FC, useCallback, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { useTheme, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';

import VideoPlayer from '~/components/VideoPlayer/VideoPlayer';
import useVerse from '~/hooks/useVerse';
import { ModalProps } from '~/modals/types';
import { css, CssUtilityType } from '~/styled';

interface IVideoModal {
  verseId: string;
  url: string;
  bumperUrl: string;
  duration: string;
  title: string;
  expertName: string;
}

const VideoModal: FC<IVideoModal & ModalProps> = ({
  verseId,
  url = '',
  bumperUrl,
  duration,
  title,
  expertName,
  closeModal,
}) => {
  const theme = useTheme();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { onVideoClose } = useVerse();

  const closeModalHandler = () => {
    onVideoClose();
    closeModal?.();
  };

  useEffect(() => {
    const escHandler = (e) => {
      if (e.key === 'Escape') {
        onVideoClose();
        closeModal?.();
      }
    };

    if (typeof document !== 'undefined') {
      document.body.addEventListener('keydown', escHandler, false);

      return () => document.body.removeEventListener('keydown', escHandler, false);
    }
  }, [onVideoClose, closeModal]);

  const onToggleFullscreen = useCallback((isFullscreen) => {
    setIsFullScreen(isFullscreen);
  }, []);

  return (
    <Container>
      <ModalContent optionalStyles={isFullScreen ? generatedModalContent : undefined}>
        <CloseBtn className={'close-btn'} onClick={closeModalHandler}>
          <CloseIcon style={{ color: theme.palette.common.white }} />
        </CloseBtn>
        <StyledVideoPlayer
          verseId={verseId}
          videoUrl={url}
          bumperVideoUrl={bumperUrl}
          duration={Number(duration)}
          title={title}
          expertName={expertName}
          onToggleFullscreen={onToggleFullscreen}
          isFullScreen={isFullScreen}
        />
      </ModalContent>
    </Container>
  );
};

export default VideoModal;

const Container = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  width: 80vw;
  height: calc(70vh + 35px);
  padding-top: 35px;
`;
const ModalContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})<{ optionalStyles?: CssUtilityType }>`
  border-radius: 5px;
  width: 100%;
  height: 100%;
  text-align: center;

  ${({ optionalStyles }) => optionalStyles}
`;
const generatedModalContent = css`
  top: 0;
  left: 0;
  transform: none;
  width: 100vw;
  height: 100vh;
`;
const CloseBtn = styled('div')`
  position: absolute;
  cursor: pointer;
  z-index: 20;
  padding: 3px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${({ theme }) => alpha(theme.palette.common.white, 0.33)};
  top: 0;
  right: 0;
`;
const StyledVideoPlayer = styled(VideoPlayer)`
  width: 100%;
  height: 100%;
`;
