import React, { createContext, useContext, useState } from 'react';

type stateType = {
  rxdUserInfo: any;
  setRxdUserInfo: Function;
};

const STATE: stateType = {
  rxdUserInfo: null,
  setRxdUserInfo: () => {},
};

export const RXDContext = createContext(STATE);

export function useRXDContext() {
  const context = useContext(RXDContext);

  if (!context) {
    throw new Error('useRXDContext must be used within an RXDController');
  }

  return context;
}

export const RXDController = ({ children }) => {
  const [rxdUserInfo, setRxdUserInfo] = useState(null);

  return (
    <RXDContext.Provider
      value={{
        rxdUserInfo,
        setRxdUserInfo,
      }}
    >
      {children}
    </RXDContext.Provider>
  );
};
