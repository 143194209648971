import { TFunction } from 'i18next';

import { multiLangCharRegexp } from '~/utils/regexps';
import { createSchema, createValidation } from '~/utils/validator';

export const teamNameFormSchema = (t: TFunction) =>
  createSchema({
    name: createValidation()
      .required(t('error.fieldRequired'))
      .match(multiLangCharRegexp, t('error.incorrectCompanyName')),
  });
