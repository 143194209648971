import { useEffect } from 'react';

import { useLocation, Location, useNavigate } from 'react-router-dom';

import {
  ONLINE_CONTENT_CONNECTOR_ATTRIBUTES,
  useOnlineContentConnector,
} from '~/context/OnlineContentConnectorContext';
import usePrevious from '~/hooks/usePrevious';

const useOnlineContentConnectorParams = () => {
  const location = useLocation();
  const prevLocation = usePrevious<Location<any>>(location);
  const navigate = useNavigate();

  const { updateData } = useOnlineContentConnector();

  useEffect(() => {
    if (prevLocation) {
      const isDifferentRoute =
        prevLocation.search !== location.search || prevLocation.pathname !== location.pathname;
      let hadParams = false;
      let hasParams = false;

      ONLINE_CONTENT_CONNECTOR_ATTRIBUTES.forEach((item) => {
        if (prevLocation.search.includes(item)) {
          hadParams = true;
        }
        if (location.search.includes(item)) {
          hasParams = true;
        }
      });

      if (hadParams && !hasParams && isDifferentRoute) {
        const prevSearchParams = new URLSearchParams(prevLocation.search);
        const currSearchParams = new URLSearchParams(location.search);

        ONLINE_CONTENT_CONNECTOR_ATTRIBUTES.forEach((item) => {
          let param = prevSearchParams.get(item);
          if (param) {
            currSearchParams.append(item, param);
          }
        });

        const stringifiedParams = currSearchParams.toString();
        navigate(location.pathname + stringifiedParams ? '?' + stringifiedParams : '', {
          replace: true,
        });
      }
    }
  }, [location, prevLocation, navigate]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    const onlineContentConnectorData = {};
    ONLINE_CONTENT_CONNECTOR_ATTRIBUTES.forEach((key) => {
      const val = searchParams.get(key);
      if (val) {
        onlineContentConnectorData[key] = val;
      }
    });

    if (Object.keys(onlineContentConnectorData).length > 0) {
      updateData(onlineContentConnectorData);
    }
  }, [location, updateData]);
};

export default useOnlineContentConnectorParams;
