import { hostToTenantName } from '~/utils/hostToTenantName/hostToTenantName';
import { TENANTS_CONFIG, TenantsRefs } from '~/utils/tenantsConfig';

const getApiUrl = (host = '') => {
  const tenant = hostToTenantName(window?.location?.hostname || host);

  return TENANTS_CONFIG[tenant]?.apiUrl || TENANTS_CONFIG[TenantsRefs.Xunl].apiUrl;
};

export default getApiUrl;
