import React, { FC, ReactNode } from 'react';

import { styled } from '@mui/material';

import { CssUtilityType } from '~/styled';

interface IFlipper {
  // require 2 children elements
  children: ReactNode;
  className?: string;
}

const Flipper: FC<IFlipper> = ({ children, className }) => {
  return (
    <Scene className={className}>
      <Flip>{children}</Flip>
    </Scene>
  );
};

export default Flipper;

const Scene = styled('div', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})<{ optionalStyles?: CssUtilityType }>`
  perspective: 600px;

  &:hover {
    & > * {
      transform: rotateY(180deg);
    }
  }

  ${({ optionalStyles }) => optionalStyles}
`;
const Flip = styled('div')`
  height: 100%;
  transition: transform 0.8s;
  transition-delay: 250ms;
  transform-style: preserve-3d;

  & > * {
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
  }

  & > *:last-child {
    transform: rotateY(180deg);
  }
`;
