import { gql } from '@apollo/client';

export const GET_PATHWAY_PAGE_INFO = gql`
  query GetPathwayPageInfo($filter: PathwaySearchFilter) {
    getPathwayPageInfo(filter: $filter) {
      pathway {
        id
        name
        description
        marketing_description
        count_videos
        duration
        slug
        cpd_credits
        image_url
        background_image_url
        foreground_image_url
        foreground_image_seo_name
        logo_svg
        who_is_this_for
        intro_verse_story_id
        skills {
          id
          title
          slug
        }
        impacts {
          id
          title
          description
        }
        key_learning_objectives {
          id
          title
        }
        difficulty {
          title
          icon_url
          id
        }
        categories {
          id
          title
          slug
          parent {
            slug
            id
          }
        }
        videos {
          id
          title
          description
          duration
          preview_url
          video_url
          free_for_all
          skills {
            id
            title
            slug
          }
          difficulty {
            id
            icon_url
            title
          }
          categories {
            id
            title
            slug
            parent {
              slug
              id
            }
          }
          user {
            id
            name
            slug
            avatar
            job_title
            description
          }
        }
        quizes {
          id
          title
          stars
        }
        subtitles_codes
        reviews_score
        tenants {
          id
          name
        }
      }
    }
  }
`;

export const GET_VIDEOS_EXIST_IN_USER_WATCH_LIST = gql`
  query GetVideosExistInUserWatchList($video_ids: [ID]) {
    VideosExistInUserWatchList(video_ids: $video_ids) {
      video_id
      exists
    }
  }
`;

export const GET_PATHWAY_PAGE_PROGRESS = gql`
  query GetPathwayPageProgress($filter: PathwaySearchFilter) {
    getPathwayLearnerPageInfo(filter: $filter) {
      pathway {
        id
        completion_date
        available_quizes {
          count_of_questions
          description
          id
          location
          percentage_complete
          stars
          time_for_pass
          title
          stars
          questions {
            blooms_taxonomy_type
            title
            frontend_type
            id
            answers {
              id
              title
            }
          }
        }
      }
      progress {
        video {
          id
          duration
          video_url
          new
        }
        last_position
        chapter
        time
        progress_percentage
        completed
      }
    }
  }
`;

export const GET_PATHWAY_INFO_FOR_TEST = gql`
  query GetPathwayInfoForTest($filter: PathwaySearchFilter) {
    getPathwayPageInfo(filter: $filter) {
      pathway {
        id
        name
        slug
        cpd_credits
        logo_svg
        image_url
      }
    }
  }
`;

export const GET_PATHWAY_PROGRESS_FOR_TEST = gql`
  query GetPathwayProgressForTest($filter: PathwaySearchFilter) {
    getPathwayLearnerPageInfo(filter: $filter) {
      pathway {
        id
        available_quizes {
          count_of_questions
          description
          id
          location
          percentage_complete
          stars
          time_for_pass
          title
          percentage_complete
          stars
          questions {
            blooms_taxonomy_type
            title
            frontend_type
            id
            image
            answers {
              id
              title
            }
          }
        }
      }
    }
  }
`;

export const GET_PATHWAYS_YOU_MIGHT_ALSO_LIKE = gql`
  query listYouMightAlsoLikePathways($filter: PathwaySearchFilter) {
    listYouMightAlsoLikePathways(filter: $filter) {
      id
      name
      image_url
      duration
      count_videos
      slug
    }
  }
`;

export const CHECK_QUIZ_ANSWERS = gql`
  mutation CheckQuizAnswers($input: PassedQuizInfo) {
    check_quiz_answers(input: $input) {
      hash
      date_passing
      percentage_complete
      count_of_questions
      count_correct_answers
      pathway {
        certificate_id
      }
      passed_questions_answers {
        question {
          id
          title
        }
        correct
      }
    }
  }
`;

export const CHECK_N_RECORD_VIDEO_QUESTION_ANSWERS = gql`
  mutation CheckNRecordVideoQuestionAnswers($input: SubmitVideoQuestionAnswersInfo) {
    check_n_record_video_question_answers(input: $input) {
      passed_questions_answers {
        question {
          id
        }
        correct
        correct_answers {
          order
          id
          correct
        }
      }
    }
  }
`;

export const GET_PASSED_QUIZES_RESULTS_BY_HASH = gql`
  query GetPassedQuizesResultsByHash($hash: String!) {
    GetPassedQuizesResultsByHash(hash: $hash) {
      passed
      pathway {
        id
        slug
        name
        description
      }
    }
  }
`;
export const GET_CERTIFICATE_PATHWAY_BY_HASH = gql`
  query GetCertificatePathwayByHash($hash: String!) {
    GetPassedQuizesResultsByHash(hash: $hash) {
      learner {
        id
        first_name
        last_name
      }
      pathway {
        id
        name
        description
        certificate_id
        completion_date
        marketing_description
        count_videos
        duration
        slug
        cpd_credits
        image_url
        background_image_url
        reviews_score
        logo_svg
        key_learning_objectives {
          id
          title
        }
        difficulty {
          title
          icon_url
          id
        }
        quizes {
          id
          title
          stars
        }
        videos {
          user {
            id
            name
            slug
            avatar
            job_title
            description
          }
        }
      }
    }
  }
`;

export const GET_PASSED_QUIZES_BY_PATHWAY = gql`
  query GetPassedQuizesResults($quiz_ids: [Int]!, $pathway_id: Int!) {
    GetPassedQuizesResults(quiz_ids: $quiz_ids, pathway_id: $pathway_id) {
      date_passing
      count_correct_answers
      count_of_questions
      percentage_complete
      passed
      hash
    }
  }
`;

export const GET_PATHWAYS_LIST = gql`
  query GetPathwaysList {
    listPathways {
      id
      name
      description
      count_videos
      cpd_credits
      duration
      slug
      image_url
      categories {
        id
        title
        slug
        parent {
          slug
          id
        }
      }
      difficulty {
        id
        icon_url
        title
      }
    }
  }
`;

export const GET_DISCOVER_PATHWAYS_DETAILED_INFO = gql`
  query GetDiscoverPathwaysDetailedInfo($pathway_ids: [Int]!) {
    get_discover_pathways_detailed_info(pathway_ids: $pathway_ids) {
      id
      count_watched_videos
      passed_quizes {
        id_passed_quiz
        hash
        date_passing
        percentage_complete
        stars
      }
      not_passed_quizes {
        id
      }
    }
  }
`;

export const GET_LEARNER_REVIEW_ON_ENTITY = gql`
  query GetLearnerReviewOnEntity($entity_id: Int!, $entity_type: ReviewableType!) {
    getLearnerReviewOnEntity(entity_id: $entity_id, entity_type: $entity_type) {
      status
    }
  }
`;

export const GET_CASE_STUDY_FOR_PATHWAY = gql`
  query GetCaseStudyForPathway($id: Int) {
    getCaseStudiesForPathway(id: $id) {
      description
      id
      location
      percentage_complete
      title
      image_url
      long_description
      key_learning_objectives {
        title
      }
      questions {
        id
        frontend_type
        title
        description
        answers {
          id
          title
        }
      }
    }
  }
`;
export const GET_CASE_STUDY_FOR_PATHWAYS = gql`
  query GetCaseStudyForPathways($ids: [Int!]) {
    getCaseStudiesForPathways(ids: $ids) {
      case_studies {
        description
        id
        location
        percentage_complete
        title
        image_url
        long_description
        questions {
          id
          frontend_type
          title
          description
          answers {
            id
            title
          }
        }
      }
      pathway {
        id
      }
    }
  }
`;

export const GET_CASE_STUDIES_ATTEMPTS = gql`
  query GetCaseStudiesAttempts($pathway_ids: [Int!]) {
    getCaseStudiesAttempts(pathway_ids: $pathway_ids) {
      case_study_id
      passed
    }
  }
`;

export const CHECK_CASE_STUDY_ONE_ANSWER = gql`
  mutation CheckCaseStudyOneAnswer($input: PassedCaseStudyInfo) {
    checkCaseStudyOneAnswer(input: $input) {
      correct
      correct_answers {
        correct
        id
        order
      }
    }
  }
`;

export const CHECK_CASE_STUDY_ANSWERS = gql`
  mutation CheckCaseStudyAnswers($input: PassedCaseStudyInfo) {
    checkCaseStudyAnswers(input: $input) {
      passed
      passed_questions_answers {
        correct
      }
      questions {
        id
        frontend_type
        answers {
          id
          correct
          order
        }
      }
    }
  }
`;

export const GET_PATHWAY_CERTIFICATE = gql`
  query GetPathwayCertificate($hash: String!) {
    getPathwayCertificate(hash: $hash)
      @rest(path: "/certificates/share/pathways/{args.hash}", type: "PathwayCertificate") {
      learner {
        id
        first_name
        last_name
      }
      pathway {
        id
        slug
        name
        completion_date
        videos
        marketing_description
        description
        logo_svg
        image_url
        cpd_credits
        certificate_id
        duration
        count_videos
        key_learning_objectives
        reviews_score
        difficulty
      }
    }
  }
`;
