import React, { forwardRef, useMemo } from 'react';

import { Box, Typography, useTheme, styled } from '@mui/material';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

import FacebookIcon from '~/assets/icons/FacebookIcon';
import LinkedInIcon from '~/assets/icons/LinkedInIcon';
import MicrosoftTeamsIcon from '~/assets/icons/MicrosoftTeamsIcon';
import TwitterIcon from '~/assets/icons/TwitterIcon';
import ImageWithLoader from '~/components/ImageWithLoader/ImageWithLoader';
import CardWrapper from '~/components/UI/CardWrapper/CardWrapper';
import ShareButton from '~/components/UI/ShareButton/ShareButton';
import { useTenantContext } from '~/context/TenantProvider';
import useShareTeams from '~/hooks/useShareTeams';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { StyledCloseIcon } from '~/modals/modalStyles';
import { ModalProps } from '~/modals/types';
import { ANALYTICS_LEARNER_PAGE_EVENTS } from '~/pages/AnalyticsPage/gtmEvents';
import { PATHWAY_PAGE_EVENTS } from '~/pages/PathwayPage/const';
import { COURSE_PAGE_EVENTS } from '~/pages/courses/const';
import { buildCoursesShareRoute } from '~/routes';
import { css } from '~/styled';
import { SMALL_MARGIN_PX } from '~/theme';
import { getCourseSocialShareImage, getPathwaySocialShareImage } from '~/utils/certificateUtils';
import assertNever from '~/utils/errors/assertNever';
import sendAnalyticEvent from '~/utils/sendAnalyticEvent';
import { TENANTS_CONFIG } from '~/utils/tenantsConfig';
import { translate } from '~/utils/translate';

export enum ShareType {
  Pathway = 'pathway',
  Course = 'course',
  Analytics = 'analytics',
}

type GenerateShareDataInput = {
  type: ShareType;
  resultHash?: string | null;
  tenantName?: string | null;
  entityName?: string | null;
  cpdCredits?: string | null;
  category?: string | null;
  fbName?: string | null;
  twitterHandle?: string | null;
  tenantTitle?: string | null;
};

const generateShareData = ({
  type,
  resultHash,
  tenantName,
  entityName,
  cpdCredits,
  category,
  fbName,
  twitterHandle,
  tenantTitle,
}: GenerateShareDataInput) => {
  const defaultShareText = translate(
    `page.pathway.${TENANTS_CONFIG[tenantName || '']?.pathwayCertShareDefaultTextKey}`,
    { title: tenantTitle },
  );

  switch (type) {
    case ShareType.Pathway: {
      const shareText =
        entityName && cpdCredits
          ? translate('page.test.shareText', { pathwayName: entityName, cpdCredits })
          : defaultShareText;
      const shareTwitterText =
        entityName && category
          ? translate('page.test.shareTwitterText', {
              pathwayName: entityName,
              twitterHandle,
              category,
            })
          : defaultShareText;
      const shareFacebookText =
        entityName && category
          ? translate('page.test.shareFacebookText', { pathwayName: entityName, fbName, category })
          : defaultShareText;

      return {
        shareLink: `${window?.location?.origin}/share/${resultHash}`,
        socialShareImgUrl: getPathwaySocialShareImage(resultHash, tenantName),
        shareText,
        shareTwitterText,
        shareFacebookText,
      };
    }
    case ShareType.Course: {
      const shareText = entityName
        ? translate('page.course.shareText', { courseName: entityName })
        : '';
      const shareTwitterText = entityName
        ? translate('page.course.shareTwitterText', {
            courseName: entityName,
            twitterHandle,
            category,
          })
        : '';
      const shareFacebookText = entityName
        ? translate('page.course.shareFacebookText', { courseName: entityName, fbName })
        : '';

      return {
        shareLink: `${window?.location?.origin}${buildCoursesShareRoute(resultHash)}`,
        socialShareImgUrl: getCourseSocialShareImage(resultHash, tenantName),
        shareText,
        shareTwitterText,
        shareFacebookText,
      };
    }
    case ShareType.Analytics: {
      const shareText =
        entityName && cpdCredits
          ? translate('page.test.shareText', { pathwayName: entityName, cpdCredits })
          : defaultShareText;
      const shareTwitterText =
        entityName && category
          ? translate('page.test.shareTwitterText', {
              pathwayName: entityName,
              twitterHandle,
              category,
            })
          : defaultShareText;
      const shareFacebookText =
        entityName && category
          ? translate('page.test.shareFacebookText', { pathwayName: entityName, fbName, category })
          : defaultShareText;

      return {
        shareText,
        shareFacebookText: shareFacebookText,
        shareTwitterText: shareTwitterText,
        shareLink: `${window?.location?.origin}/share/${resultHash}`,
        socialShareImgUrl: getPathwaySocialShareImage(resultHash, tenantName),
      };
    }
  }
};

const formatHashTags = (hashtags?: (string | null)[] | null): string[] => {
  const arr = (hashtags ? hashtags.filter((hashtag) => Boolean(hashtag)) : []) as string[];

  return arr.map((hashtag) => (hashtag?.startsWith('#') ? hashtag?.substring(1) : hashtag));
};

export interface ShareModalProps {
  resultHash?: string | null;
  entityName?: string | null;
  cpdCredits?: string | null;
  category?: string | null;
  type?: ShareType;
}

const getAnalyticsTrackingHandlers = (type: ShareType) => {
  let events: Record<'certShareLi' | 'certShareTeams' | 'certShareFb' | 'certShareX', string>;
  switch (type) {
    case ShareType.Pathway:
      events = PATHWAY_PAGE_EVENTS;
      break;
    case ShareType.Course:
      events = COURSE_PAGE_EVENTS;
      break;
    case ShareType.Analytics:
      events = ANALYTICS_LEARNER_PAGE_EVENTS;
      break;
    default:
      return assertNever(type);
  }

  return {
    li: () => sendAnalyticEvent(events.certShareLi),
    ms: () => sendAnalyticEvent(events.certShareTeams),
    fb: () => sendAnalyticEvent(events.certShareFb),
    x: () => sendAnalyticEvent(events.certShareX),
  };
};

const ShareModal = forwardRef<HTMLDivElement, ShareModalProps & ModalProps>(
  (
    {
      closeModal,
      resultHash,
      entityName,
      cpdCredits,
      category,
      type = ShareType.Pathway,
      labelledBy,
    },
    ref,
  ) => {
    const theme = useTheme();
    const { t } = useTenantTranslation();
    const { tenant } = useTenantContext();

    const tenantName = tenant?.name;
    const tenantTitle = tenant?.title;
    const fbName = tenant?.fb_name;
    const twitterHandle = tenant?.twitter_handle;
    const twitterHashtags = tenant?.twitter_hashtags_array;

    const { handleTeamsShare, teamsShareRef } = useShareTeams();
    const formattedHashtags = useMemo(() => formatHashTags(twitterHashtags), [twitterHashtags]);

    const { shareLink, socialShareImgUrl, shareText, shareFacebookText, shareTwitterText } =
      useMemo(
        () =>
          generateShareData({
            type,
            resultHash,
            tenantName,
            cpdCredits,
            category,
            entityName,
            fbName,
            twitterHandle,
            tenantTitle,
          }),
        [
          type,
          resultHash,
          tenantName,
          entityName,
          category,
          cpdCredits,
          fbName,
          twitterHandle,
          tenantTitle,
        ],
      );

    const analyticsHandlers = getAnalyticsTrackingHandlers(type);

    return (
      <StyledCardWrapper ref={ref} data-testid="shareModal">
        <HeaderWrapper>
          <Title id={labelledBy} variant="h2">
            {t('page.test.shareYourSuccess')}
          </Title>
          <StyledCloseIcon
            data-testid="shareModalClose"
            color={theme.palette.common.gray}
            onClick={closeModal}
          />
        </HeaderWrapper>
        <Content>
          <CertificateImgWrapper>
            <ImageWithLoader
              ratio={52}
              imageCss={imageCss}
              imgWrapperCss={certificateImgWrapperWithBg}
              path={socialShareImgUrl}
              alt={t('page.test.share')}
              srcset={[{ width: 750 }]}
              sizes="100vw"
              data-testid="certificateImg"
            />
          </CertificateImgWrapper>
          <ButtonsWrapper>
            <StyledLinkedinShareButton
              title={shareText}
              url={shareLink}
              resetButtonStyle={true}
              data-testid="linkedinShare"
              onClick={analyticsHandlers?.li}
            >
              <StyledShareButton>
                <LinkedInIcon color={theme.palette.common.swiper.buttonBorder} />
                {t('page.test.linkedIn')}
              </StyledShareButton>
            </StyledLinkedinShareButton>
            <StyledShareButton
              data-testid="shareMSTeams"
              onClick={() => {
                handleTeamsShare();
                analyticsHandlers?.ms();
              }}
            >
              <MicrosoftTeamsIcon color={theme.palette.common.swiper.buttonBorder} />
              {t('page.test.microsoftTeams')}
            </StyledShareButton>
            <FacebookShareButton
              data-testid="facebookShare"
              quote={shareFacebookText}
              url={shareLink}
              resetButtonStyle={true}
              onClick={analyticsHandlers?.fb}
            >
              <StyledShareButton>
                <FacebookIcon color={theme.palette.common.swiper.buttonBorder} />
                {t('page.test.facebook')}
              </StyledShareButton>
            </FacebookShareButton>
            <StyledTwitterShareButton
              data-testid="twitterShare"
              title={shareTwitterText}
              url={shareLink}
              resetButtonStyle={true}
              hashtags={formattedHashtags}
              onClick={analyticsHandlers?.x}
            >
              <StyledShareButton>
                <TwitterIcon color={theme.palette.common.swiper.buttonBorder} />
                {t('page.test.twitter')}
              </StyledShareButton>
            </StyledTwitterShareButton>
            <TeamsShareOriginal
              className="teams-share-button"
              data-href={shareLink}
              data-msg-text={shareText}
              ref={teamsShareRef}
            />
          </ButtonsWrapper>
        </Content>
      </StyledCardWrapper>
    );
  },
);

export default ShareModal;

const certificateImgWrapperWithBg = css`
  border-radius: 5px;
`;
const StyledCardWrapper = styled(CardWrapper)`
  width: 100%;
  background: ${({ theme }) => theme.palette.common.answerItem};

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 900px;
  }

  &:focus {
    outline: none;
  }
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${SMALL_MARGIN_PX};
`;
const Title = styled(Typography)`
  font-size: 24px;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 28px;
  }
`;
const Content = styled(Box)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;
const CertificateImgWrapper = styled('div')`
  flex: 1;
  position: relative;
  margin-bottom: ${SMALL_MARGIN_PX};
  display: flex;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-right: ${SMALL_MARGIN_PX};
    margin-bottom: 0;
  }
`;
const imageCss = css`
  border-radius: 5px;
  width: 100%;
`;
const ButtonsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  & button,
  & a {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const createStyledShareButtonWrapper = (tag) => styled(tag)`
  &:focus {
    outline: none;
  }
`;
const StyledLinkedinShareButton = createStyledShareButtonWrapper(LinkedinShareButton);
const StyledTwitterShareButton = createStyledShareButtonWrapper(TwitterShareButton);
const StyledShareButton = styled(ShareButton)`
  cursor: pointer;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const TeamsShareOriginal = styled('div')`
  display: none;
`;
