import React, { createContext, useContext, useMemo } from 'react';

import useOverflowHidden from '~/context/UtilitiesProvider/hooks/useOverflowHidden';
import { throwOnlyInDev } from '~/util/throwOnlyInDev';

const UtilitiesCtx = createContext<{
  overflowHidden: ReturnType<typeof useOverflowHidden>;
}>({
  overflowHidden: {
    register: () => {
      throwOnlyInDev(new Error('register called before initialisation'));
    },
    unregister: () => {
      throwOnlyInDev(new Error('unregister called before initialisation'));
    },
  },
});

export const useUtilitiesContext = () => {
  const context = useContext(UtilitiesCtx);

  if (!context) {
    throw new Error('useUtilitiesContext must be used within a UtilitiesProvider');
  }

  return context;
};

const UtilitiesProvider = ({ children }) => {
  const overflowHidden = useOverflowHidden();
  const memoizedValue = useMemo(
    () => ({
      overflowHidden,
    }),
    [overflowHidden],
  );

  return <UtilitiesCtx.Provider value={memoizedValue}>{children}</UtilitiesCtx.Provider>;
};

export default UtilitiesProvider;
