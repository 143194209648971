import React, { FC, useMemo, useRef, useState } from 'react';

import { ClickAwayListener } from '@mui/material';
import { alpha, css, styled } from '@mui/material/styles';

import ChevronTopIcon from '~/assets/icons/ChevronTopIcon';
import OnlineSmallIcon from '~/assets/icons/OnlineSmallIcon';
import HeaderDropdown from '~/components/HeaderDropdown';
import { getLangOptions } from '~/components/LangWidget/const';
import { createDropdownLinkStyles } from '~/components/LangWidget/styles';
import { DEFAULT_LOCALE } from '~/const';
import { useLanguageContext } from '~/context/LanguageProvider';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { SMALL_MARGIN_PX, TINY_MARGIN_PX } from '~/theme';

interface ILangWidget {
  className?: string;
  arrowPlacementBottom?: boolean;
  abbr?: boolean;
}

const LangWidget: FC<ILangWidget> = ({ className, arrowPlacementBottom = false, abbr = false }) => {
  const { t } = useTenantTranslation();
  const [opened, setOpened] = useState(false);
  const wrapperRef = useRef(null);
  const { language, changeLanguage } = useLanguageContext();
  const langOptions = useMemo(() => getLangOptions(t), [t]);

  const selectedOpt = langOptions.find((opt) =>
    language ? opt.value === language : opt.value === DEFAULT_LOCALE,
  );

  return (
    <ClickAwayListener onClickAway={() => setOpened(false)}>
      <Wrapper
        data-testid="langWidget"
        className={className}
        onClick={() => setOpened((old) => !old)}
        ref={wrapperRef}
      >
        <StyledOnlineSmallIcon color={'inherit'} />
        <Caption data-testid="langCaption" bold={abbr}>
          {selectedOpt?.[abbr ? 'abbr' : 'label']}
        </Caption>
        <StyledChevronTopIcon color="inherit" toTop={opened} />
        <HeaderDropdown
          opened={opened}
          anchorElRef={wrapperRef}
          arrowPlacementBottom={arrowPlacementBottom}
          arrowCss={dropdownArrowCss}
        >
          <Dropdown>
            {langOptions.map((opt) => (
              <ListItem
                data-testid="langOption"
                key={opt.value}
                onClick={() => changeLanguage(opt.value)}
              >
                {opt.label}
              </ListItem>
            ))}
          </Dropdown>
        </HeaderDropdown>
      </Wrapper>
    </ClickAwayListener>
  );
};

export default LangWidget;

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const StyledOnlineSmallIcon = styled(OnlineSmallIcon)`
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: ${TINY_MARGIN_PX};
`;
const StyledChevronTopIcon = styled(ChevronTopIcon, {
  shouldForwardProp: (prop) => prop !== 'toTop',
})`
  width: 10px;
  min-width: 10px;
  height: 10px;
  transform: rotate(${({ toTop }) => (toTop ? 0 : 180)}deg);
  margin-left: ${TINY_MARGIN_PX};
  transition: 0.3s;

  & > * {
    stroke-width: 4;
  }
`;
const ListItem = styled(createDropdownLinkStyles('div'))`
  position: relative;
  z-index: 1;

  &:hover {
    background: ${({ theme }) =>
      alpha(theme.mode === 'dark' ? theme.palette.common.blue : theme.palette.common.gray, 0.1)};
  }
`;
const Dropdown = styled('div')`
  padding: ${SMALL_MARGIN_PX} 0;
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.common.answerItem};
  z-index: 1150;
  box-shadow: 0px 13px 26px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.3)};
  min-width: 240px;
  max-width: 240px;
`;
const Caption = styled('span')<{ bold: boolean }>`
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
`;
const dropdownArrowCss = ({ theme }) => css`
  &:after {
    background: ${theme.palette.common.answerItem};
  }
`;
