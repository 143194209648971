import { PaletteMode } from '@mui/material';

import { DiscoverRoutes } from '~/routes';

export const HEADER_IMAGE_PRICING =
  'https://img-cdn.financeunlocked.com/headers-images/financeunlocked/6.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666614137671';
export const HEADER_IMAGE_VIDEOS =
  'https://img-cdn.financeunlocked.com/headers-images/7.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666687674552';
export const HEADER_IMAGE_PATHWAYS =
  'https://img-cdn.financeunlocked.com/headers-images/8.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666687674663';
export const HEADER_IMAGE_INSIGHTS =
  'https://img-cdn.financeunlocked.com/headers-images/9.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666687674645';
export const HEADER_IMAGE_GLOSSARY =
  'https://img-cdn.financeunlocked.com/headers-images/2.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1619080601629';
export const DELIVERED_BY_XUNL = (mode?: PaletteMode) =>
  `https://img-cdn.financeunlocked.com/logos/xUnlocked${mode === 'light' ? '_dark' : ''}.png`;

export const VIDEO_MAX_PROGRESS = 90;
export const VIDEO_MIN_PROGRESS = 10;

export const SECONDS_IN_HOUR = 3600;
export const SECONDS_IN_MINUTE = 60;

export const WIDE_CATEGORY_LABEL_WIDTH = '160px';

export const COURSE_PAGE_CUSTOM_BLOCKS_URL = DiscoverRoutes.Courses;
export const yyyyLLddHHmmss = 'yyyy-LL-dd HH:mm:ss';
