import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import cx from 'classnames';

import IKImage from '~/components/IKImage/IKImage';
import { BodyText, H3 } from '~/components/UI/Texts';
import useEventListener from '~/hooks/useEventListener';
import { CssUtilityType } from '~/styled';
import {
  MIDDLE_MARGIN_PX,
  SMALL_MARGIN_PX,
  SUBMIDDLE_MARGIN_PX,
  TINY_MARGIN_PX,
  useIsWidthUp,
} from '~/theme';
import { Background, Maybe, Scalars } from '~/types';

interface CardProps {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  background?: Maybe<Background>;
  wrapperStyles?: CssUtilityType;
}

const Card: FC<CardProps> = ({ background, imageUrl, title, description, wrapperStyles }) => {
  const [minTileHeight, setMinTileHeight] = useState(0);
  const [showBackTile, setShowBackTile] = useState(false);

  const backTileRef = useRef<HTMLDivElement>(null);

  const isMobile = !useIsWidthUp('md');
  const hasHoverEffect = Boolean(description && !isMobile);

  const handleClickCard = () => {
    if (isMobile && description) {
      setShowBackTile((prevState) => !prevState);
    }
  };

  const handleResize = useCallback(() => {
    if (backTileRef.current?.offsetHeight) {
      setMinTileHeight(backTileRef.current.offsetHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backTileRef.current]);

  useEventListener('resize', handleResize);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  return (
    <Wrapper
      className={cx({
        cardsBlock_cardWrapper_toggled: isMobile && showBackTile,
        cardsBlock_cardWrapper_hoverable: hasHoverEffect,
      })}
      onClick={handleClickCard}
      optionalStyles={wrapperStyles}
      hasHoverEffect={hasHoverEffect}
    >
      <FrontTile minHeight={minTileHeight} background={background}>
        <BigImg path={imageUrl} />
        {title && (
          <H3 variant="h3" align="center">
            {title}
          </H3>
        )}
      </FrontTile>
      {description && (
        <BackTile>
          <BackTileContent ref={backTileRef}>
            <Img path={imageUrl} />
            <BodyText>{description}</BodyText>
          </BackTileContent>
        </BackTile>
      )}
    </Wrapper>
  );
};

export default Card;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles' && prop !== 'hasHoverEffect',
})<{
  optionalStyles?: CssUtilityType;
  hasHoverEffect: boolean;
}>`
  position: relative;
  perspective: 1000px;
  display: flex;
  ${({ hasHoverEffect }) => hasHoverEffect && 'cursor: pointer;'}
  ${({ optionalStyles }) => optionalStyles}
`;
const Tile = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: ease-in-out 800ms;
  transition-delay: 250ms;
  transform-style: preserve-3d;
  box-shadow: 0px 3px 6px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)};
  border-radius: 8px;
  backface-visibility: hidden;
  width: 100%;
`;
const FrontTile = styled(Tile, {
  shouldForwardProp: (prop) => prop !== 'background' && prop !== 'minHeight',
})<{ minHeight: number; background?: Maybe<Background> }>`
  align-items: center;
  padding: ${MIDDLE_MARGIN_PX} ${SUBMIDDLE_MARGIN_PX};
  background: ${({ theme }) => theme.palette.common.blackSecondary};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
  .cardsBlock_cardWrapper_hoverable:hover &, .cardsBlock_cardWrapper_toggled & {
    visibility: hidden;
    transform: rotateY(180deg);
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    background: ${({ background, theme }) =>
      background === Background.Dark
        ? theme.palette.common.blackSecondary
        : theme.palette.common.blueDark};
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${SUBMIDDLE_MARGIN_PX};
  }
`;
const BackTile = styled(Tile)`
  background: ${({ theme }) => theme.palette.common.violetAnalyticsCard};
  visibility: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotateY(-180deg);
  left: 0;
  min-height: 100%;
  .cardsBlock_cardWrapper_hoverable:hover &,
  .cardsBlock_cardWrapper_toggled & {
    visibility: visible;
    transform: translateY(-50%) rotateY(0deg);
  }
`;
const BackTileContent = styled('div')`
  padding: ${MIDDLE_MARGIN_PX} ${SUBMIDDLE_MARGIN_PX};
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${SUBMIDDLE_MARGIN_PX};
  }
`;
const BigImg = styled(IKImage)`
  width: 72px;
  height: 72px;
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};
`;
const Img = styled(IKImage)`
  width: 36px;
  height: 36px;
  margin-bottom: ${TINY_MARGIN_PX};
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${SMALL_MARGIN_PX};
  }
`;
