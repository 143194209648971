import React, { FC } from 'react';

import { Container, Typography } from '@mui/material';
import { styled } from '@mui/material';
import cx from 'classnames';

import LazyLoadImage from '~/components/LazyloadImage/LazyLoadImage';
import { LARGE_MARGIN_PX, MIDDLE_MARGIN_PX, SMALL_MARGIN, SMALL_MARGIN_PX } from '~/theme';
import { Background, Maybe, TrustpilotBlock as TrustpilotBlockType } from '~/types';

interface TrustpilotBlockProps {
  data: TrustpilotBlockType;
}

const TrustpilotBlock: FC<TrustpilotBlockProps> = ({ data }) => {
  return (
    <Wrapper
      className={cx(data?.background === 'Dark' ? 'dark-block' : 'light-block', 'trustpilot-block')}
      background={data?.background}
    >
      <StyledContainer className="trustpilot-block__container">
        <TextWrapper className="trustpilot-block__text-wrap">
          {data?.trustpilot_url ? (
            <TitleLink href={data.trustpilot_url} className="trustpilot-block__title-link">
              <Title variant="h2" className="trustpilot-block__title">
                {data?.title}
              </Title>
            </TitleLink>
          ) : (
            <Title variant="h2" className="trustpilot-block__title">
              {data?.title}
            </Title>
          )}
          <Description className="trustpilot-block__description">{data?.description}</Description>
        </TextWrapper>
        <ImgWrapper className="trustpilot-block__img-wrap">
          {data?.trustpilot_image &&
            (data?.trustpilot_url ? (
              <a href={data.trustpilot_url}>
                <StyledLazyLoadImage
                  path={data.trustpilot_image}
                  className="trustpilot-block__img"
                />
              </a>
            ) : (
              <StyledLazyLoadImage path={data.trustpilot_image} className="trustpilot-block__img" />
            ))}
        </ImgWrapper>
      </StyledContainer>
    </Wrapper>
  );
};

export default TrustpilotBlock;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background?: Maybe<Background> }>`
  padding-top: ${MIDDLE_MARGIN_PX};
  padding-bottom: ${MIDDLE_MARGIN_PX};
  background: ${({ theme }) => theme.palette.secondary.dark};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: ${LARGE_MARGIN_PX};
    padding-bottom: ${LARGE_MARGIN_PX};
    background-color: ${({ background, theme }) =>
      background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light};
  }
`;
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: center;
  }
`;
const TextWrapper = styled('div')`
  flex: 1;
  margin-bottom: ${MIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-right: ${MIDDLE_MARGIN_PX};
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding-right: ${LARGE_MARGIN_PX};
  }
`;
const TitleLink = styled('a')`
  color: ${({ theme }) => theme.palette.common.white};
`;
const Title = styled(Typography)`
  && {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: ${SMALL_MARGIN_PX};

    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: ${SMALL_MARGIN / 2}px;
    }
  }
`;
const Description = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.common.gray};

  ${({ theme }) => theme.breakpoints.up('md')} {
    line-height: 28px;
  }
`;
const ImgWrapper = styled('div')`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-left: ${MIDDLE_MARGIN_PX};
    justify-content: flex-end;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding-left: ${LARGE_MARGIN_PX};
  }
`;
const StyledLazyLoadImage = styled(LazyLoadImage)`
  max-width: 100%;
  width: 470px;
`;
