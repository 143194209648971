import { useEffect, useState } from 'react';

import { checkIsIframed } from '~/utils/checkIsIframed';

const useIsIframed = () => {
  const [isIframed, setIsIframed] = useState(false);

  useEffect(() => {
    if (checkIsIframed()) {
      setIsIframed(true);
    }
  }, []);

  return isIframed;
};

export default useIsIframed;
