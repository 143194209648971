import { styled, TextField } from '@mui/material';

import { SMALL_MARGIN, SMALL_MARGIN_PX, SUBMIDDLE_MARGIN_PX } from '~/theme';

export const Wrapper = styled('div')`
  width: 100%;
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};
`;

export const Col = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${SMALL_MARGIN_PX};
  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex-direction: row;
    align-items: flex-start;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  &:first-child:not(:only-child) {
    margin-bottom: ${SMALL_MARGIN_PX};
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex: 1;
    width: calc(50% - ${SMALL_MARGIN / 2});
    margin-bottom: 0;
    &:first-child:not(:only-child) {
      margin-right: ${SMALL_MARGIN / 2}px;
      margin-bottom: 0;
    }
    &:last-child:not(:only-child) {
      margin-left: ${SMALL_MARGIN / 2}px;
    }
  }
`;

export const StyledTextFieldWide = styled(StyledTextField)`
  && {
    margin-left: 0;
    margin-right: 0;
  }
`;
