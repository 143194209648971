import { useTenantContext } from '~/context/TenantProvider';
import { TenantsRefs } from '~/utils/tenantsConfig';

const useIsSsku = () => {
  const { tenant, fallbackTenantName } = useTenantContext();
  const tenantName = tenant?.name || fallbackTenantName;
  return tenantName === TenantsRefs.Ssku;
};

export default useIsSsku;
