import React, { FC, Fragment, useState } from 'react';

import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material';
import cx from 'classnames';

import IKImage from '~/components/IKImage/IKImage';
import LazyLoadImage from '~/components/LazyloadImage/LazyLoadImage';
import Button from '~/components/UI/Button/Button';
import { LARGE_MARGIN_PX, MIDDLE_MARGIN_PX, SMALL_MARGIN_PX } from '~/theme';
import { Background, ForEnterprise, Maybe } from '~/types';

interface ImageWithTabsBlockProps {
  data: ForEnterprise;
}

const ImageWithTabsBlock: FC<ImageWithTabsBlockProps> = ({ data }) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const background = data?.background;

  return (
    <Wrapper
      className={cx('image-with-tabs-block', background === 'Dark' ? 'dark-block' : 'light-block')}
      background={background}
    >
      <Container>
        <ContentWrap>
          <Content>
            <Heading data-testid="imageWithTabsHeading" variant="h2" component="h2">
              {data?.heading}
            </Heading>
            <Description data-testid="imageWithTabsDescription">{data?.description}</Description>
            <Subheading data-testid="imageWithTabsSubtitle" variant="h3" component="h3">
              {data?.sub_title}
            </Subheading>
            <Tabs>
              {data?.content?.map((item, i) => (
                <Tab
                  data-testid="imageWithTabsItemBtn"
                  key={item?.title}
                  onClick={() => setActiveTab(i)}
                  className={cx(activeTab === i && 'active-tab', `image-with-tabs-block__tab-${i}`)}
                  background={background}
                >
                  {item?.title}
                </Tab>
              ))}
            </Tabs>
            {data?.button_text && data?.button_url && (
              <StyledButton
                data-testid="imageWithTabsBtn"
                className="button-desktop"
                text={data?.button_text}
                link={data?.button_url}
              />
            )}
          </Content>
          <ImageList>
            {data?.content?.map((item, i) => (
              <Fragment key={item?.desktopImageUrl}>
                <MobileImage
                  data-testid="imageWithTabsItemMob"
                  className={cx(
                    activeTab === i && 'active-img',
                    `image-with-tabs-block__modile-img-${i}`,
                  )}
                >
                  {/*render only for visible image as lazyloaded to prevent issue with changing preloader
                  to normal image without scroll*/}
                  {item?.mobileImageUrl &&
                    (i === 0 ? (
                      <LazyLoadImage
                        data-testid="imageWithTabsItemImageMob"
                        path={item.mobileImageUrl}
                        draggable={false}
                        alt={item?.title}
                        srcset={[{ height: 540 }]}
                        sizes="100vw"
                      />
                    ) : (
                      <IKImage
                        data-testid="imageWithTabsItemImageMob"
                        path={item.mobileImageUrl}
                        draggable={false}
                        alt={item?.title}
                        srcset={[{ height: 540 }]}
                        sizes="100vw"
                      />
                    ))}
                </MobileImage>
                <DesktopImage
                  data-testid="imageWithTabsItem"
                  className={cx(
                    activeTab === i && 'active-img',
                    `image-with-tabs-block__desktop-img-${i}`,
                  )}
                >
                  {/*render only for visible image as lazyloaded to prevent issue with changing preloader
                  to normal image without scroll*/}
                  {item?.desktopImageUrl &&
                    (i === 0 ? (
                      <LazyLoadImage
                        data-testid="imageWithTabsItemImage"
                        path={item?.desktopImageUrl}
                        draggable={false}
                        alt={item?.title}
                        srcset={[{ width: 850 }]}
                        sizes="100vw"
                      />
                    ) : (
                      <IKImage
                        data-testid="imageWithTabsItemImage"
                        path={item?.desktopImageUrl}
                        draggable={false}
                        alt={item?.title}
                        srcset={[{ width: 850 }]}
                        sizes="100vw"
                      />
                    ))}
                </DesktopImage>
              </Fragment>
            ))}
          </ImageList>
          {data?.button_text && data?.button_url && (
            <StyledButton
              data-testid="imageWithTabsBtnMob"
              className="button-mobile"
              text={data?.button_text}
              link={data?.button_url}
            />
          )}
        </ContentWrap>
      </Container>
    </Wrapper>
  );
};

export default ImageWithTabsBlock;

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background?: Maybe<Background> }>`
  padding: ${MIDDLE_MARGIN_PX} 0;
  background: ${({ theme }) => theme.palette.secondary.dark};
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${LARGE_MARGIN_PX} 0;
    background: ${({ background, theme }) =>
      background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light};
  }
`;
const ContentWrap = styled(Box)`
  position: relative;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    align-items: center;
  }
`;
const Content = styled(Box)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 50%;
    min-width: 50%;
    padding-right: ${MIDDLE_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-right: ${LARGE_MARGIN_PX};
  }
`;
const Heading = styled(Typography)`
  && {
    margin-bottom: ${SMALL_MARGIN_PX};
    width: 100%;
    font-size: 24px;
    line-height: 1.2;

    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 28px;
      line-height: 1.4;
    }
  }
` as typeof Typography;
const Description = styled(Typography)`
  font-size: 18px;
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.common.white};
  margin-bottom: ${MIDDLE_MARGIN_PX};
`;
const Subheading = styled(Typography)`
  && {
    font-size: 20px;
    line-height: 1.4;
    color: ${({ theme }) => theme.palette.common.white};
    margin-bottom: ${MIDDLE_MARGIN_PX};

    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-bottom: 24px;
    }
  }
` as typeof Typography;
const Tabs = styled('ul')`
  margin-bottom: ${MIDDLE_MARGIN_PX};
  padding: 0;
  list-style: none;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;
const Tab = styled('li', {
  shouldForwardProp: (prop) =>
    prop !== 'background' && prop !== 'additionalStyles' && prop !== 'additionalStyles1',
})<{ background?: Maybe<Background> }>`
  color: ${({ theme }) => theme.palette.common.white};
  background: ${({ theme }) => theme.palette.common.blackSecondary};
  border-radius: 8px;
  transition: all, 0.3s;
  cursor: pointer;
  line-height: 1.4;
  font-size: 14px;
  text-align: center;
  padding: 10px 16px;

  &.active-tab {
    color: ${({ theme }) => theme.palette.common.blueDark};
    background: ${({ theme }) => theme.palette.common.violet};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    background: ${({ background, theme }) =>
      background === Background.Dark
        ? theme.palette.common.blackSecondary
        : theme.palette.common.blueDark};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    min-width: calc(50% - 12px);
    flex: 1;

    &:nth-child(odd) {
      margin-right: 24px;
    }

    &:nth-child(-n + 2) {
      margin-bottom: 24px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: left;
    padding: ${SMALL_MARGIN_PX};
    font-size: 20px;

    &.active-tab {
      margin-left: ${SMALL_MARGIN_PX};
    }

    &:not(:last-child) {
      margin-bottom: ${SMALL_MARGIN_PX};
    }
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    width: 460px;
  }
`;
const ImageList = styled(Box)`
  overflow: hidden;
  margin: 0 auto ${MIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 0;
    min-width: 50vw;
    padding-right: 20px;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding-right: ${MIDDLE_MARGIN_PX};
  }
`;
const MobileImage = styled(Box)`
  display: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }

  &.active-img {
    display: flex;
    justify-content: center;

    ${({ theme }) => theme.breakpoints.up('md')} {
      display: none;
    }
  }

  & img {
    max-height: 540px;
    width: 100%;
    object-fit: contain;
  }
`;
const DesktopImage = styled(Box)`
  display: none;
  transition: all 0.3s;
  width: 100%;

  &.active-img {
    ${({ theme }) => theme.breakpoints.up('md')} {
      display: block;
    }
  }

  & img {
    width: 100%;
  }
`;
const StyledButton = styled(Button)`
  &.button-desktop {
    ${({ theme }) => theme.breakpoints.down('md')} {
      display: none;
    }
  }

  &.button-mobile {
    display: flex;
    margin: auto;

    ${({ theme }) => theme.breakpoints.up('md')} {
      display: none;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 100%;
    }
  }
`;
