export enum Modals {
  LinkedInModal = 'LinkedInModal',
  ShareModal = 'ShareModal',
  LeaveModal = 'LeaveModal',
  VideoModal = 'VideoModal',
  Confirm = 'Confirm',
  ChangeLearnerTeamModal = 'ChangeLearnerTeamModal',
  EditTeamModal = 'EditTeamModal',
  CreateTeamModal = 'CreateTeamModal',
  NotificationsModal = 'NotificationsModal',
  ExtendDueDateModal = 'ExtendDueDateModal',
  OnboardingModal = 'OnboardingModal',
  SetGoalsLaterModal = 'SetGoalsLaterModal',
}

export type IChildrenParams = {
  closeModal: () => void;
  openModal: (name: Modals, params?: {}) => void;
  closeAllModals: () => void;
};

export type ModalProps = {
  closeModal?: IChildrenParams['closeModal'];
  openModal?: IChildrenParams['openModal'];
  closeAllModals?: IChildrenParams['closeAllModals'];
  labelledBy: string;
};
