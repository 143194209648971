import { useEffect, useRef } from 'react';

const useEventListener = (
  eventName,
  handler,
  element: null | HTMLElement | typeof window = window,
  options?,
) => {
  // Create a ref that stores handler
  const savedHandler = useRef();
  savedHandler.current = handler;

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      // @ts-ignore
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener, options);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener, options);
      };
    },
    [eventName, element, options, savedHandler], // Re-run if eventName or element changes
  );
};

export default useEventListener;
