import { VIDEO_MAX_PROGRESS, VIDEO_MIN_PROGRESS } from '~/utils/const';
import { generateContinueWatchingLink } from '~/utils/urlUtils/generateContinueWatchingLink';

/**
 *
 * @param lastPosition - last viewed position, in milliseconds
 * @param duration - video duration, in seconds
 * @param debug - display real position if lastPosition > duration
 * @return - object with video progress, and progressValue in percentages
 */
export const getVideoProgressData = (lastPosition, duration, debug: boolean = false) => {
  const _lastPosition = Math.ceil(lastPosition / 1000);
  const progress = _lastPosition > duration && !debug ? duration : _lastPosition;
  const progressValue = (progress / duration) * 100;
  return {
    progress,
    progressValue,
  };
};

export const getVideoProgressDataByCompleted = (
  lastPosition,
  duration,
  completed,
  debug: boolean = false,
) => {
  let progress;
  let progressValue;
  if (completed) {
    progress = duration;
    progressValue = 100;
  } else {
    const { progress: prg, progressValue: prgValue } = getVideoProgressData(
      lastPosition,
      duration,
      debug,
    );
    progress = prg;
    progressValue = prgValue;
  }

  return {
    progress,
    progressValue,
  };
};

export const calcVideoProgressAndLinkToShow = (
  completed,
  lastPosition,
  duration,
  videoUrl,
  chapter,
  time,
  debug = false,
) => {
  const { progressValue } = getVideoProgressData(lastPosition, duration, debug);

  let linkToShow = generateContinueWatchingLink(`/videos/${videoUrl}`, chapter, time);
  let progressToShow = progressValue;

  if (!completed && progressValue > VIDEO_MAX_PROGRESS && progressValue < 100) {
    progressToShow = VIDEO_MAX_PROGRESS;
  }
  if (!completed && progressValue >= 100) {
    linkToShow = `/videos/${videoUrl}`;
    progressToShow = VIDEO_MIN_PROGRESS;
  }
  return {
    linkToShow,
    progressToShow,
  };
};

export const formatProgressData = (progress) => {
  if (!progress || !progress.length) return {};

  return progress?.reduce((all, elem) => {
    const id = elem?.video?.id;
    const videoUrl = elem?.video?.video_url || id;
    const { linkToShow, progressToShow } = calcVideoProgressAndLinkToShow(
      elem?.completed,
      elem?.last_position,
      elem?.video?.duration,
      videoUrl,
      elem?.chapter,
      elem?.time,
    );
    return {
      ...all,
      [id as any]: {
        link: linkToShow,
        progress: elem?.progress_percentage || 0,
        lastPositionPercent: progressToShow,
        completed: elem?.completed,
        new: Boolean(elem?.video?.new),
      },
    };
  }, {});
};
