import React, { FC } from 'react';

import { Box, Container, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';

import IKImage from '~/components/IKImage/IKImage';
import { HERO_IMG_SRC_SET } from '~/const';
import useIsSsku from '~/hooks/tenant/useIsSsku';
import { CssUtilityType } from '~/styled';
import { useIsWidthUp } from '~/theme';
import { Maybe } from '~/types';
import useRandomHeroImage from '~/utils/useRandomHeroImage';

interface BackgroundImageProps {
  backgroundUrl?: Maybe<string>;
  foregroundUrl?: Maybe<string>;
  foregroundAlt?: Maybe<string>;
  srcset?: any[];
  backgroundAbsoluteUrl?: any;
  wrapperCss?: CssUtilityType;
  fadedOverlay?: boolean;
  fadedFromLeftToRight?: boolean;
  fadedFromBottomToTop?: boolean;
  backgroundAlt?: Maybe<string>;
  foregroundCss?: CssUtilityType;
}

const HeroBackground: FC<BackgroundImageProps> = ({
  backgroundUrl,
  foregroundUrl,
  backgroundAbsoluteUrl,
  backgroundAlt,
  foregroundAlt,
  srcset = HERO_IMG_SRC_SET,
  fadedOverlay = true,
  fadedFromLeftToRight = true,
  fadedFromBottomToTop = true,
  wrapperCss,
  foregroundCss,
}) => {
  const isSsku = useIsSsku();
  const isLg = !useIsWidthUp('lg');
  const randomHeroImage = useRandomHeroImage();

  return (
    <Wrapper
      fadedFromLeftToRight={isSsku ? !isLg && fadedFromLeftToRight : fadedFromLeftToRight}
      fadedFromBottomToTop={fadedFromBottomToTop}
      withImage={true}
      optionalStyles={wrapperCss}
    >
      {backgroundAbsoluteUrl ? (
        <Img src={backgroundAbsoluteUrl} alt={backgroundAlt || ''} />
      ) : (
        <>
          <BgImage
            alt={backgroundAlt}
            path={backgroundUrl || randomHeroImage}
            srcset={srcset}
            sizes="100vw"
            draggable={false}
            isSsku={isSsku}
          />
          {foregroundUrl && (
            <ForegroundContainer>
              <ForegroundImage
                alt={foregroundAlt}
                path={foregroundUrl}
                optionalStyles={foregroundCss}
              />
            </ForegroundContainer>
          )}
          {fadedOverlay && !isSsku && <FadedOverlay />}
        </>
      )}
    </Wrapper>
  );
};

export default HeroBackground;

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'optionalStyles' &&
    prop !== 'withImage' &&
    prop !== 'fadedFromLeftToRight' &&
    prop !== 'fadedFromBottomToTop',
})<{
  fadedFromLeftToRight: boolean;
  fadedFromBottomToTop: boolean;
  withImage: boolean;
  optionalStyles?: CssUtilityType;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  max-height: 100%;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 660px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    height: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    display: ${({ fadedFromLeftToRight }) => !fadedFromLeftToRight && 'none'};
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: ${({ theme }) => theme.palette.common.blockBackground.main};
    opacity: 0.7;
    z-index: 2;

    ${({ theme }) => theme.breakpoints.up('md')} {
      width: 70%;
      background: linear-gradient(
        90deg,
        ${({ theme }) => theme.palette.common.blockBackground.main} 0%,
        ${({ theme }) => alpha(theme.palette.common.blockBackground.main, 0.0001)} 100%
      );
      opacity: 1;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
      visibility: ${({ theme }) => theme.isSsku && theme.palette.mode === 'light' && 'hidden'};
    }
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 4;
    display: ${({ fadedFromBottomToTop }) => !fadedFromBottomToTop && 'none'};
    width: 100%;
    height: ${({ theme, withImage }) => (withImage ? (theme.isSsku ? '30%' : '100%') : '370px')};
    left: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.palette.common.blockBackground.main} 0%,
      ${({ theme }) => alpha(theme.palette.common.blockBackground.main, 0.0001)} 100%
    );

    ${({ theme }) => theme.breakpoints.up('lg')} {
      visibility: ${({ theme }) => theme.isSsku && theme.palette.mode === 'light' && 'hidden'};
    }
  }

  && {
    ${({ optionalStyles }) => optionalStyles}
  }
`;
const Img = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${({ theme }) => (theme.isSsku ? (theme.palette.mode === 'light' ? 0.05 : 0.1) : 1)};
`;
const FadedOverlay = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: radial-gradient(
    82% 64.09% at 50% 50%,
    ${({ theme }) => alpha(theme.palette.common.violet, 0.396078)} 0%,
    ${({ theme }) => alpha(theme.palette.common.primary, 0.4)} 100%
  );
`;
const BgImage = styled(IKImage, {
  shouldForwardProp: (prop) => prop !== 'underForeground' && prop !== 'isSsku',
})`
  object-fit: cover;
  width: 100%;
  height: 100%;
  padding-bottom: 2px;
  opacity: ${({ isSsku, theme }) => (isSsku ? (theme.palette.mode === 'light' ? 0.05 : 0.1) : 1)};
`;
const ForegroundImage = styled(IKImage, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  margin: auto auto 0;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-right: 0;
    right: unset;
    max-width: 600px;
    padding-left: 600px;
    box-sizing: content-box;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    max-width: 700px;
  }

  @media (min-width: 1366px) {
    max-width: 800px;
  }

  ${({ theme }) => theme.breakpoints.up('xxl')} {
    max-width: 900px;
  }

  @media (min-width: 1900px) {
    max-width: 1000px;
  }

  ${({ optionalStyles }) => optionalStyles}
`;
const ForegroundContainer = styled(Container)`
  position: absolute;
  top: -2px;
  left: 50%;
  z-index: 3;
  display: flex;
  height: 100%;
  transform: translateX(-50%);
  padding-top: 140px;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: 160px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-top: 140px;
  }
`;
