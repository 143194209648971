import React from 'react';

import { Link } from 'react-router-dom';

import { isAbsoluteUrl } from '~/utils/urlUtils/isAbsoluteUrl';

const SafeLink = ({ to, children, ...rest }) => {
  const isAbsolute = isAbsoluteUrl(to);

  return isAbsolute ? (
    <a href={to} {...rest}>
      {children}
    </a>
  ) : (
    <Link to={to} {...rest}>
      {children}
    </Link>
  );
};

export default SafeLink;
