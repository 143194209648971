import React, { FC } from 'react';

import { styled } from '@mui/material';

import CardWithCheckIcon from '~/blocks/Header/components/CardWithCheckIcon';
import CardWithIconAndLink from '~/blocks/Header/components/CardWithIconAndLink';
import { SMALL_MARGIN_PX, SUBMIDDLE_MARGIN_PX } from '~/theme';
import { MegaMenuMiddleClickableSubBlock, MegaMenuMiddleInformationalSubBlock } from '~/types';

export const calcPlacement = (itemsCount = 0) => {
  const res: number[] = [];
  if (itemsCount < 5) {
    for (let i = 0; i < itemsCount; i++) {
      if (i === 0) {
        res.push(itemsCount % 2 ? 6 : 3);
      } else {
        res.push(3);
      }
    }
  } else {
    const mod = itemsCount % 3;
    for (let i = 0; i < itemsCount; i++) {
      if (i === 0) {
        res.push(mod === 1 ? 6 : mod === 0 ? 2 : 3);
      } else if (i === 1) {
        res.push(mod === 2 ? 3 : 2);
      } else {
        res.push(2);
      }
    }
  }

  return res;
};

interface MiddleMegaMenuProps {
  linkItems?: (MegaMenuMiddleClickableSubBlock | null)[] | null;
  checkItems?: (MegaMenuMiddleInformationalSubBlock | null)[] | null;
}

const MiddleMegaMenu: FC<MiddleMegaMenuProps> = ({ linkItems, checkItems }) => {
  const linkedItemsPlacement = calcPlacement(linkItems?.length);
  const checkItemsPlacement = calcPlacement(checkItems?.length);

  return (
    <Wrapper>
      {Boolean(linkItems?.length) && (
        <LinkableItemsWrapper>
          {linkItems?.map((item, i) => (
            <Item key={i} span={linkedItemsPlacement[i]} data-testid="megamenuLinkItem">
              <CardWithIconAndLink
                title={item?.title}
                description={item?.sub_title}
                imageUrl={item?.image_url}
                link={item?.link}
              />
            </Item>
          ))}
        </LinkableItemsWrapper>
      )}
      {Boolean(checkItems?.length) && (
        <CheckItemsWrapper>
          {checkItems?.map((item, i) => (
            <Item key={i} span={checkItemsPlacement[i]} data-testid="megamenuCheckItem">
              <CardWithCheckIcon title={item?.title} description={item?.sub_title} />
            </Item>
          ))}
        </CheckItemsWrapper>
      )}
    </Wrapper>
  );
};

export default MiddleMegaMenu;

const Wrapper = styled('div')`
  margin-bottom: -${SMALL_MARGIN_PX};
`;

const ItemsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    justify-items: stretch;
    grid-template-columns: repeat(6, 1fr);
  }
  &:not(:last-child) {
    margin-bottom: ${SMALL_MARGIN_PX};
  }
`;

const LinkableItemsWrapper = styled(ItemsWrapper)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    column-gap: ${SMALL_MARGIN_PX};
    row-gap: ${SUBMIDDLE_MARGIN_PX};
  }
`;

const CheckItemsWrapper = styled(ItemsWrapper)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    gap: ${SUBMIDDLE_MARGIN_PX};
  }
`;

const Item = styled('div')<{ span: number }>`
  display: flex;
  justify-content: stretch;
  grid-column-end: ${({ span }) => 'span ' + span};
`;
