import React, { FC } from 'react';

import { css, styled } from '@mui/material/styles';

import { useUserInfoContext } from '~/context/UserInfoProvider';
import useAvatarColor from '~/hooks/useAvatarColor';

const sizes = {
  small: {
    width: 36,
    fz: 14,
  },
  medium: {
    width: 54,
    fz: 16,
  },
};

type Size = keyof typeof sizes;

interface SizesByBreakpoints {
  xs?: Size;
  md?: Size;
}

interface UserAvatarProps extends SizesByBreakpoints {
  onClick?: () => void;
  className?: string;
}

const ProfileAvatar: FC<UserAvatarProps> = (props) => {
  const { userInfo } = useUserInfoContext();
  const avatarColor = useAvatarColor();
  const formattedUserName = userInfo?.name
    ?.split(' ')
    .map((item) => item.substr(0, 1))
    .join('');

  return (
    <Wrapper
      aria-label={formattedUserName}
      aria-description="account of current user"
      background={avatarColor?.background}
      color={avatarColor?.color}
      data-testid="profileAvatar"
      {...props}
    >
      {formattedUserName}
    </Wrapper>
  );
};

export default ProfileAvatar;

const Wrapper = styled('button', {
  shouldForwardProp: (prop) => prop !== 'background' && prop !== 'color' && prop !== 'xs',
})<{ background?: string; color?: string } & SizesByBreakpoints>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ xs = 'small' }) => sizes[xs].width + 'px'};
  min-width: ${({ xs = 'small' }) => sizes[xs].width + 'px'};
  height: ${({ xs = 'small' }) => sizes[xs].width + 'px'};
  border-radius: 50%;
  border: none;
  font-weight: 700;
  font-size: ${({ xs = 'small' }) => sizes[xs].fz + 'px'};
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  line-height: 17px;
  cursor: ${({ onClick }) => onClick && 'pointer'};

  ${({ theme, md }) =>
    md &&
    css`
      ${theme.breakpoints.up('md')} {
        width: ${sizes[md].width + 'px'};
        min-width: ${sizes[md].width + 'px'};
        height: ${sizes[md].width + 'px'};
        font-size: ${sizes[md].fz + 'px'};
      }
    `}
`;
