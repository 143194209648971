import React from 'react';

import { useTheme } from '@mui/material';

import { Svg } from '~/assets/icons/styles';

const StarEdgyFilledIcon = ({ color = '', ...props }) => {
  const theme = useTheme();
  const svgColor = color || theme.palette.common.blue;

  return (
    <Svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      svgColor={svgColor}
    >
      <path
        d="M22.5 9.56812L15.31 8.94812L12.5 2.32812L9.69 8.95813L2.5 9.56812L7.96 14.2981L6.32 21.3281L12.5 17.5981L18.68 21.3281L17.05 14.2981L22.5 9.56812Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default StarEdgyFilledIcon;
