import React, { useMemo } from 'react';

import { alpha, Container, useTheme } from '@mui/material';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link as ReactLink } from 'react-router-dom';

import FacebookIcon from '~/assets/icons/FacebookIcon';
import InstagramIcon from '~/assets/icons/InstagramIcon';
import LinkedInIcon from '~/assets/icons/LinkedInIcon';
import TwitterIcon from '~/assets/icons/TwitterIcon';
import IKImage from '~/components/IKImage/IKImage';
import LangWidget from '~/components/LangWidget/LangWidget';
import { useTenantContext } from '~/context/TenantProvider';
import { useUserInfoContext } from '~/context/UserInfoProvider';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import {
  BIG_MARGIN,
  BIG_MARGIN_PX,
  LARGE_MARGIN,
  MIDDLE_MARGIN,
  SMALL_MARGIN,
  TINY_MARGIN_PX,
} from '~/theme';
import { Navigation, NavigationItemType, NavigationSection, Tenant } from '~/types';
import { TENANTS_CONFIG, TenantsRefs } from '~/utils/tenantsConfig';

const checkIfInternalLink = (link: string): boolean => {
  return !!(link && link[0] === '/');
};

interface FooterProps {
  footerPartners: Tenant['footer_partners'];
  navigation?: Navigation;
  tenantTitle?: string | null;
  linkedinLink?: string | null;
  twitterLink?: string | null;
  facebookLink?: string | null;
  instagramLink?: string | null;
}

const Footer: React.FC<FooterProps> = ({
  footerPartners,
  navigation,
  tenantTitle,
  linkedinLink,
  twitterLink,
  facebookLink,
  instagramLink,
}) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();
  const year = new Date().getFullYear();
  const { tenant } = useTenantContext();
  const { userInfo } = useUserInfoContext();
  const langSwitchingAllowed =
    (!userInfo && tenant?.name && TENANTS_CONFIG[tenant?.name]?.langSwitchingAllowed) ||
    tenant?.name === TenantsRefs.Ssku;

  const navigationSections: NavigationSection[] = useMemo(() => {
    const sections = navigation?.items
      ? (navigation.items.filter(
          (item) => item.item_type === NavigationItemType.Section,
        ) as NavigationSection[])
      : [];

    return sections.map((section) => ({
      ...section,
      items: section?.items ? section.items.filter((item) => item?.type === 'Link') : [],
    }));
  }, [navigation]);

  const socialLinks = (
    <SocialLinks>
      {linkedinLink && (
        <SocialIcon
          href={linkedinLink}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="linkedin_link"
        >
          <LinkedInIcon color={theme.palette.common.white} />
        </SocialIcon>
      )}
      {instagramLink && (
        <SocialIcon
          href={instagramLink}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="instagram_link"
        >
          <InstagramIcon
            color={theme.palette.common.white}
            data-color={theme.palette.common.white}
          />
        </SocialIcon>
      )}
      {facebookLink && (
        <SocialIcon
          href={facebookLink}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="facebook_link"
        >
          <FacebookIcon color={theme.palette.common.white} />
        </SocialIcon>
      )}
      {twitterLink && (
        <SocialIcon
          href={twitterLink}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="twitter_link"
        >
          <TwitterIcon color={theme.palette.common.white} />
        </SocialIcon>
      )}
    </SocialLinks>
  );

  const copyright = (
    <License>
      © {tenantTitle} {year}
    </License>
  );

  return (
    <Wrapper component="footer">
      <FooterContainer>
        <Content>
          {footerPartners && (
            <Box>
              <FooterTitle data-testid="partnersTitle" component="h3">
                {t('footer.accreditedBy')}
              </FooterTitle>
              <FooterPartners>
                {footerPartners.map((item) => (
                  <FooterPartner key={item?.id}>
                    <Link href={item?.url_link || undefined} target="_blank">
                      <IKImage
                        transformation={[{ height: 52 }]}
                        path={item?.image_url}
                        draggable={false}
                        alt={item?.title}
                      />
                    </Link>
                  </FooterPartner>
                ))}
              </FooterPartners>
            </Box>
          )}
          <FooterNavigation>
            {navigationSections.map((block) => (
              <FooterLinksBlock key={block.title}>
                <SectionFooterTitle component="h3">{block.title}</SectionFooterTitle>
                {block.items &&
                  block.items.map((item) => {
                    const isInternalLink = item?.url && checkIfInternalLink(item.url);
                    return isInternalLink ? (
                      <FooterLink key={item?.url} component={ReactLink} to={item?.url}>
                        {item?.title}
                      </FooterLink>
                    ) : (
                      <FooterLink key={item?.url} href={item?.url}>
                        {item?.title}
                      </FooterLink>
                    );
                  })}
              </FooterLinksBlock>
            ))}
          </FooterNavigation>
        </Content>
        {langSwitchingAllowed ? (
          <>
            <BottomPart>
              {socialLinks}
              <StyledLangWidget arrowPlacementBottom />
            </BottomPart>
            <Center>{copyright}</Center>
          </>
        ) : (
          <BottomPart>
            {socialLinks}
            {copyright}
          </BottomPart>
        )}
      </FooterContainer>
      <BelowFooterContent id="below-footer-content"></BelowFooterContent>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.palette.common.footer.bg};
`;
const FooterContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 1600px;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding: 0 90px;
  }
`;
const Content = styled(Box)`
  padding: ${MIDDLE_MARGIN}px 0;
  border-bottom: 1px solid ${({ theme }) => alpha(theme.palette.common.white, 0.33)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: ${LARGE_MARGIN}px 0;
  }
`;
const FooterTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.footer.title};
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: ${SMALL_MARGIN}px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 22px;
    margin-bottom: 30px;
  }
` as typeof Typography;
const SectionFooterTitle = styled(FooterTitle)`
  margin-bottom: ${SMALL_MARGIN / 2}px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 22px;
  }
` as typeof Typography;
const FooterPartners = styled('div')`
  margin: auto;
  padding-bottom: ${MIDDLE_MARGIN}px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-right: ${MIDDLE_MARGIN}px;
  }
`;
const FooterPartner = styled(Box)`
  display: inline-block;

  &:not(:last-child) {
    margin-right: 30px;

    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-right: ${MIDDLE_MARGIN}px;
    }
  }

  & > img {
    height: 36px;
    filter: invert(${({ theme }) => (theme.palette.mode === 'light' ? 1 : 0)});

    ${({ theme }) => theme.breakpoints.up('sm')} {
      height: 42px;
    }

    ${({ theme }) => theme.breakpoints.up('md')} {
      height: 44px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 600px;
  }
`;
const FooterNavigation = styled(Box)`
  display: flex;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-wrap: wrap;
  }
`;
const FooterLinksBlock = styled(Box)`
  margin-bottom: ${TINY_MARGIN_PX};

  &:not(:last-child) {
    padding-right: ${MIDDLE_MARGIN}px;

    ${({ theme }) => theme.breakpoints.up('md')} {
      padding-right: ${LARGE_MARGIN}px;
    }
  }
`;
const FooterLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 16px;
  line-height: 1.4;
  padding-top: ${TINY_MARGIN_PX};
  padding-bottom: ${TINY_MARGIN_PX};

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.common.footer.hover};
  }
` as typeof Link;
const BottomPart = styled(Box)`
  padding: ${MIDDLE_MARGIN}px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${BIG_MARGIN}px 0;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;
const SocialLinks = styled(Box)`
  display: flex;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 30px;
  }
`;
const SocialIcon = styled('a')`
  &:not(:first-child) {
    margin-left: ${SMALL_MARGIN}px;
  }

  & > svg {
    width: 24px;
    height: 24px;
  }
`;
const License = styled(Box)`
  font-size: 18px;
  line-height: 1;
  color: ${({ theme }) => theme.palette.common.white};
`;
const Center = styled('div')`
  text-align: center;
  margin-bottom: ${BIG_MARGIN_PX};
`;
const StyledLangWidget = styled(LangWidget)`
  color: ${({ theme }) => theme.palette.common.white};
`;
const BelowFooterContent = styled('div')`
  width: 100%;
`;
