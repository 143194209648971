import React, { useEffect } from 'react';

import { Box, Typography } from '@mui/material';

import useTenantTranslation from '~/hooks/useTenantTranslation';
import useValidation from '~/hooks/useValidation';
import {
  CancelButton,
  ModalContent,
  ModalWrapper,
  StyledCloseIcon,
  StyledCustomButton,
  StyledFormField,
} from '~/modals/modalStyles';
import { ModalProps } from '~/modals/types';
import { teamNameFormSchema } from '~/pages/MyAccountPage/components/AllTeamView/validation';
import useTeamActions, { Name } from '~/pages/MyAccountPage/hooks/useTeamActions';

const CreateTeamModal: React.FC<ModalProps> = ({ closeModal, labelledBy }) => {
  const { t } = useTenantTranslation();

  const { create } = useTeamActions();
  const { values, errors, handleChange, handleSubmit, submitted, submitting } = useValidation(
    {
      name: '',
    },
    teamNameFormSchema,
    (formValues: Name) => create(formValues, ['GetTeamsListPaginated']),
  );

  useEffect(() => {
    if (submitted) {
      closeModal?.();
    }
  }, [submitted, closeModal]);

  return (
    <ModalWrapper>
      <StyledCloseIcon onClick={closeModal} />
      <Typography id={labelledBy} variant="h3">
        {t('page.account.createTeam')}
      </Typography>
      <ModalContent>
        <StyledFormField
          inputProps={{ 'data-testid': 'teamNameField' }}
          name="name"
          variant="outlined"
          label={t('input.label.teamName')}
          value={values.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
        />
      </ModalContent>
      <Box>
        <CancelButton data-testid="cancelTeamButton" onClick={closeModal} color="secondary">
          {t('button.cancel')}
        </CancelButton>
        <StyledCustomButton
          loading={submitting}
          disabled={submitting}
          data-testid="createTeamButton"
          onClick={handleSubmit}
        >
          {t('button.create')}
        </StyledCustomButton>
      </Box>
    </ModalWrapper>
  );
};

export default CreateTeamModal;
