import React, { FC, useState } from 'react';

import { Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { alpha, styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { PROFILE_LINKS, PROFILE_LINKS_SSKU } from '~/blocks/Header/const';
import { createDropdownLinkStyles } from '~/components/LangWidget/styles';
import { useUserInfoContext } from '~/context/UserInfoProvider';
import useIsSsku from '~/hooks/tenant/useIsSsku';
import useIsIframed from '~/hooks/useIsIframed';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { SMALL_MARGIN_PX, useIsWidthUp } from '~/theme';
import ProfileAvatar from '~/ui/components/ProfileAvatar';
import UserCom from '~/utils/UserCom';

interface UserInfoDropdownProps {
  scrolled: boolean;
  handleLogout: () => void;
  onClick: () => void;
}

const UserInfoDropdown: FC<UserInfoDropdownProps> = ({ scrolled, handleLogout, onClick }) => {
  const { t } = useTenantTranslation();
  const isMobile = !useIsWidthUp('xl');
  const { userInfo } = useUserInfoContext();
  const userName = userInfo?.name;
  const teamName = userInfo?.teams?.[0]?.name || null;
  const isSsku = useIsSsku();

  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const isIframed = useIsIframed();

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const closeDropdown = () => setDropdownOpen(false);

  const logoutHandler = () => {
    closeDropdown();
    handleLogout();
  };

  const handleClickContact = () => {
    UserCom.openWidget();
    closeDropdown();
  };

  return (
    <ClickAwayListener onClickAway={closeDropdown}>
      <DotsBtnWrapper>
        <ProfileAvatar md="medium" onClick={isMobile ? onClick : toggleDropdown} />
        <Dropdown
          data-testid="headerUserInfoDropdown"
          aria-label="dropdown"
          opened={isDropdownOpen}
          scrolled={scrolled}
        >
          <DropdownContent>
            <Divider>
              <Username className="userInfoDropdown_username">{userName}</Username>
              {teamName && <Team>{teamName}</Team>}
            </Divider>
            <LinksWrapper>
              {(isSsku ? PROFILE_LINKS_SSKU : PROFILE_LINKS).map((item) => (
                <DropdownLink key={item.url} to={item.url} onClick={closeDropdown}>
                  {t(item.title)}
                </DropdownLink>
              ))}
              {!isSsku && !!UserCom.checkHasUserCom() && (
                <DropdownLinkDiv onClick={handleClickContact}>
                  {t('header.contact')}
                </DropdownLinkDiv>
              )}
            </LinksWrapper>
          </DropdownContent>
          {!isIframed && (
            <GeneratedLogout>
              <DropdownLinkButton onClick={logoutHandler}>{t('header.logout')}</DropdownLinkButton>
            </GeneratedLogout>
          )}
        </Dropdown>
      </DotsBtnWrapper>
    </ClickAwayListener>
  );
};

export default UserInfoDropdown;

const DotsBtnWrapper = styled('div')`
  margin-left: 0;
  position: relative;
  display: block;
`;
const Dropdown = styled('div', {
  shouldForwardProp: (prop) => prop !== 'opened' && prop !== 'scrolled',
})<{ scrolled: boolean; opened: boolean }>`
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.common.blockBackground.light};
  z-index: 1150;
  box-shadow: 0px 13px 26px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.3)};
  min-width: 240px;
  max-width: 240px;
  position: absolute;
  right: -2px;
  top: ${({ scrolled }) => (scrolled ? '70px' : '80px')};
  transition: all 0.5s ease-in-out;
  display: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: ${({ opened }) => (opened ? 'block' : 'none')};
  }
`;
const DropdownContent = styled('div')`
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    background: ${({ theme }) => theme.palette.common.blockBackground.light};
    transform: rotate(45deg);
    position: absolute;
    top: -9px;
    right: calc(50% - 9px);

    ${({ theme }) => theme.breakpoints.up('sm')} {
      right: 20px;
    }
  }
`;
const Divider = styled('div')`
  position: relative;
  padding: 24px 24px ${SMALL_MARGIN_PX};
  margin-bottom: 9px;

  &::after {
    content: '';
    display: block;
    width: calc(100% - 48px);
    height: 1px;
    background: ${({ theme }) => theme.palette.common.white};
    opacity: 0.33;
    position: absolute;
    bottom: 0;
  }

  & .userInfoDropdown_username:not(:only-child) {
    margin-bottom: 10px;
  }

  & .userInfoDropdown_username:only-child {
    margin-bottom: 0;
  }
`;
const Username = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.common.white};
    font-size: 18px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 5px;
    display: none;

    ${({ theme }) => theme.breakpoints.up('xl')} {
      display: block;
    }
  }
`;
const Team = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.common.text.fadedSubtitle};
  line-height: 1.5;
`;
const LinksWrapper = styled('div')`
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: ${SMALL_MARGIN_PX};
  }
`;
const GeneratedLogout = styled('div')`
  margin-top: ${SMALL_MARGIN_PX};
  padding: 9px 0;
  background: ${({ theme }) => theme.palette.common.card.lightAccent};
  border-radius: 0 0 10px 10px;
  display: flex;
`;
const DropdownLink = createDropdownLinkStyles(Link);
const DropdownLinkButton = createDropdownLinkStyles('button');
const DropdownLinkDiv = createDropdownLinkStyles('div');
