import React from 'react';

import Typography from '@mui/material/Typography';
import { alpha, Theme, styled } from '@mui/material/styles';

import LazyLoadImage from '~/components/LazyloadImage/LazyLoadImage';
import { css } from '~/styled';
import { BIG_MARGIN_PX, LARGE_MARGIN, LARGE_MARGIN_PX, MIDDLE_MARGIN, SMALL_MARGIN } from '~/theme';
import { Background } from '~/types';

interface SlideProps {
  imagePath: string;
  listItems: string[];
  title: string;
  slideColor: Background;
}

const Slide = ({ imagePath, listItems = [], title, slideColor }: SlideProps) => {
  const isDark = slideColor === Background.Dark;
  return (
    <Wrapper data-testid="tabsSliderBlockSlide">
      <Shadow isDark={isDark}>
        <LazyLoadImage
          data-testid="slide-img"
          path={imagePath}
          imageWrapper
          srcset={[{ width: 570 }]}
          wrapperCss={lazyLoadImageWrapperCss(isDark)}
        />
        <SlideContent>
          <Title data-testid="slide-title">{title}</Title>
          <List>
            {listItems.map((item, index) => (
              <ListItem key={index} data-testid="slide-list-item">
                {item}
              </ListItem>
            ))}
          </List>
        </SlideContent>
      </Shadow>
    </Wrapper>
  );
};

export default Slide;

const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
  marginBottom: 24,
  fontWeight: 700,
  fontSize: 18,
  [theme.breakpoints.up('md')]: {
    fontSize: 20,
  },
}));
const Wrapper = styled('div')({
  maxWidth: '100%',
  height: '100%',
});
const Shadow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDark',
})<{ isDark: boolean }>`
  height: 100%;
  margin: 3px 3px 5px;
  border-radius: 8px;
  box-shadow: 0 3px 6px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)};
  background: ${({ theme }) => theme.palette.common.blackSecondary};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    background: ${({ isDark, theme }) =>
      isDark ? theme.palette.common.blueDark : theme.palette.common.blackSecondary};
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: row-reverse;
    min-height: 500px;
  }
`;
const SlideContent = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  width: '100%',
  boxSizing: 'border-box',
  padding: `16px 16px ${MIDDLE_MARGIN}px`,
  [theme.breakpoints.up('sm')]: {
    padding: `24px 24px ${MIDDLE_MARGIN}px`,
  },
  [theme.breakpoints.up('md')]: {
    position: 'relative',
    width: '50%',
    padding: `${LARGE_MARGIN}px 0 ${LARGE_MARGIN_PX} ${LARGE_MARGIN}px`,
  },
}));
const List = styled('ul')({
  padding: 0,
  listStyleType: 'none',
  marginBottom: 0,
});
const ListItem = styled('li')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: SMALL_MARGIN,
  color: theme.palette.common.gray,
  fontSize: 16,
  '&:last-child': {
    marginBottom: 0,
  },
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    marginRight: MIDDLE_MARGIN,
    fontSize: 18,
  },
  '&:before': {
    content: '""',
    display: 'block',
    height: 8,
    width: 8,
    minWidth: 8,
    marginTop: 8,
    marginRight: 8,
    borderRadius: '50%',
    background: theme.palette.common.blue,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginRight: SMALL_MARGIN,
    },
  },
}));
const lazyLoadImageWrapperCss =
  (isDark) =>
  ({ theme }) =>
    css`
      position: relative;
      flex-basis: 48%;
      margin-left: auto;
      height: 190px;
      margin-bottom: -${BIG_MARGIN_PX};
      ${theme.breakpoints.up('sm')} {
        height: 240px;
        margin-bottom: -${LARGE_MARGIN_PX};
      }
      ${theme.breakpoints.up('md')} {
        height: initial;
        margin-bottom: 0;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          0deg,
          ${theme.palette.common.blackSecondary} 2%,
          ${alpha(theme.palette.common.blueDark, 0)} 100%
        );
        ${theme.breakpoints.up('sm')} {
          background: linear-gradient(
            0deg,
            ${isDark ? theme.palette.common.blueDark : theme.palette.common.blackSecondary} 2%,
            ${alpha(theme.palette.common.blueDark, 0)} 100%
          );
        }
        ${theme.breakpoints.up('md')} {
          left: -2px;
          top: 0;
          background: linear-gradient(
            90deg,
            ${isDark ? theme.palette.common.blueDark : theme.palette.common.blackSecondary} 2%,
            ${alpha(theme.palette.common.blueDark, 0)} 100%
          );
        }
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          270deg,
          ${alpha(theme.palette.common.violet, 0.15)} 0%,
          ${alpha(theme.palette.common.violet, 0.2)} 100%
        );
        mix-blend-mode: color;
      }
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    `;
