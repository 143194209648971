import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';

import useIsAboveViewport from '~/hooks/useIsAboveViewport';
import { BIG_MARGIN, useIsWidthUp } from '~/theme';

export const PIP_HEADER_HEIGHT = 58;

const INITIAL_POS = { x: 0, y: 0 };
const SIZE = { w: 385, h: 212, wMax: 1000, wMin: 385 };

const usePip = (isVideoPlaying: boolean, fullSizeElementRef: RefObject<HTMLElement>) => {
  const location = useLocation();
  const [active, setActive] = useState(false);
  const [ignoreScroll, setIgnoreScroll] = useState(isVideoPlaying);
  const isMobile = !useIsWidthUp('md');
  const [size, setSize] = useState(SIZE);
  const sizeRef = useRef(size);
  sizeRef.current = size;
  const [pos, setPos] = useState<{ x: number; y: number }>(INITIAL_POS);
  const isElementInViewport = !useIsAboveViewport(fullSizeElementRef);

  useEffect(() => {
    if (isMobile) {
      setActive(false);
    }
  }, [isMobile]);

  useEffect(() => {
    setActive(false);
  }, [location]);

  const calcConstraints = useCallback(
    (w: number, h: number, padding: number) => {
      const fullSizeWrapperRect = fullSizeElementRef.current?.getBoundingClientRect();
      const fullSizeWrapperTop = fullSizeWrapperRect?.top || 0;
      const fullSizeWrapperLeft = fullSizeWrapperRect?.left || 0;

      const rightScreenSide = document.querySelector('html')!.clientWidth - fullSizeWrapperLeft;
      const bottomScreenSide = -fullSizeWrapperTop - window.scrollY + window.innerHeight;

      return {
        top: -fullSizeWrapperTop - window.scrollY + padding,
        left: -fullSizeWrapperLeft + padding,
        right: rightScreenSide - w - padding,
        bottom: bottomScreenSide - h - padding,
      };
    },
    [fullSizeElementRef],
  );

  useEffect(() => {
    if (active) {
      const constraints = calcConstraints(sizeRef.current.w, sizeRef.current.h, BIG_MARGIN);

      setPos({
        x: constraints.right,
        y: constraints.bottom - PIP_HEADER_HEIGHT,
      });
    } else {
      setPos(INITIAL_POS);
    }
  }, [active, fullSizeElementRef, calcConstraints, sizeRef]);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    if (ignoreScroll) {
      return;
    }

    if (!isElementInViewport && !active) {
      setActive(true);
      return;
    }

    if (isElementInViewport && active) {
      setActive(false);
    }
  }, [isElementInViewport, active, ignoreScroll, isMobile]);

  useEffect(() => {
    if (!isVideoPlaying) {
      setIgnoreScroll(true);
      return;
    }

    if (!isElementInViewport) {
      setIgnoreScroll(false);
    }
  }, [isElementInViewport, isVideoPlaying]);

  const disable = useCallback(() => {
    setIgnoreScroll(true);
    setActive(false);
  }, []);

  const enable = useCallback(() => {
    setIgnoreScroll(true);
    setActive(true);
  }, []);

  const toggle = useCallback(() => {
    if (active) {
      disable();
    } else {
      enable();
    }
  }, [active, disable, enable]);

  return {
    active,
    pos,
    disable,
    enable,
    toggle,
    size,
    updateSize: setSize,
    calcConstraints,
  };
};

export default usePip;
