import { gql } from '@apollo/client';

export const GET_DISCOVER_LANDING_DATA = gql`
  query getDiscoverLandingData {
    listFeaturedVideosGroupByCategory {
      items {
        pathways {
          id
          name
          image_url
          slug
          duration
          description
          count_videos
          categories {
            id
            title
            slug
            parent {
              slug
              id
            }
          }
          difficulty {
            id
            icon_url
            title
          }
        }
        videos {
          id
          title
          duration
          description
          preview_url
          video_url
          user {
            id
            name
            slug
          }
          categories {
            id
            title
            slug
            parent {
              slug
              id
            }
          }
          difficulty {
            id
            icon_url
            title
          }
        }
      }
      parentCategory {
        slug
        title
        id
      }
      subCategories {
        id
        title
        slug
      }
    }
  }
`;

export const GET_GLOSSARY_TERM = gql`
  query GetGlossaryTerm($slug: String!) {
    getGlossaryTerm(slug: $slug) {
      content
      definition
      id
      subtitle
      slug
      title
      verse_video_id
      seo_name
      seo_image_name
      seo_image
      seo_description
      tenants {
        name
      }
      categories {
        id
        title
        slug
        parent_id
        parent {
          id
          title
          slug
        }
      }
      glossary_video {
        id
        title
        duration
        verse_video_id
        user {
          name
        }
      }
      videos {
        id
        title
        duration
        preview_url
        video_url
        user {
          id
          name
        }
        categories {
          id
          title
        }
        difficulty {
          id
          icon_url
          title
        }
      }
    }
  }
`;

export const GET_GLOSSARY_TERMS_BY_IDS = gql`
  query GetGlossaryTermsByIds($ids: [ID!]!) {
    getGlossaryTermsByIds(ids: $ids) {
      id
      title
      subtitle
      definition
      content
    }
  }
`;

export const GET_LIST_VIDEO_DIFFICULTIES = gql`
  query ListVideoDifficulties {
    listVideoDifficulties(sort: { column: "title", order: ASC }) {
      id
      title
    }
  }
`;

export const GET_SKILL_BY_SLUG = gql`
  query GetSkillBySlug($slug: String!) {
    getSkillBySlug(slug: $slug) {
      id
      description
      title
      image_url
    }
  }
`;
