import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

import { i18n } from 'i18next';
import { I18nextProvider } from 'react-i18next';

import { DEFAULT_LOCALE, LANG_COOKIE_KEY } from '~/const';
import useQueryParams from '~/hooks/useQueryParams';
import { Language } from '~/i18n';
import { checkConsent } from '~/utils/checkConsent';
import Cookies from '~/utils/cookies';

export const LanguageCtx = createContext<{
  language: string;
  changeLanguage: (lang: Language) => void;
}>({
  language: DEFAULT_LOCALE,
  changeLanguage: () => {},
});

export function useLanguageContext() {
  const context = useContext(LanguageCtx);

  if (!context) {
    throw new Error('useLanguageContext must be used within a LanguageProvider');
  }

  return context;
}

interface LanguageProviderProps {
  children: ReactNode;
  i18n: i18n;
}

const LanguageProvider: FC<LanguageProviderProps> = ({ children, i18n }) => {
  const { lang: paramsLang } = useQueryParams();
  const [language, setLanguage] = useState<Language>(() =>
    window.__LANG_SWITCHING_ALLOWED__
      ? paramsLang || Cookies.get(LANG_COOKIE_KEY) || window.__LANG_CODE__ || DEFAULT_LOCALE
      : paramsLang || DEFAULT_LOCALE,
  );
  const i18nRef = useRef(i18n);
  i18nRef.current = i18n;

  const changeLanguage = useCallback(
    (lang: Language) => {
      checkConsent(LANG_COOKIE_KEY) && Cookies.set(LANG_COOKIE_KEY, lang);

      if (!paramsLang) {
        window.__APOLLO_STATE__ = undefined;
        i18nRef.current.changeLanguage(lang);
        setLanguage(lang);
      }
    },
    [i18nRef, paramsLang],
  );

  return (
    <I18nextProvider i18n={i18n}>
      <LanguageCtx.Provider
        value={{
          language,
          changeLanguage,
        }}
      >
        {children}
      </LanguageCtx.Provider>
    </I18nextProvider>
  );
};

export default LanguageProvider;
