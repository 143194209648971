import React from 'react';

import { useTheme } from '@mui/material';

import { Svg } from '~/assets/icons/styles';

const CircleCheckIcon = ({ color = '', ...props }) => {
  const theme = useTheme();
  const svgColor = color || theme.palette.common.violet;
  return (
    <Svg
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      svgColor={svgColor}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9963 1.2002C7.04105 1.2002 2.19629 6.04495 2.19629 12.0002C2.19629 17.9552 7.04105 22.8002 12.9963 22.8002C18.9513 22.8002 23.7963 17.9552 23.7963 12.0002C23.7963 11.5183 23.4057 11.1277 22.9238 11.1277C22.4419 11.1277 22.0514 11.5183 22.0514 12.0002C22.0514 16.9933 17.9894 21.0553 12.9963 21.0553C8.00348 21.0553 3.94123 16.9933 3.94123 12.0002C3.94123 7.00738 8.00348 2.94513 12.9963 2.94513C13.4782 2.94513 13.8688 2.55458 13.8688 2.07268C13.8688 1.59075 13.4782 1.2002 12.9963 1.2002Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5361 3.99825C23.1871 3.6366 22.6197 3.6359 22.2706 3.99677L12.3518 14.2295L8.80124 10.2407C8.46691 9.8653 7.90049 9.84075 7.53668 10.1868C7.17431 10.5318 7.15135 11.1143 7.48458 11.4888L11.666 16.186C11.8304 16.3708 12.0609 16.4787 12.3045 16.4842L12.3554 16.4205C12.3623 16.4202 12.3693 16.4198 12.3762 16.4194L12.3243 16.4845C12.5614 16.4845 12.7889 16.3871 12.9562 16.2146L23.5343 5.30141C23.8832 4.94184 23.8837 4.35874 23.5361 3.99825ZM21.866 4.50469L22.2706 3.99677L21.8614 4.50945L21.866 4.50469Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default CircleCheckIcon;
