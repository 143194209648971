import { gql } from '@apollo/client';

export const GET_VIDEOS_WATCHED_BY_CATEGORY = gql`
  query GetVideosWatchedByCathegoryStat($filter: statisticFilter) {
    VideosWatchedByCathegoryStat(filter: $filter) {
      count
      users {
        user_id
        count
      }
      category {
        id
        parent_id
      }
    }
  }
`;

export const GET_VIEWED_HOUR_STAT = gql`
  query GetViewedHoursStat($filter: ViewedHoursFilter) {
    ViewedHoursStat(filter: $filter) {
      grouped_by_dates {
        date
        duration
      }
      total_view_duration
    }
  }
`;

export const GET_VIDEOS_WATCHED_COUNT_STAT = gql`
  query GetVideosWatchedCountStat($filter: ViewedVideosFilter) {
    VideosWatchedCountStat(filter: $filter) {
      grouped_by_dates {
        date
        count
      }
      total_count_viewed_videos
    }
  }
`;

export const GET_CPD_COUNT_STAT = gql`
  query GetCPDCountStat($filter: CPDCreditsFilter) {
    CpdCreditsChartStat(filter: $filter) {
      grouped_by_dates {
        date
        cpd_credits
      }
    }
  }
`;

export const GET_PATHWAYS_PROGRESS_STAT = gql`
  query GetPathwaysProgressStat($pathwaysFilter: PathwayProgressFilter) {
    PathwaysProgressStat(filter: $pathwaysFilter) {
      count
      total_count_pathways
      users {
        current
        user_id
      }
    }
  }
`;

export const GET_LOGIN_USERS_STAT = gql`
  query GetLoginUsersStat($filter: LoginUsersFilter) {
    LoginUsersStat(filter: $filter) {
      grouped_by_dates {
        date
        logins
      }
      total_count_logins
    }
  }
`;

export const GET_LAST_LOGIN_USERS_STAT = gql`
  query GetLastLoginUsersStat($loginStatFilter: LoginUsersFilter) {
    LastLoginUsersStat(filter: $loginStatFilter) {
      last_logins
    }
  }
`;

export const GET_LIST_BASE = gql`
  query GetListBaseVideoCategories {
    listBaseVideoCategories {
      id
      title
      slug
    }
  }
`;

export const GET_LIST_BASE_WITH_SORT = gql`
  query GetListBaseVideoCategoriesWithSort($sort: [OrderByClause!]) {
    listBaseVideoCategories(sort: $sort) {
      id
      title
      slug
      image_url
    }
  }
`;

export const GET_ANALYTICS = gql`
  query GetStatistics(
    $statisticFilter: statisticFilter
    $viewedHoursFilter: ViewedHoursFilter
    $viewedVideosFilter: ViewedVideosFilter
  ) {
    VideosWatchedByCathegoryStat(filter: $statisticFilter) {
      count
      users {
        user_id
        count
      }
      category {
        id
        parent_id
      }
    }

    ViewedHoursStat(filter: $viewedHoursFilter) {
      grouped_by_dates {
        date
        duration
      }
      total_view_duration
    }

    VideosWatchedCountStat(filter: $viewedVideosFilter) {
      grouped_by_dates {
        date
        count
      }
      total_count_viewed_videos
    }

    listBaseVideoCategories {
      id
      title
    }
  }
`;

export const GET_USER_VIDEOS_PROGRESS_STAT = gql`
  query GetUserVideosProgressStat($filter: VideosProgressFilter) {
    UserVideosProgressStat(filter: $filter) {
      videos_progresses {
        time
        chapter
        last_position
        completed
        progress_percentage
        video {
          id
          title
          preview_url
          video_url
          duration
          user {
            name
            slug
          }
        }
      }
    }
  }
`;

export const GET_MANAGER_PATHWAY_ANALYTICS = gql`
  query GetManagerPathwayAnalytics($filter: pathwaysGroupedFilter) {
    PathwaysGroupedStatPaginated(filter: $filter) {
      count_items
      items {
        pathway {
          id
          name
          description
          image_url
          duration
          slug
          cpd_credits
          formatted_duration
          count_videos
        }
        duration
        cpd_available
        users_started
        users_completed
        users_watched
        half_completed
      }
    }
  }
`;

export const GET_MANAGER_VIDEO_ANALYTICS = gql`
  query GetManagerVideoAnalytics($filter: videosGroupedFilter) {
    VideosGroupedStatPaginated(filter: $filter) {
      count_items
      items {
        video_id
        view_duration
        title
        description
        presenter
        image
        users_started
        users_completed
        video_categories
        video_url
        video_duration
      }
    }
  }
`;

export const GET_DETAILED_VIDEO_TABLE_ANALYTIC = gql`
  query GetDetailedVideoTableAnalytic($filter: UsersProgressesByVideoFilter) {
    getUsersProgressesByVideo(filter: $filter) {
      id
      complete
      last_position
      view_duration
      view_finish
      progress_percentage
      user {
        name
      }
    }
  }
`;

export const GET_LEARNER_VIDEOS_WATCHED_COUNT_STAT_BY_DATE_RANGES = gql`
  query GetLearnerVideosWatchedCountStatByDateRanges($filter: LearnerViewedStatByDateRangesFilter) {
    getLearnerVideosWatchedCountStatByDateRanges(filter: $filter) {
      grouped_by_dates {
        date
        count
      }
      total_count_viewed_videos
    }
  }
`;

export const GET_LEARNER_VIEWED_HOURS_STAT_BY_DATE_RANGES = gql`
  query GetLearnerViewedHoursStatByDateRanges($filter: LearnerViewedStatByDateRangesFilter) {
    getLearnerViewedHoursStatByDateRanges(filter: $filter) {
      grouped_by_dates {
        date
        duration
      }
      total_view_duration
    }
  }
`;

export const GET_VIEWS_AND_HOURS = gql`
  query GetVideosWatchedGeneralInfo($filter: VideosWatchedGeneralFilter) {
    getVideosWatchedGeneralInfo(filter: $filter) {
      watched_videos_count
      watched_hours
    }
  }
`;

export const GET_COMPLETED_PATHWAYS_STATS_BY_DATE_RANGES = gql`
  query GetCompletedPathwaysStatsByDateRanges($dateRanges: [DateRangeFilter]) {
    getCompletedPathwaysStatsByDateRanges(dateRanges: $dateRanges) {
      date_from
      date_to
      completed
      cpd_earned
    }
  }
`;

export const GET_REPORT_BY_LEARNER = gql`
  query getReportByLearner($filter: GroupedByUsersFilter) {
    UsersGroupedStatV3(filter: $filter) {
      items {
        learner {
          id
          name
          email
          teams {
            id
            name
          }
        }
        cpd_available
        hours_watched
        videos_started
        videos_completed
        pathways_watched
        pathways_completed
        login_sessions
        last_session
      }
      total_count
    }
  }
`;

export const GET_PATHWAYS_STATS = gql`
  query GetPathwaysStats($filter: PathwayProgressFilter) {
    getUserPathwaysProgressStat(filter: $filter) {
      pathways_stat {
        completed
        total
      }
      cpd_credits_stat {
        completed
      }
      pathways_progresses_stat {
        pathway {
          id
          slug
          name
          image_url
          quizes {
            id
            stars
            percentage_complete
          }
          available_quizes {
            id
          }
        }
        pathway_watched_videos_count
        pathway_videos_count
        progress
        test_score
        status
      }
    }
  }
`;

export const GET_PATHWAYS_PROGRESS_STATS = gql`
  query GetPathwaysProgressStats($filter: PathwayProgressFilter) {
    getUserPathwaysProgressStat(filter: $filter) {
      pathways_progresses_stat {
        pathway {
          id
          slug
          quizes {
            id
          }
          available_quizes {
            id
          }
        }
        pathway_watched_videos_count
        pathway_videos_count
        progress
        test_score
        status
      }
    }
  }
`;

export const GET_PATHWAYS_STATS_FOR_WIDGET = gql`
  query GetPathwaysStatsForWidget($filter: PathwayProgressFilter) {
    getUserPathwaysProgressStat(filter: $filter) {
      pathways_stat {
        completed
        total
      }
      cpd_credits_stat {
        completed
      }
    }
  }
`;

export const GET_DETAILED_PATHWAY_TABLE_ANALYTIC = gql`
  query GetDetailedPathwayTableAnalytic($filter: UsersProgressOnPathwaysFilter) {
    GetUsersProgressesByPathway(filter: $filter) {
      status
      progress
      user {
        id
        name
      }
    }
  }
`;

export const GET_VIEWED_HOURS_STAT_BY_STORY_IDS = gql`
  query GetViewedHoursStatByStoryIds($filter: ViewedHoursFilter) {
    ViewedHoursStat(filter: $filter) {
      total_view_duration
    }
  }
`;

export const GET_VIDEOS_WATCHED_COUNT_STAT_BY_STORY_IDS = gql`
  query GetVideosWatchedCountStatByIds($filter: ViewedVideosFilter) {
    VideosWatchedCountStat(filter: $filter) {
      total_count_viewed_videos
    }
  }
`;

export const GET_MANAGER_DETAILED_REPORT_BY_PATHWAYS = gql`
  query GetManagerDetailedReportByPathways($filter: DetailedReportPathwayFilter) {
    getManagerDetailedReportByPathways(filter: $filter) {
      items {
        pathway_id
        pathway_name
        pathway_duration
        progress
        test_passed
        test_score
        status
        learner {
          id
          name
          email
        }
      }
      count
    }
  }
`;

export const GET_MANAGER_DETAILED_REPORT_BY_VIDEOS = gql`
  query GetManagerDetailedReportByVideos($filter: DetailedReportVideoFilter) {
    getManagerDetailedReportByVideos(filter: $filter) {
      items {
        progress
        completed
        video {
          id
          title
          duration
          user {
            id
            name
          }
          categories {
            id
            title
          }
        }
        learner {
          id
          name
          email
        }
      }
      count
    }
  }
`;

export const GET_MANAGER_DETAILED_REPORT_BY_PATHWAYS_CSV_LINK = gql`
  query GetManagerDetailedReportByPathwaysCsvLink($filter: DetailedReportPathwayFilter) {
    getDetailedReportByPathwaysCsvLink(filter: $filter)
  }
`;

export const GET_MANAGER_DETAILED_REPORT_BY_VIDEOS_CSV_LINK = gql`
  query GetManagerDetailedReportByVideosCsvLink($filter: DetailedReportVideoFilter) {
    getDetailedReportByVideosCsvLink(filter: $filter)
  }
`;

export const GET_PATHWAYS_CERTIFICATES = gql`
  query GetPathwayCertificates($filter: CertificatesFilter) {
    getPathwayCertificates(filter: $filter) {
      pathway {
        id
        name
        image_url
        slug
        cpd_credits
        categories {
          id
          title
        }
      }
      hash
      date_passing
      percent_complete
      quiz_questions_count
      count_of_correct_answers
    }
  }
`;

export const GET_COURSE_CERTIFICATES = gql`
  query GetCourseCertificates($filter: CertificatesFilter) {
    getCourseCertificates(filter: $filter) {
      course {
        id
        name
        slug
        image_url
        general_info {
          pathways
        }
        pathways_stats {
          cpd_credits_stat {
            completed
            total
          }
          pathways_progresses_stat {
            test_score
            pathway {
              id
            }
          }
        }
      }
      hash
    }
  }
`;

export const GET_ASL_CERTIFICATES = gql`
  query GetAslCertificates($filter: CertificatesFilter) {
    getAslCertificates(filter: $filter) {
      ... on AssignmentForLearner {
        id
        title
        type
        cert_hash
      }
      ... on AssignmentForLearnerCPD {
        id
        title
        type
        cert_hash
      }
      ... on AssignmentForLearnerHours {
        id
        title
        type
        cert_hash
      }
      ... on AssignmentForLearnerVideos {
        id
        title
        type
        cert_hash
      }
    }
  }
`;

export const GET_MANAGER_REPORT_BY_TEAMS = gql`
  query GetManagerReportByTeams($filter: ReportByTeamsFilter) {
    getManagerReportByTeamsPaginated(filter: $filter) {
      paginatorInfo {
        total
      }
      data {
        team {
          id
          name
        }
        active_learners
        videos_started
        videos_completed
        pathways_completed
        cpd_earned
        seconds_watched
        login_sessions
      }
    }
  }
`;

export const GET_VIEWED_HOUR_STAT_BY_PERIOD = gql`
  query GetViewedHoursStatByPeriod($filter: ViewedHoursFilter) {
    ViewedHoursStat(filter: $filter) {
      grouped_by_dates {
        date
        duration
      }
    }
  }
`;

export const GET_VIDEOS_WATCHED_COUNT_STAT_BY_PERIOD = gql`
  query GetVideosWatchedCountStatByPeriod($filter: ViewedVideosFilter) {
    VideosWatchedCountStat(filter: $filter) {
      grouped_by_dates {
        date
        count
      }
    }
  }
`;

export const GET_VIDEOS_WATCHED_GENERAL_INFO_MANAGER = gql`
  query GetVideosWatchedGeneralInfoManager($filter: VideosWatchedGeneralFilter) {
    getVideosWatchedGeneralInfoManager(filter: $filter) {
      watched_videos_count
      watched_hours
    }
  }
`;

export const GET_PATHWAYS_COMPLETED_GENERAL_INFO_MANAGER = gql`
  query GetPathwaysCompletedGeneralInfoManager($filter: VideosWatchedGeneralFilter) {
    getPathwaysCompletedGeneralInfoManager(filter: $filter) {
      count
    }
  }
`;

export const GET_PATHWAYS_COMPLETED_STAT_BY_PERIOD = gql`
  query GetPathwaysStatByUsersGroupedByDatesManager($filter: VideosWatchedGeneralFilter) {
    getPathwaysStatByUsersGroupedByDatesManager(filter: $filter) {
      grouped_by_dates {
        date
        count
      }
    }
  }
`;

export const GET_LEARNERS_STATS_BY_DATE_RANGES = gql`
  query GetLearnersStatsByDateRanges($dateRanges: [DateRangeFilter]) {
    getLearnersStatsByDateRanges(dateRanges: $dateRanges) {
      date_from
      date_to
      count
    }
  }
`;

export const GET_LEARNING_HOURS_LEADERBOARD = gql`
  query GetLearningHoursLeaderboard($filter: LearningHoursLeaderboardFilter) {
    getLearningHoursLeaderboard(filter: $filter) {
      id
      learner_name
      ranking
      watched_hours
    }
  }
`;
