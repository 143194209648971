import React, { FC } from 'react';

import { Navigate, useParams, Params } from 'react-router-dom';

import { useSsrContext } from '~/context/SsrContext';
import { checkIsSSR } from '~/utils/checkIsSSR';

interface SSRRedirectProps {
  to: string | ((params: Readonly<Params<string>>) => string);
}

const SSRRedirect: FC<SSRRedirectProps> = ({ to }) => {
  const isSSR = checkIsSSR();
  const { redirect } = useSsrContext();
  const params = useParams();

  const redirectUrl = typeof to === 'function' ? to(params) : to;

  if (isSSR && redirect) {
    redirect(redirectUrl);
    return null;
  } else {
    return <Navigate to={redirectUrl} replace />;
  }
};

export default SSRRedirect;
