import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { useTenantContext } from '~/context/TenantProvider';
import replaceTenantVariables from '~/utils/translations/replaceTenantVariables';
import { translateByTenant } from '~/utils/translations/translateByTenant';

const useTenantTranslation = () => {
  const { t: translate, ...rest } = useTranslation();
  const { tenant } = useTenantContext();

  const t = useCallback(
    (key, options?) => {
      const createTranslation = translateByTenant(tenant?.name, translate);
      const replaceVariables = replaceTenantVariables(tenant?.name, translate);
      return replaceVariables(createTranslation(key, options));
    },
    [tenant?.name, translate],
  );

  return {
    ...rest,
    t,
  };
};

export default useTenantTranslation;
