import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import CustomBlocks from '~/blocks/CustomBlocks/CustomBlocks';
import { RM_PAGES } from '~/const';
import { useGtmContext } from '~/context/GtmProvider';
import { useRmMetadata } from '~/context/RmContext';
import { useUserInfoContext } from '~/context/UserInfoProvider';
import { Block } from '~/types';
import { GtmEvents, sendEventToGTM } from '~/utils/gtmUtils';

interface CustomPageProps {
  blocks: (Block | null)[] | null;
  preventLoader?: boolean;
  blocksLoading?: boolean;
  canonicalUrl?: string;
}

const CustomPage: React.FC<CustomPageProps> = ({
  blocks,
  preventLoader,
  blocksLoading,
  canonicalUrl,
}) => {
  const location = useLocation();
  const { isInitialized: isGtmInitialized } = useGtmContext();
  const { userInfo } = useUserInfoContext();
  useRmMetadata({ page: RM_PAGES.CustomPage });

  useEffect(() => {
    if (isGtmInitialized && userInfo) {
      sendEventToGTM(GtmEvents.loggedInCustomPageVisit);
    }
  }, [isGtmInitialized, location.pathname, userInfo]);

  return (
    <CustomBlocks
      blocks={blocks}
      preventLoader={preventLoader}
      blocksLoading={blocksLoading}
      canonicalUrl={canonicalUrl}
    />
  );
};

export default CustomPage;
