import React, { FC, ReactNode } from 'react';

import { styled } from '@mui/material';

import LazyLoadImage from '~/components/LazyloadImage/LazyLoadImage';
import { css, CssUtilityType } from '~/styled';

interface IZoomedBackground {
  children?: ReactNode;
  className?: string;
  imgWrapperCss?: any;
  [key: string]: any;
}

const ZoomedBackground: FC<IZoomedBackground> = ({
  children,
  className,
  imgWrapperCss,
  ...rest
}) => {
  return (
    <Wrapper className={className}>
      <ImgWrapper className="ZOOM_ON_HOVER_IMAGE_WRAPPER" imgWrapperCss={imgWrapperCss}>
        {rest.path && (
          <Image
            className="ZOOM_ON_HOVER"
            data-testid="zoomedImg"
            wrapperCss={imageWrapperStyles}
            {...rest}
          />
        )}
      </ImgWrapper>
      {children}
    </Wrapper>
  );
};

export default ZoomedBackground;

const Wrapper = styled('div')`
  position: relative;
  height: 100%;
  & .ZOOM_ON_HOVER {
    transition: 0.3s transform;
  }

  &:hover {
    .ZOOM_ON_HOVER {
      transform: scale(1.1);
    }
  }
`;
const ImgWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'imgWrapperCss' })<{
  imgWrapperCss?: CssUtilityType;
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  ${({ imgWrapperCss }) => imgWrapperCss}
`;
const Image = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const imageWrapperStyles = css`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const createCustomDimensionsImgWrapper = ({
  height,
  width,
}: {
  height?: string;
  width?: string;
}) => css`
  height: ${height};
  width: ${width};
  top: 0;
  transform: none;
  overflow: hidden;
`;
