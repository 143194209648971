import { useTheme } from '@mui/material';
import { styled } from '@mui/material';

export const ElementFill = styled('path', {
  shouldForwardProp: (prop) => prop !== 'secondaryColor' && prop !== 'as',
})<{ secondaryColor?: string }>`
  fill: ${({ secondaryColor, theme }) => secondaryColor || theme.palette.common.gray};
  fill-rule: evenodd;
`;
export const Element = styled('path', {
  shouldForwardProp: (prop) => prop !== 'as',
})`
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
`;

export const Svg = styled('svg', {
  shouldForwardProp: (prop) => prop !== 'svgColor',
})<{ svgColor?: string }>`
  ${({ svgColor }) => (svgColor ? `color: ${svgColor};` : '')};
`;

export const useSvgColor = (color) => {
  const theme = useTheme();
  return color || theme.palette.common.white;
};
