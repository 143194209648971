import React from 'react';

import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import BellIcon from '~/assets/icons/BellIcon';
import IconButton from '~/components/UI/IconButton/IconButton';
import { LightTooltip } from '~/components/UI/LightTooltip/LightTooltip';
import { ringing } from '~/components/animations/style';
import useTenantTranslation from '~/hooks/useTenantTranslation';

const FollowButton = ({ followed, loading, onlyIcon = false, ...rest }) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();
  return (
    <LightTooltip
      data-testid="followButton"
      placement="top"
      title={followed ? t('alert.unfollowThisExpert') : t('alert.followExpertsUpdates')}
    >
      <StyledIconButton
        size={'small'}
        disabled={loading}
        icon={<StyledBellIcon color={theme.palette.common.primary} filled={followed} />}
        label={!onlyIcon ? (followed ? t('button.followed') : t('button.follow')) : ''}
        onlyIcon={onlyIcon}
        {...rest}
      />
    </LightTooltip>
  );
};

export default FollowButton;

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'onlyIcon',
})<{ onlyIcon: boolean }>`
  padding: 2px;
  min-width: ${({ onlyIcon }) => (onlyIcon ? 0 : 64)}px;
  line-height: 1.5;

  & > span {
    display: ${({ onlyIcon }) => onlyIcon && 'none'};
  }

  &:hover {
    & svg {
      animation: ${ringing} 2s;
      animation-fill-mode: forwards;
    }
  }

  &:active {
    transform: scale(${({ onlyIcon }) => (onlyIcon ? 0.85 : 1)});
  }
`;
const StyledBellIcon = styled(BellIcon)`
  width: 22px;
  min-width: 22px;
  height: 22px;
  transform-origin: 50% 0;
  transform: rotate(0deg);
  transition: 0.3s;

  > * {
    stroke-width: 7px;
  }
`;
