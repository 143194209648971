import { format } from 'date-fns';
import { enGB, fr, es } from 'date-fns/locale';

import { isValidDate } from '~/utils/dateUtils/isValidDate';

const locales = {
  en: enGB,
  fr: fr,
  es: es,
};

const defaultDateTimeFormat = 'do MMMM yyyy';

export const formatDateToNewsFormat = ({
  dateString,
  dateTimeFormat = defaultDateTimeFormat,
  locale = 'en',
}) => {
  if (!dateString) return null;

  const date = new Date(dateString.replace(/-/g, '/'));

  if (!isValidDate(date)) {
    return null;
  }

  return format(date, dateTimeFormat, { locale: locales[locale] || locales.en });
};
