import { captureException } from '@sentry/react';

export enum GtmEvents {
  submitForm = 'submit-form',
  loggedInCustomPageVisit = 'logged-in-custom-page-page-visit',
  loggedOutPageVisit = 'logged-out-page-visit',
  learnerDashboardVisit = 'learnerDashboardVisit',
  freeTrialSignUp = 'free-trial-sign-up',
  staticCoursePageVisit = 'staticCoursePageVisit',
}

export const sendEventToGTM = (event: string, variables = {}) =>
  sendDataToGTM({ event, ...variables });

export const sendDataToGTM = (variables = {}) => {
  if (!window?.dataLayer) {
    return captureException(
      new Error(`The ${sendDataToGTM.name} function was called before GTM was initialized`),
    );
  }

  window.dataLayer.push(variables);
};
